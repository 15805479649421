import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import SearchFArticleComponent from "../fArticle/SearchFArticleComponent";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { toastr } from "react-redux-toastr";
import { useTranslation } from "react-i18next";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import { defaultFontSize } from "../category/ConfigureViewProductsComponent";
import CopyClipboardComponent from "../CopyClipboardComponent";
import FArticleSliderComponent from "../fArticle/FArticleSliderComponent";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { CircularProgress } from "@mui/material";
import { PRODUCT_PAGE } from "../../../utils/RouteUtils";
import { generateTechnicalSheet } from "../../../helpers/FArticleHelper";
import {
  STORAGE_FARTICLES_COMPARE,
  STORAGE_SHOW_ONLY_DIFF_COMPARE,
} from "../../../utils/StorageUtils";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ContentComponent from "../../content/ContentComponent";
import { set } from "../../../app/globalSlice";
import { priceFormat } from "../../../utils/FormatUtils";
import { filterArrayUniqueKey } from "../../../helpers/GroupHelper";
import { DEFAULT_DEPOT } from "../../../utils/FArticleUtils";
import FArticleFDepotComponent from "../fArticle/FArticleFDepotComponent";

interface CharacteristicInterface {
  name: string;
  fields: CharacteristicFieldInterface[];
  nbDiff: number;
}

interface CharacteristicFieldInterface {
  name: string;
  values: (string | null)[];
  nbDiff: number;
}

const CompareComponent: React.FC = React.memo(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const fontSize = useAppSelector(
    (state: RootState) => state.globalState.fontSize
  );
  const fArticlesToCompare = useAppSelector(
    (state: RootState) => state.globalState.fArticlesToCompare
  );
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const getFArticles = React.useCallback(async () => {
    const searchParamsObject = searchParamToObject(searchParams);
    const arRefs = searchParamsObject["arRefs[]"];
    if (!arRefs) {
      return [];
    }
    const response = await requestApi({
      method: GET,
      path:
        FARTICLE_URL +
        objectToQuery({
          ...searchParamsObject,
          full: true,
        }),
      allowError: false,
      paginate: false,
      token: token,
    });
    if (response.statusCode === 200) {
      return response.content.fArticles;
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      toastr.error(t("word.error"), t("error.tryAgain"));
    }
    return [];
  }, [searchParams, t, token]);
  const getShowOnlyDiff = React.useCallback(() => {
    return localStorage.getItem(STORAGE_SHOW_ONLY_DIFF_COMPARE) === "true";
  }, []);
  const [showOnlyDiff, setShowOnlyDiff] = React.useState<boolean>(
    getShowOnlyDiff()
  );
  const [characteristics, setCharacteristics] = React.useState<
    CharacteristicInterface[]
  >([]);

  const getCharacteristics = React.useCallback(() => {
    let r: CharacteristicInterface[] = [];
    if (!fArticlesToCompare) {
      return r;
    }
    const tempTechs: any = {};
    for (const f of fArticlesToCompare) {
      const tech = generateTechnicalSheet(f, 1);
      tempTechs[f.arRef] = tech;
      if (!tech) {
        continue;
      }
      for (const group of tech.groups) {
        for (const prop of group.props) {
          let thisCharacteristic = r.find((x) => x.name === group.name);
          if (!thisCharacteristic) {
            thisCharacteristic = {
              name: group.name,
              fields: [],
              nbDiff: 0,
            };
            r.push(thisCharacteristic);
          }
          let fieldCharacteristic = thisCharacteristic.fields.find(
            (x) => x.name === prop.name
          );
          if (!fieldCharacteristic) {
            fieldCharacteristic = {
              name: prop.name,
              values: [],
              nbDiff: 0,
            };
            thisCharacteristic.fields.push(fieldCharacteristic);
          }
        }
      }
    }
    if (fArticlesToCompare && fArticlesToCompare.length > 0) {
      r = [
        {
          name: "",
          fields: [
            {
              name: t("word.wHt"),
              // @ts-ignore
              values: fArticlesToCompare.map((f) =>
                priceFormat(f.priceDto.priceHt, i18n.language, "EUR")
              ),
              nbDiff: 0,
            },
            {
              name: t("word.wTtc"),
              // @ts-ignore
              values: fArticlesToCompare.map((f) =>
                priceFormat(f.priceDto.priceTtc, i18n.language, "EUR")
              ),
              nbDiff: 0,
            },
            {
              name: t("word.brand"),
              // @ts-ignore
              values: fArticlesToCompare.map(
                (f) => f.fArticleProp?.driver?.name ?? ""
              ),
              nbDiff: 0,
            },
          ],
          nbDiff: 0,
        },
        ...r,
      ];
    }
    for (const characteristic of r) {
      for (const fieldCharacteristic of characteristic.fields) {
        for (const f of fArticlesToCompare) {
          let value = null;
          const group = tempTechs[f.arRef]?.groups.find(
            (g: CharacteristicInterface) => g.name === characteristic.name
          );
          if (group) {
            const prop = group.props.find(
              (p: CharacteristicFieldInterface) =>
                p.name === fieldCharacteristic.name
            );
            if (prop) {
              value = prop.value;
            }
          }
          if (characteristic.name !== "") {
            fieldCharacteristic.values.push(value);
          }
        }
        fieldCharacteristic.nbDiff = [
          // @ts-ignore
          ...new Set(fieldCharacteristic.values.filter((x) => x !== null)),
        ].length;
      }
      characteristic.nbDiff = characteristic.fields.reduce(
        (n, fieldCharacteristic) => n + fieldCharacteristic.nbDiff,
        0
      );
    }
    r.sort((a, b) => {
      if (a.name === "") {
        return -1;
      }
      if (b.name === "") {
        return 1;
      }
      if (a.nbDiff < b.nbDiff) {
        return 1;
      }
      if (a.nbDiff > b.nbDiff) {
        return -1;
      }
      return 0;
    });
    if (showOnlyDiff) {
      r = r.filter((c) => c.fields.find((f) => f.nbDiff > 1));
    }
    return r;
  }, [fArticlesToCompare, i18n.language, showOnlyDiff, t]);

  const onSearchFArticle = React.useCallback(
    (result: FArticleInterface) => {
      let temp = [...(fArticlesToCompare ?? [])];
      dispatch(set({ fArticlesToCompare: undefined }));
      setTimeout(() => {
        if (!temp) {
          temp = [];
        }
        temp = temp.filter((f) => f.arRef !== result.arRef);
        temp.push(result);
        dispatch(set({ fArticlesToCompare: [...temp] }));
      });
    },
    [dispatch, fArticlesToCompare]
  );

  useEffect(() => {
    getFArticles().then((r) => {
      dispatch(set({ fArticlesToCompare: r }));
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (fArticlesToCompare === undefined) {
      return;
    }
    const searchParamsObject = searchParamToObject(searchParams);
    searchParamsObject["arRefs[]"] = fArticlesToCompare.map((f) => f.arRef);
    setSearchParams(searchParamsObject);
    setCharacteristics(getCharacteristics());
  }, [fArticlesToCompare, showOnlyDiff]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ContentComponent contentName="beforeCompare" saveLocalStorage={true} />
      {fArticlesToCompare !== undefined ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {fArticlesToCompare?.map((fArticle, indexFArticle) => (
                  <TableCell
                    key={indexFArticle}
                    sx={{
                      minWidth: "200px",
                      maxWidth: "400px",
                      position: "relative",
                    }}
                  >
                    <IconButton
                      sx={{ position: "absolute", right: 0, top: 0, zIndex: 1 }}
                      onClick={() => {
                        const thisFArticlesToCompare: FArticleInterface[] =
                          JSON.parse(
                            localStorage.getItem(STORAGE_FARTICLES_COMPARE) ??
                              "[]"
                          );
                        const newFArticlesToCompare = filterArrayUniqueKey(
                          [
                            ...(thisFArticlesToCompare?.filter(
                              (f) => f.arRef !== fArticle.arRef
                            ) ?? []),
                          ],
                          "arRef"
                        );
                        dispatch(
                          set({
                            fArticlesToCompare: newFArticlesToCompare,
                          })
                        );
                        localStorage.setItem(
                          STORAGE_FARTICLES_COMPARE,
                          JSON.stringify(newFArticlesToCompare)
                        );
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <Link
                      style={{ textDecoration: "none", color: "inherit" }}
                      to={PRODUCT_PAGE + "/" + fArticle.fArticleProp?.slug}
                    >
                      <Box sx={{ maxWidth: 400, margin: "auto" }}>
                        <FArticleSliderComponent
                          fArticle={fArticle}
                          dots={false}
                          singleImage={true}
                        />
                      </Box>
                      <Typography
                        sx={{
                          paddingInline: 1,
                          wordBreak: "break-word",
                          fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                        }}
                      >
                        {fArticle.arDesign}
                      </Typography>
                      <FArticleFDepotComponent
                        fArticle={fArticle}
                        maxEqualMinWidth={true}
                        depot={DEFAULT_DEPOT}
                        isExpedition={false}
                      />
                      <CopyClipboardComponent
                        className="RobotoMono"
                        sx={{
                          paddingInline: 1,
                          fontWeight: "bold",
                          textAlign: "center",
                          color: theme.palette.success.main,
                          fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                        }}
                        text={fArticle.arRef}
                      />
                    </Link>
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    verticalAlign: "bottom",
                    ...(fArticlesToCompare.length > 0 && {
                      borderLeft: "1px solid black",
                    }),
                  }}
                >
                  <Box sx={{ minWidth: "400px", maxWidth: "600px" }}>
                    <SearchFArticleComponent
                      onSuccess={onSearchFArticle}
                      hideSearchIcon={true}
                      hideConstructor={true}
                      preventSearch={true}
                      full={true}
                    />
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            {characteristics.length > 0 && (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={fArticlesToCompare.length + 1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={showOnlyDiff}
                          onChange={(event, checked) => {
                            setShowOnlyDiff(checked);
                            localStorage.setItem(
                              STORAGE_SHOW_ONLY_DIFF_COMPARE,
                              checked.toString()
                            );
                          }}
                        />
                      }
                      label={t("word.hideSimilar")}
                    />
                  </TableCell>
                  <TableCell sx={{ borderLeft: "1px solid black" }}></TableCell>
                </TableRow>
                {characteristics.map((c, indexC) => (
                  <React.Fragment key={indexC}>
                    {c.name && (
                      <TableRow>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.common.white,
                          }}
                          colSpan={fArticlesToCompare.length + 1}
                        >
                          {c.name}
                        </TableCell>
                        <TableCell
                          sx={{
                            textAlign: "center",
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.common.white,
                            borderLeft: "1px solid black",
                          }}
                        ></TableCell>
                      </TableRow>
                    )}
                    {c.fields.map((f, fIndex) => (
                      <TableRow
                        key={fIndex}
                        sx={{
                          ...(f.nbDiff <= 1
                            ? showOnlyDiff && {
                                display: "none",
                              }
                            : f.nbDiff > 1 && {
                                backgroundColor: theme.palette.secondary.main,
                                color: theme.palette.secondary.contrastText,
                              }),
                        }}
                      >
                        <TableCell>{f.name}</TableCell>
                        {f.values.map((v, vIndex) => (
                          <TableCell key={vIndex}>{v ?? ""}</TableCell>
                        ))}
                        <TableCell sx={{ borderLeft: "1px solid black" }}>
                          {/*nothing*/}
                        </TableCell>
                      </TableRow>
                    ))}
                  </React.Fragment>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
});

export default CompareComponent;
