import * as React from "react";
import {
  DriverInterface,
  FArticleSmallInterface,
} from "../../../interfaces/FArticleInterface";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PriceComponent from "../PriceComponent";
import FArticleStockComponent from "./FArticleStockComponent";
import { grey } from "@mui/material/colors";
import { useSearchParams } from "react-router-dom";
import FArticleLogoComponent from "./FArticleLogoComponent";
import FArticleActionButtonComponent from "./FArticleActionButtonComponent";
import { Chip, Grid, Skeleton, Tooltip } from "@mui/material";
import FArticleSliderComponent from "./FArticleSliderComponent";
import { STYLE_INLINE } from "../../../utils/StorageUtils";
import {
  DEFAULT_DEPOT,
  DEFAULT_STYLE_INLINE,
  STYLE_COMPACT_INLINE,
  STYLE_STRETCH_INLINE,
  STYLE_SUPER_COMPACT_INLINE,
} from "../../../utils/FArticleUtils";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { useTranslation } from "react-i18next";
import { priceFormat } from "../../../utils/FormatUtils";
import FArticleKeywordsComponent from "./FArticleKeywordsComponent";
import CopyClipboardComponent from "../CopyClipboardComponent";
import { InventoryFArticleComponent } from "./InventoryFArticleComponent";
import { StockMinMaxFArticleComponent } from "./StockMinMaxFArticleComponent";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import FArticleLinkComponent from "./FArticleLinkComponent";
import FArticleCustomTagComponent from "./FArticleCustomTagComponent";
import { getMatching } from "../../../helpers/FArticleHelper";
import { defaultFontSize } from "../category/ConfigureViewProductsComponent";
import { getPrixDeRevient } from "../../../helpers/FarticleFormat";

interface State {
  fArticles: FArticleSmallInterface[];
  nbColumn: number;
  inline: boolean;
  loading?: boolean;
  showAddCart?: boolean;
  slider?: boolean;
  defaultStyleInline?: string;
  openNewTab?: boolean;
}

interface State2 {
  remainingSpace: number;
  fArticlesLength: number;
}

interface State3 {
  fArticles: FArticleSmallInterface[];
  nbColumn: number;
  showAddCart?: boolean;
  slider?: boolean;
  defaultStyleInline: string | undefined;
  openNewTab: boolean | undefined;
  beforeNavigate: any;
}

interface State4 {
  fArticle: FArticleSmallInterface;
  searchFArticle: string;
}

interface State5 {
  driver: DriverInterface;
}

interface State6 {
  matching: string;
}

interface State7 {
  fArticle: FArticleSmallInterface;
}

const RemainingSpaceComponent: React.FC<State2> = React.memo(
  ({ remainingSpace, fArticlesLength }) => {
    return (
      <>
        {remainingSpace >= 0 &&
          Array.from(Array(remainingSpace)).map((emptyElement, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flex: 1,
                ...(fArticlesLength > 1 && {
                  marginInline: 0.5,
                }),
              }}
            />
          ))}
      </>
    );
  }
);

const FArticleSmallComponentColumn: React.FC<State3> = React.memo(
  ({
    fArticles,
    nbColumn,
    showAddCart,
    slider,
    openNewTab,
    beforeNavigate,
  }) => {
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const showIcons = useAppSelector(
      (state: RootState) => state.globalState.showIcons
    );
    const fontSize = useAppSelector(
      (state: RootState) => state.globalState.fontSize
    );
    const showKeywords = useAppSelector(
      (state: RootState) => state.globalState.showKeywords
    );
    const showNoveRef = useAppSelector(
      (state: RootState) => state.globalState.showNoveRef
    );
    const showConstructorRef = useAppSelector(
      (state: RootState) => state.globalState.showConstructorRef
    );
    const theme = useTheme();
    const [searchParams] = useSearchParams();

    const searchParamsObject = searchParamToObject(searchParams);
    return (
      <>
        <Box sx={{ display: "flex", alignItems: "stretch" }}>
          {fArticles.map((fArticle, indexFArticle) => (
            <Box
              key={indexFArticle}
              id={"product-" + fArticle.arRef}
              sx={{
                width: 100 / nbColumn + "%",
                position: "relative",
                fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  right: 5,
                  zIndex: 1,
                }}
              >
                <FArticleLinkComponent
                  fArticle={fArticle}
                  beforeNavigate={beforeNavigate}
                  openNewTab={openNewTab}
                >
                  <FArticleCustomTagComponent fArticle={fArticle} />
                </FArticleLinkComponent>
              </Box>
              <Box
                sx={{
                  height: "100%",
                  border: "1px solid rgba(0,0,0,.125)",
                  borderRadius: "5px 5px 0 0",
                  ...(fArticles.length > 1 && {
                    marginInline: 0.5,
                  }),
                }}
              >
                <FArticleLinkComponent
                  fArticle={fArticle}
                  beforeNavigate={beforeNavigate}
                  openNewTab={openNewTab}
                >
                  <FArticleSliderComponent
                    fArticle={fArticle}
                    dots={false}
                    singleImage={slider === false}
                  />
                </FArticleLinkComponent>
              </Box>
            </Box>
          ))}
          <RemainingSpaceComponent
            remainingSpace={nbColumn - fArticles.length}
            fArticlesLength={fArticles.length}
          />
        </Box>
        {showKeywords !== false && (
          <Box sx={{ display: "flex", flexGrow: 1 }}>
            {fArticles.map((fArticle, indexFArticle) => {
              const matching = getMatching(
                searchParamsObject.keywords ?? "",
                fArticle,
                isAdmin ?? false
              );
              return (
                <Box
                  key={indexFArticle}
                  sx={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    borderRight: "1px solid rgba(0,0,0,.125)",
                    alignItems: "center",
                    borderLeft: "1px solid rgba(0,0,0,.125)",
                    ...(fArticles.length > 1 && {
                      marginInline: 0.5,
                    }),
                    position: "relative",
                  }}
                >
                  <MatchingChip matching={matching} />
                  <FArticleKeywordsComponent
                    fArticle={fArticle}
                    sx={{ textAlign: "center" }}
                    tooltip={true}
                    hideKeywordLabel={true}
                    fontSize={fontSize ?? defaultFontSize}
                  />
                </Box>
              );
            })}
            <RemainingSpaceComponent
              remainingSpace={nbColumn - fArticles.length}
              fArticlesLength={fArticles.length}
            />
          </Box>
        )}
        {showNoveRef !== false && (
          <Box sx={{ display: "flex", flexGrow: 1 }}>
            {fArticles.map((fArticle, indexFArticle) => (
              <Box
                key={indexFArticle}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  justifyContent: "center",
                  borderRight: "1px solid rgba(0,0,0,.125)",
                  borderLeft: "1px solid rgba(0,0,0,.125)",
                  ...(fArticles.length > 1 && {
                    marginInline: 0.5,
                  }),
                  position: "relative",
                }}
              >
                <CopyClipboardComponent
                  className="RobotoMono"
                  sx={{
                    paddingInline: 1,
                    fontWeight: "bold",
                    textAlign: "center",
                    color: theme.palette.success.main,
                    fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                  }}
                  text={fArticle.arRef}
                />
              </Box>
            ))}
            <RemainingSpaceComponent
              remainingSpace={nbColumn - fArticles.length}
              fArticlesLength={fArticles.length}
            />
          </Box>
        )}
        {showConstructorRef !== false && (
          <Box sx={{ display: "flex", flexGrow: 1 }}>
            {fArticles.map((fArticle, indexFArticle) => (
              <Box
                key={indexFArticle}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  flex: 1,
                  justifyContent: "flex-start",
                  borderRight: "1px solid rgba(0,0,0,.125)",
                  borderLeft: "1px solid rgba(0,0,0,.125)",
                  ...(fArticles.length > 1 && {
                    marginInline: 0.5,
                  }),
                  position: "relative",
                }}
              >
                <CopyClipboardComponent
                  className="RobotoMono"
                  sx={{
                    paddingInline: 1,
                    fontWeight: "bold",
                    textAlign: "center",
                    color: grey[500],
                    ...(showNoveRef !== false && {
                      borderTop: "none",
                    }),
                    position: "relative",
                    fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                  }}
                  text={fArticle.constructeurRef}
                />
              </Box>
            ))}
            <RemainingSpaceComponent
              remainingSpace={nbColumn - fArticles.length}
              fArticlesLength={fArticles.length}
            />
          </Box>
        )}
        {isAdmin && (
          <>
            <Box sx={{ display: "flex", flexGrow: 1 }}>
              {fArticles.map((fArticle, indexFArticle) => (
                <Box
                  key={indexFArticle}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    justifyContent: "center",
                    borderRight: "1px solid rgba(0,0,0,.125)",
                    borderLeft: "1px solid rgba(0,0,0,.125)",
                    ...(fArticles.length > 1 && {
                      marginInline: 0.5,
                    }),
                    position: "relative",
                  }}
                >
                  {isAdmin && fArticle.score && (
                    <Typography
                      sx={{
                        paddingInline: 1,
                        color: grey[500],
                        textAlign: "center",
                        wordBreak: "break-word",
                        fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                      }}
                    >
                      [{fArticle.score}]
                    </Typography>
                  )}
                  <FArticleLinkComponent
                    fArticle={fArticle}
                    beforeNavigate={beforeNavigate}
                    openNewTab={openNewTab}
                    className="full-link"
                  />
                </Box>
              ))}
              <RemainingSpaceComponent
                remainingSpace={nbColumn - fArticles.length}
                fArticlesLength={fArticles.length}
              />
            </Box>
          </>
        )}
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          {fArticles.map((fArticle, indexFArticle) => (
            <Box
              key={indexFArticle}
              sx={{
                flex: 1,
                borderRight: "1px solid rgba(0,0,0,.125)",
                borderLeft: "1px solid rgba(0,0,0,.125)",
                ...(fArticles.length > 1 && {
                  marginInline: 0.5,
                }),
                position: "relative",
              }}
            >
              <FArticleLogoComponent
                fArticle={fArticle}
                onlyConstructor={true}
              />
              {showIcons && (
                <FArticleLogoComponent
                  fArticle={fArticle}
                  onlyConstructor={false}
                />
              )}
              <FArticleLinkComponent
                fArticle={fArticle}
                openNewTab={openNewTab}
                beforeNavigate={beforeNavigate}
                className="full-link"
              />
            </Box>
          ))}
          <RemainingSpaceComponent
            remainingSpace={nbColumn - fArticles.length}
            fArticlesLength={fArticles.length}
          />
        </Box>
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          {fArticles.map((fArticle, indexFArticle) => (
            <Box
              key={indexFArticle}
              sx={{
                display: "flex",
                flex: 1,
                cursor: "pointer",
                alignItems: "flex-start",
                justifyContent: "center",
                borderRight: "1px solid rgba(0,0,0,.125)",
                borderLeft: "1px solid rgba(0,0,0,.125)",
                ...(fArticles.length > 1 && {
                  marginInline: 0.5,
                }),
                position: "relative",
              }}
            >
              <Typography
                sx={{
                  paddingInline: 1,
                  wordBreak: "break-word",
                  fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                }}
              >
                {fArticle.arDesign}
                {fArticle?.fArticleProp?.indexable === false && (
                  <Typography
                    component="span"
                    sx={{ color: theme.palette.error.main }}
                  >
                    {" "}
                    [non publié]
                  </Typography>
                )}
              </Typography>
              <FArticleLinkComponent
                fArticle={fArticle}
                openNewTab={openNewTab}
                beforeNavigate={beforeNavigate}
                className="full-link"
              />
            </Box>
          ))}
          <RemainingSpaceComponent
            remainingSpace={nbColumn - fArticles.length}
            fArticlesLength={fArticles.length}
          />
        </Box>
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          {fArticles.map((fArticle, indexFArticle) => (
            <Box
              key={indexFArticle}
              sx={{
                display: "flex",
                flex: 1,
                alignItems: "flex-start",
                justifyContent: "center",
                borderRight: "1px solid rgba(0,0,0,.125)",
                borderLeft: "1px solid rgba(0,0,0,.125)",
                ...(fArticles.length > 1 && {
                  marginInline: 0.5,
                }),
                position: "relative",
              }}
            >
              <FArticleSmallPriceComponent fArticle={fArticle} />
              <FArticleLinkComponent
                fArticle={fArticle}
                openNewTab={openNewTab}
                beforeNavigate={beforeNavigate}
                className="full-link"
              />
            </Box>
          ))}
          <RemainingSpaceComponent
            remainingSpace={nbColumn - fArticles.length}
            fArticlesLength={fArticles.length}
          />
        </Box>
        <Box sx={{ display: "flex", flexGrow: 1 }}>
          {fArticles.map((fArticle, indexFArticle) => (
            <Box
              key={indexFArticle}
              sx={{
                flex: 1,
                alignItems: "center",
                borderRight: "1px solid rgba(0,0,0,.125)",
                borderLeft: "1px solid rgba(0,0,0,.125)",
                ...(fArticles.length > 1 && {
                  marginInline: 0.5,
                }),
                position: "relative",
              }}
            >
              <FArticleStockComponent
                fArticle={fArticle}
                asLineReverse={true}
                hideEdit={true}
                small={true}
                fontSize={fontSize ?? defaultFontSize}
              />
              <FArticleLinkComponent
                fArticle={fArticle}
                openNewTab={openNewTab}
                beforeNavigate={beforeNavigate}
                className="full-link"
              />
            </Box>
          ))}
          <RemainingSpaceComponent
            remainingSpace={nbColumn - fArticles.length}
            fArticlesLength={fArticles.length}
          />
        </Box>
        {isAdmin && (
          <>
            <Box sx={{ display: "flex", flexGrow: 1 }}>
              {fArticles.map((fArticle, indexFArticle) => (
                <Box
                  key={indexFArticle}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    justifyContent: "flex-start",
                    borderRight: "1px solid rgba(0,0,0,.125)",
                    borderLeft: "1px solid rgba(0,0,0,.125)",
                    ...(fArticles.length > 1 && {
                      marginInline: 0.5,
                    }),
                    position: "relative",
                  }}
                >
                  <InventoryFArticleComponent
                    fArticle={fArticle}
                    fontSize={fontSize ?? defaultFontSize}
                  />
                </Box>
              ))}
              <RemainingSpaceComponent
                remainingSpace={nbColumn - fArticles.length}
                fArticlesLength={fArticles.length}
              />
            </Box>
          </>
        )}
        {isAdmin && (
          <>
            <Box sx={{ display: "flex", flexGrow: 1 }}>
              {fArticles.map((fArticle, indexFArticle) => (
                <Box
                  key={indexFArticle}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flex: 1,
                    justifyContent: "flex-start",
                    borderRight: "1px solid rgba(0,0,0,.125)",
                    borderLeft: "1px solid rgba(0,0,0,.125)",
                    ...(fArticles.length > 1 && {
                      marginInline: 0.5,
                    }),
                    position: "relative",
                  }}
                >
                  <StockMinMaxFArticleComponent
                    fArticle={fArticle}
                    fontSize={fontSize ?? defaultFontSize}
                    showSwitchPublie={true}
                  />
                </Box>
              ))}
              <RemainingSpaceComponent
                remainingSpace={nbColumn - fArticles.length}
                fArticlesLength={fArticles.length}
              />
            </Box>
          </>
        )}
        {showAddCart !== false && (
          <Box sx={{ display: "flex", flexGrow: 1, marginBottom: 1 }}>
            {fArticles.map((fArticle, indexFArticle) => (
              <Box
                key={indexFArticle}
                sx={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRight: "1px solid rgba(0,0,0,.125)",
                  borderLeft: "1px solid rgba(0,0,0,.125)",
                  borderRadius: "0 0 5px 5px",
                  borderBottom: "1px solid rgba(0,0,0,.125)",
                  ...(fArticles.length > 1 && {
                    marginInline: 0.5,
                  }),
                  paddingBottom: 0.5,
                  position: "relative",
                }}
              >
                <FArticleLinkComponent
                  fArticle={fArticle}
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                    width: "100%",
                    textAlign: "center",
                  }}
                  openNewTab={openNewTab}
                  beforeNavigate={beforeNavigate}
                >
                  {!fArticle?.fArticleProp?.hasOption && (
                    <FArticleActionButtonComponent
                      fArticle={fArticle}
                      depot={DEFAULT_DEPOT}
                    />
                  )}
                </FArticleLinkComponent>
              </Box>
            ))}
            <RemainingSpaceComponent
              remainingSpace={nbColumn - fArticles.length}
              fArticlesLength={fArticles.length}
            />
          </Box>
        )}
      </>
    );
  }
);

const FArticleSmallPriceComponent: React.FC<State7> = React.memo(
  ({ fArticle }) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const fontSize = useAppSelector(
      (state: RootState) => state.globalState.fontSize
    );
    const showTTC = useAppSelector(
      (state: RootState) => state.globalState.showTTC
    );
    const showHT = useAppSelector(
      (state: RootState) => state.globalState.showHT
    );
    const priceTTCColor = useAppSelector(
      (state: RootState) => state.globalState.priceTTCColor
    );
    const priceHTColor = useAppSelector(
      (state: RootState) => state.globalState.priceHTColor
    );

    const getColor = React.useCallback(
      (value: string | undefined, defaultValue: string) => {
        if (!value) {
          value = defaultValue;
        }
        switch (value) {
          case "purple":
            return theme.palette.primary.main;
          case "grey":
            return grey[500];
        }
        return theme.palette.primary.main;
      },
      [theme.palette.primary.main]
    );

    const prixDeRevient = getPrixDeRevient(fArticle);
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {showHT !== false && (
          <Typography
            sx={{
              color: getColor(priceHTColor, "purple"),
              fontWeight: "bold",
              fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
            }}
            component="span"
          >
            {priceFormat(fArticle.priceDto.priceHt, i18n.language, "EUR")}
            {t("word.ht")}
          </Typography>
        )}
        {showTTC !== false && (
          <Typography
            component="span"
            sx={{
              color: getColor(priceTTCColor, "grey"),
              fontWeight: "bold",
              marginLeft: 1,
              fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
            }}
          >
            {priceFormat(fArticle.priceDto.priceTtc, i18n.language, "EUR")}
            {t("word.ttc")}
          </Typography>
        )}
        {isAdmin && prixDeRevient !== null && (
          <Tooltip title={t("word.prixDeRevient")}>
            <Typography
              component="span"
              sx={{
                color: "#eb6020",
                fontWeight: "bold",
                marginLeft: 1,
                fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
              }}
            >
              {priceFormat(prixDeRevient * 100, i18n.language, "EUR")}
            </Typography>
          </Tooltip>
        )}
      </Box>
    );
  }
);

const FArticleSmallComponentLine: React.FC<State3> = React.memo(
  ({
    fArticles,
    nbColumn,
    showAddCart,
    slider,
    defaultStyleInline,
    openNewTab,
    beforeNavigate,
  }) => {
    const getStyleInline = React.useCallback(() => {
      return (
        defaultStyleInline ??
        localStorage.getItem(STYLE_INLINE) ??
        DEFAULT_STYLE_INLINE
      );
    }, [defaultStyleInline]);
    const [styleInline, setStyleInline] = React.useState<string>(() => {
      return getStyleInline();
    });
    const theme = useTheme();
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const showIcons = useAppSelector(
      (state: RootState) => state.globalState.showIcons
    );
    const fontSize = useAppSelector(
      (state: RootState) => state.globalState.fontSize
    );
    const showKeywords = useAppSelector(
      (state: RootState) => state.globalState.showKeywords
    );
    const showNoveRef = useAppSelector(
      (state: RootState) => state.globalState.showNoveRef
    );
    const showConstructorRef = useAppSelector(
      (state: RootState) => state.globalState.showConstructorRef
    );
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const [searchParams] = useSearchParams();

    React.useEffect(() => {
      setStyleInline(getStyleInline());
    }, [defaultStyleInline]); // eslint-disable-line react-hooks/exhaustive-deps

    const searchParamsObject = searchParamToObject(searchParams);
    return (
      <Box sx={{ display: "flex", flexWrap: "wrap", margin: 0 }}>
        {fArticles.map((fArticle, indexFArticle) => {
          const matching = getMatching(
            searchParamsObject.keywords ?? "",
            fArticle,
            isAdmin ?? false
          );
          return (
            <Box
              key={indexFArticle}
              id={"product-" + fArticle.arRef}
              sx={{
                width: 100 / nbColumn + "%",
                marginBottom:
                  styleInline === STYLE_SUPER_COMPACT_INLINE ? 0 : 1,
                position: "relative",
                fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  right: 0,
                  zIndex: 1,
                }}
              >
                <FArticleLinkComponent
                  fArticle={fArticle}
                  openNewTab={openNewTab}
                  beforeNavigate={beforeNavigate}
                >
                  <FArticleCustomTagComponent fArticle={fArticle} />
                </FArticleLinkComponent>
              </Box>
              <Box
                sx={{
                  border: "1px solid rgba(0,0,0,.125)",
                  borderRadius: "5px",
                  padding: 1,
                  ...(styleInline === STYLE_SUPER_COMPACT_INLINE && {
                    padding: "0!important",
                  }),
                }}
              >
                <Grid container spacing={1}>
                  {isSmall && (
                    <Grid item xs={12}>
                      <FArticleLinkComponent
                        fArticle={fArticle}
                        openNewTab={openNewTab}
                        beforeNavigate={beforeNavigate}
                      >
                        <Typography
                          sx={{
                            paddingInline: 1,
                            wordBreak: "break-word",
                            textAlign: "center",
                            fontSize:
                              (fontSize ?? defaultFontSize) / 10 + "rem",
                          }}
                        >
                          {fArticle.arDesign}
                          {fArticle?.fArticleProp?.indexable === false && (
                            <Typography
                              component="span"
                              sx={{
                                color: theme.palette.error.main,
                                fontSize:
                                  (fontSize ?? defaultFontSize) / 10 + "rem",
                              }}
                            >
                              {" "}
                              [non publié]
                            </Typography>
                          )}
                        </Typography>
                      </FArticleLinkComponent>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={
                      styleInline === STYLE_SUPER_COMPACT_INLINE
                        ? 1
                        : styleInline === STYLE_COMPACT_INLINE
                        ? isSmall
                          ? 4
                          : 2
                        : 4
                    }
                    sx={{
                      padding: 0.5,
                    }}
                  >
                    <FArticleLinkComponent
                      fArticle={fArticle}
                      openNewTab={openNewTab}
                      beforeNavigate={beforeNavigate}
                    >
                      <FArticleSliderComponent
                        fArticle={fArticle}
                        dots={false}
                        singleImage={slider === false}
                      />
                    </FArticleLinkComponent>
                  </Grid>
                  <Grid
                    item
                    xs={
                      styleInline === STYLE_SUPER_COMPACT_INLINE
                        ? 11
                        : styleInline === STYLE_COMPACT_INLINE
                        ? isSmall
                          ? 8
                          : 10
                        : 8
                    }
                    sx={{
                      padding: 0.5,
                      ...(styleInline !== STYLE_STRETCH_INLINE && {
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }),
                    }}
                  >
                    {styleInline !== STYLE_STRETCH_INLINE ? (
                      <>
                        {!isSmall && (
                          <FArticleLinkComponent
                            fArticle={fArticle}
                            openNewTab={openNewTab}
                            beforeNavigate={beforeNavigate}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              textDecoration: "none",
                              color: "inherit",
                            }}
                          >
                            <Typography
                              sx={{
                                paddingInline: 1,
                                wordBreak: "break-word",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize:
                                  (fontSize ?? defaultFontSize) / 10 + "rem",
                              }}
                            >
                              {fArticle.arDesign}
                              {fArticle?.fArticleProp?.indexable === false && (
                                <Typography
                                  component="span"
                                  sx={{ color: theme.palette.error.main }}
                                >
                                  {" "}
                                  [non publié]
                                </Typography>
                              )}
                            </Typography>
                            {showKeywords !== false && (
                              <FArticleKeywordsComponent
                                fArticle={fArticle}
                                sx={{ textAlign: "center" }}
                                tooltip={true}
                                hideKeywordLabel={true}
                                fontSize={fontSize ?? defaultFontSize}
                              />
                            )}
                          </FArticleLinkComponent>
                        )}
                        <FArticleLinkComponent
                          fArticle={fArticle}
                          openNewTab={openNewTab}
                          beforeNavigate={beforeNavigate}
                        >
                          <Grid container spacing={1}>
                            <Grid item xs={12} md={5} sx={{ display: "flex" }}>
                              <Grid container>
                                <Grid item xs={7} sx={{ textAlign: "center" }}>
                                  <MatchingChip matching={matching} />
                                  {showNoveRef !== false && (
                                    <CopyClipboardComponent
                                      className="RobotoMono"
                                      sx={{
                                        paddingInline: 1,
                                        fontWeight: "bold",
                                        textAlign: "center",
                                        color: theme.palette.success.main,
                                        fontSize:
                                          (fontSize ?? defaultFontSize) / 10 +
                                          "rem",
                                      }}
                                      text={fArticle.arRef}
                                    />
                                  )}
                                  {showConstructorRef !== false && (
                                    <CopyClipboardComponent
                                      className="RobotoMono"
                                      sx={{
                                        paddingInline: 1,
                                        fontWeight: "bold",
                                        color: grey[500],
                                        ...(showNoveRef !== false && {
                                          borderTop: "none",
                                        }),
                                        fontSize:
                                          (fontSize ?? defaultFontSize) / 10 +
                                          "rem",
                                      }}
                                      text={fArticle.constructeurRef}
                                    />
                                  )}
                                  {isAdmin && fArticle.score && (
                                    <Typography
                                      sx={{
                                        paddingInline: 1,
                                        color: theme.palette.primary.main,
                                        wordBreak: "break-word",
                                        fontSize:
                                          (fontSize ?? defaultFontSize) / 10 +
                                          "rem",
                                      }}
                                    >
                                      [{fArticle.score}]
                                    </Typography>
                                  )}
                                </Grid>
                                <Grid item xs={5}>
                                  <FArticleLogoComponent
                                    fArticle={fArticle}
                                    onlyConstructor={true}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={7}
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: STYLE_STRETCH_INLINE
                                  ? "flex-end"
                                  : "center",
                                textAlign: "center",
                              }}
                            >
                              <FArticleStockComponent
                                fArticle={fArticle}
                                hideText={isSmall}
                                hideEdit={true}
                                small={true}
                                asLine={styleInline !== STYLE_STRETCH_INLINE}
                                fontSize={fontSize ?? defaultFontSize}
                              />
                              <FArticleSmallPriceComponent
                                fArticle={fArticle}
                              />
                              {!fArticle?.fArticleProp?.hasOption &&
                                showAddCart !== false && (
                                  <Box sx={{ marginLeft: 1 }}>
                                    <FArticleActionButtonComponent
                                      fArticle={fArticle}
                                      depot={DEFAULT_DEPOT}
                                    />
                                  </Box>
                                )}
                            </Grid>
                            {isAdmin && (
                              <Grid
                                item
                                xs={12}
                                sx={{ padding: "0!important" }}
                              >
                                <StockMinMaxFArticleComponent
                                  fArticle={fArticle}
                                  inline={true}
                                  fontSize={fontSize ?? defaultFontSize}
                                  showSwitchPublie={true}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </FArticleLinkComponent>
                        {showIcons && (
                          <FArticleLinkComponent
                            fArticle={fArticle}
                            openNewTab={openNewTab}
                            beforeNavigate={beforeNavigate}
                          >
                            <FArticleLogoComponent
                              fArticle={fArticle}
                              onlyConstructor={!showIcons}
                            />
                          </FArticleLinkComponent>
                        )}
                      </>
                    ) : (
                      <>
                        <FArticleLinkComponent
                          fArticle={fArticle}
                          openNewTab={openNewTab}
                          beforeNavigate={beforeNavigate}
                        >
                          <FArticleKeywordsComponent
                            fArticle={fArticle}
                            sx={{ textAlign: "center" }}
                            tooltip={true}
                            hideKeywordLabel={true}
                            fontSize={fontSize ?? defaultFontSize}
                          />
                          {showNoveRef !== false && (
                            <CopyClipboardComponent
                              className="RobotoMono"
                              sx={{
                                paddingInline: 1,
                                fontWeight: "bold",
                                textAlign: "center",
                                color: theme.palette.success.main,
                                fontSize:
                                  (fontSize ?? defaultFontSize) / 10 + "rem",
                              }}
                              text={fArticle.arRef}
                            />
                          )}
                          {showConstructorRef !== false && (
                            <CopyClipboardComponent
                              className="RobotoMono"
                              sx={{
                                paddingInline: 1,
                                fontWeight: "bold",
                                color: grey[500],
                                textAlign: "center",
                                ...(showNoveRef !== false && {
                                  borderTop: "none",
                                }),
                                fontSize:
                                  (fontSize ?? defaultFontSize) / 10 + "rem",
                              }}
                              text={fArticle.constructeurRef}
                            />
                          )}
                          {isAdmin && fArticle.score && (
                            <Typography
                              sx={{
                                paddingInline: 1,
                                color: grey[500],
                                textAlign: "center",
                                wordBreak: "break-word",
                                fontSize:
                                  (fontSize ?? defaultFontSize) / 10 + "rem",
                              }}
                            >
                              [{fArticle.score}]
                            </Typography>
                          )}
                          <FArticleLogoComponent
                            fArticle={fArticle}
                            onlyConstructor={true}
                          />
                          {showIcons && (
                            <FArticleLogoComponent
                              fArticle={fArticle}
                              onlyConstructor={false}
                            />
                          )}
                          <Typography
                            sx={{
                              paddingInline: 1,
                              wordBreak: "break-word",
                              textAlign: "center",
                              fontSize:
                                (fontSize ?? defaultFontSize) / 10 + "rem",
                            }}
                          >
                            {fArticle.arDesign}
                          </Typography>
                          <Box sx={{ textAlign: "center" }}>
                            <FArticleSmallPriceComponent fArticle={fArticle} />
                          </Box>
                          <FArticleStockComponent
                            fArticle={fArticle}
                            hideText={isSmall}
                            hideEdit={true}
                            small={true}
                            fontSize={fontSize ?? defaultFontSize}
                          />
                          {isAdmin && (
                            <StockMinMaxFArticleComponent
                              fArticle={fArticle}
                              inline={true}
                              fontSize={fontSize ?? defaultFontSize}
                              showSwitchPublie={true}
                            />
                          )}
                          {!fArticle?.fArticleProp?.hasOption &&
                            showAddCart !== false && (
                              <Box sx={{ textAlign: "center" }}>
                                <FArticleActionButtonComponent
                                  fArticle={fArticle}
                                  depot={DEFAULT_DEPOT}
                                />
                              </Box>
                            )}
                        </FArticleLinkComponent>
                      </>
                    )}
                  </Grid>
                  {isAdmin && (
                    <Grid item xs={12}>
                      <InventoryFArticleComponent
                        fArticle={fArticle}
                        fontSize={fontSize ?? defaultFontSize}
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Box>
          );
        })}
      </Box>
    );
  }
);

const MatchingChip: React.FC<State6> = React.memo(({ matching }) => {
  const { t } = useTranslation();

  return (
    <>
      {matching === "exact" && (
        <Chip
          color="success"
          label={t("word.exact")}
          size="small"
          sx={{ cursor: "pointer" }}
        />
      )}
      {matching === "partial" && (
        <Chip
          color="warning"
          label={t("word.partial")}
          size="small"
          sx={{ cursor: "pointer" }}
        />
      )}
    </>
  );
});

export const FArticleSmallComponentAutocomplete: React.FC<State4> = React.memo(
  ({ fArticle, searchFArticle }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const [matching, setMatching] = React.useState<string>("");

    React.useEffect(() => {
      setMatching(getMatching(searchFArticle, fArticle, isAdmin ?? false));
    }, [searchFArticle, fArticle?.arRef]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <FArticleSliderComponent
            fArticle={fArticle}
            dots={false}
            singleImage={true}
          />
        </Grid>
        <Grid item xs={isSmall ? 7 : 8} sx={{ wordBreak: "break-word" }}>
          <MatchingChip matching={matching} />
          <Box>{fArticle.arDesign}</Box>
          <Box>
            <CopyClipboardComponent
              className="RobotoMono"
              component="span"
              sx={{
                color: theme.palette.success.main,
              }}
              text={fArticle?.arRef}
            />
          </Box>
          <Box>
            <CopyClipboardComponent
              className="RobotoMono"
              component="span"
              text={fArticle?.constructeurRef}
            />
          </Box>
        </Grid>
        <Grid item xs={isSmall ? 3 : 2}>
          <PriceComponent price={fArticle.priceDto.priceTtc} />
          <FArticleStockComponent
            small={true}
            fArticle={fArticle}
            hideText={true}
          />
          <FArticleActionButtonComponent
            fArticle={fArticle}
            quantity={1}
            onlyAddCard={true}
            depot={DEFAULT_DEPOT}
          />
        </Grid>
      </Grid>
    );
  }
);

export const DriverComponentAutocomplete: React.FC<State5> = React.memo(
  ({ driver }) => {
    const { t } = useTranslation();

    return (
      <Typography component="span">
        {t("sentence.searchDriver") + ": " + driver.name + " ..."}
      </Typography>
    );
  }
);

export const FArticleSmallComponent: React.FC<State> = React.memo(
  ({
    fArticles,
    nbColumn,
    loading,
    showAddCart,
    slider,
    inline,
    defaultStyleInline,
    openNewTab,
  }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const beforeNavigate = React.useCallback(
      (fArticle: FArticleSmallInterface, target: any) => {
        if (target.classList.contains("slick-arrow")) {
          return;
        }
        const searchParamsObject = searchParamToObject(searchParams);
        searchParamsObject.page = fArticle.page;
        searchParamsObject.scrollArticle = fArticle.arRef;
        setSearchParams(searchParamsObject, {
          replace: true,
        });
      },
      [searchParams, setSearchParams]
    );

    const props: State3 = {
      fArticles: fArticles,
      nbColumn: nbColumn,
      showAddCart: showAddCart,
      slider: slider,
      defaultStyleInline: defaultStyleInline,
      openNewTab: openNewTab,
      beforeNavigate: beforeNavigate,
    };

    return (
      <Box sx={{ marginBottom: 1, color: "black", display: "initial" }}>
        {loading ? (
          <Box sx={{ display: "flex" }}>
            {Array.from(Array(nbColumn)).map((column, indexColumn) => (
              <Skeleton
                key={indexColumn}
                variant="rectangular"
                height={250}
                width="100%"
                sx={{ marginX: 1 }}
              />
            ))}
          </Box>
        ) : (
          <>
            {inline === true ? (
              <FArticleSmallComponentLine {...props} />
            ) : (
              <FArticleSmallComponentColumn {...props} />
            )}
          </>
        )}
      </Box>
    );
  },
  (prevProps, nextProps): boolean => {
    return (
      prevProps.nbColumn === nextProps.nbColumn &&
      prevProps.loading === nextProps.loading &&
      prevProps.showAddCart === nextProps.showAddCart &&
      prevProps.slider === nextProps.slider &&
      prevProps.inline === nextProps.inline &&
      prevProps.defaultStyleInline === nextProps.defaultStyleInline &&
      prevProps.openNewTab === nextProps.openNewTab &&
      JSON.stringify(prevProps.fArticles.map((fArticle) => fArticle.arRef)) ===
        JSON.stringify(nextProps.fArticles.map((fArticle) => fArticle.arRef))
    );
  }
);
