import React, { useImperativeHandle, useRef } from "react";
import { InputInterface } from "../../../../interfaces/InputInterface";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { RootState } from "../../../../app/store";
import {
  Checkbox,
  DialogContent,
  DialogProps,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { requestApi } from "../../../../helpers/RequestApi";
import { DELETE, PATCH, POST } from "../../../../utils/MethodUtils";
import getErrorApi from "../../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import {
  AUTHENTICATION_TOKEN_URL,
  FLIVRAISON_URL,
} from "../../../../utils/UrlsUtils";
import { addUpdateLivraison, set } from "../../../../app/globalSlice";
import checkedValidator from "../../../../helpers/validator/CheckedValidator";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { useTheme } from "@mui/material/styles";
import { FPaysInterface } from "../../../../interfaces/FPaysInterface";
import { FLivraisonInterface } from "../../../../interfaces/UserInterface";
import { initAppHelper } from "../../../../helpers/InitAppHelper";
import { getThisPays } from "../../../../helpers/UserHelper";
import { GUADELOUPE, GUYANE, MARTINIQUE } from "../../../../utils/UserUtils";
import sage69Validator from "../../../../helpers/validator/Sage69Validator";
import sageNameValidator from "../../../../helpers/validator/SageNameValidator";
import sagePostalValidator from "../../../../helpers/validator/SagePostalValidator";
import mailValidator from "../../../../helpers/validator/MailValidator";
import PhoneInputComponent from "../PhoneInputComponent";

interface State {
  fLivraison?: FLivraisonInterface;
  onAddressChanged?: Function;
  closeDialog?: any;
  setCanCloseDialog?: any;
  isBillingAddress?: boolean;
  hideActionButton?: boolean;
}

interface FormState {
  liIntitule: InputInterface;
  liContact: InputInterface;
  liAdresse: InputInterface;
  liEmail: InputInterface;
  liComplement: InputInterface;
  liCodepostal: InputInterface;
  liVille: InputInterface;
  liPays: InputInterface;
  liPrincipal: InputInterface;
}

const LivraisonFormComponent = React.memo(
  React.forwardRef(
    (
      {
        fLivraison,
        closeDialog,
        setCanCloseDialog,
        onAddressChanged,
        isBillingAddress,
        hideActionButton,
      }: State,
      ref
    ) => {
      const refCtTelephone: any = useRef();

      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const user = useAppSelector((state: RootState) => state.globalState.user);
      const cart = useAppSelector((state: RootState) => state.globalState.cart);
      const fPays = useAppSelector(
        (state: RootState) => state.globalState.fPays
      );
      const [thisFPays, setThisFPays] = React.useState<FPaysInterface[]>(
        getThisPays()
      );
      const [maxWidth] = React.useState<DialogProps["maxWidth"]>("md");
      const [loading, setLoading] = React.useState(false);
      const theme = useTheme();
      const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
      const { t } = useTranslation();
      const dispatch = useAppDispatch();
      const getDefaultValues = React.useCallback((): FormState => {
        let liPays = fLivraison?.liPays ?? "";
        if (!thisFPays.find((x) => x.paIntitule === liPays)) {
          liPays = "";
        }
        return {
          liIntitule: { value: fLivraison?.liIntitule ?? "", error: "" },
          liContact: { value: fLivraison?.liContact ?? "", error: "" },
          liAdresse: { value: fLivraison?.liAdresse ?? "", error: "" },
          liEmail: { value: fLivraison?.liEmail ?? "", error: "" },
          liComplement: { value: fLivraison?.liComplement ?? "", error: "" },
          liCodepostal: { value: fLivraison?.liCodepostal ?? "", error: "" },
          liVille: { value: fLivraison?.liVille ?? "", error: "" },
          liPays: { value: liPays, error: "" },
          liPrincipal: {
            value:
              fLivraison?.liPrincipal === 1 ??
              (user?.fLivraisons ? user.fLivraisons.length === 0 : false),
            error: "",
          },
        };
      }, [
        fLivraison?.liAdresse,
        fLivraison?.liEmail,
        fLivraison?.liCodepostal,
        fLivraison?.liComplement,
        fLivraison?.liContact,
        fLivraison?.liIntitule,
        fLivraison?.liPays,
        fLivraison?.liPrincipal,
        fLivraison?.liVille,
        thisFPays,
        user?.fLivraisons,
      ]);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());
      const [openDialogForm, setOpenDialogForm] = React.useState(false);

      const handleChangeSelect = React.useCallback(
        (prop: keyof FormState) => (event: SelectChangeEvent) => {
          setValues((v) => {
            if (prop === "liPays") {
              v.liCodepostal.error = "";
            }
            return {
              ...v,
              [prop]: {
                // @ts-ignore
                ...v[prop],
                value: event.target.value as string,
                error: "",
              },
            };
          });
        },
        []
      );

      const handleCloseDialogForm = React.useCallback(() => {
        if (loading) {
          return;
        }
        setOpenDialogForm(false);
      }, [loading]);

      const handleChange = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              if (prop === "liCodepostal") {
                v.liPays.error = "";
              }
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.value, error: "" },
              };
            });
          },
        []
      );

      const handleChangeCheckbox = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.checked, error: "" },
              };
            });
          },
        []
      );

      const openDialogDeleteLivraison = React.useCallback(() => {
        setOpenDialogForm(true);
      }, []);

      const getErrors = React.useCallback(() => {
        const liIntituleError = sage69Validator(values.liIntitule.value);
        const liContactError = sageNameValidator(values.liContact.value);
        const liAdresseError = sageNameValidator(values.liAdresse.value);
        const liEmailError = mailValidator(values.liEmail.value, true);
        const liCodepostalError = sagePostalValidator(
          values.liCodepostal.value
        );
        const liVilleError = sageNameValidator(values.liVille.value);
        const liPaysError = sageNameValidator(values.liPays.value);
        const ctTelephoneValue = refCtTelephone.current.getValue();
        let paysMatchZip = "";
        if (
          liCodepostalError === "" &&
          liPaysError === "" &&
          ((values.liPays.value === GUADELOUPE &&
            !values.liCodepostal.value.startsWith("971")) ||
            (values.liPays.value === MARTINIQUE &&
              !values.liCodepostal.value.startsWith("972")) ||
            (values.liPays.value === GUYANE &&
              !values.liCodepostal.value.startsWith("973")))
        ) {
          paysMatchZip = t("error.countryMatchZip");
        }
        let mainError = "";
        if (!user?.fLivraisons || user.fLivraisons.length === 0) {
          mainError = checkedValidator(values.liPrincipal.value);
          if (mainError) {
            mainError = t("error.onlyLivraison");
          }
        }
        if (
          liIntituleError ||
          liContactError ||
          liAdresseError ||
          liEmailError ||
          liCodepostalError ||
          liVilleError ||
          liPaysError ||
          mainError ||
          paysMatchZip ||
          ctTelephoneValue === undefined
        ) {
          const newValue: FormState = { ...values };
          if (liIntituleError) {
            newValue.liIntitule.error = liIntituleError;
          }
          if (liContactError) {
            newValue.liContact.error = liContactError;
          }
          if (liAdresseError) {
            newValue.liAdresse.error = liAdresseError;
          }
          if (liEmailError) {
            newValue.liEmail.error = liEmailError;
          }
          if (liCodepostalError) {
            newValue.liCodepostal.error = liCodepostalError;
          }
          if (liVilleError) {
            newValue.liVille.error = liVilleError;
          }
          if (liPaysError) {
            newValue.liPays.error = liPaysError;
          }
          if (mainError) {
            newValue.liPrincipal.error = mainError;
          }
          if (paysMatchZip) {
            newValue.liCodepostal.error = paysMatchZip;
            newValue.liPays.error = paysMatchZip;
          }
          setValues(newValue);
          return true;
        }
        return false;
      }, [t, user?.fLivraisons, values]);

      const getValue = React.useCallback(() => {
        if (getErrors()) {
          return undefined;
        }
        const ctTelephoneValue = refCtTelephone.current.getValue();
        return {
          liIntitule: values.liIntitule.value.trim(),
          liContact: values.liContact.value.trim(),
          liAdresse: values.liAdresse.value.trim(),
          liEmail: values.liEmail.value.trim(),
          liComplement: values.liComplement.value.trim(),
          liCodepostal: values.liCodepostal.value.trim(),
          liVille: values.liVille.value.trim(),
          liPays: values.liPays.value.trim(),
          liTelephone: ctTelephoneValue.trim(),
          liPrincipal: values.liPrincipal.value ? 1 : 0,
        };
      }, [
        getErrors,
        values.liAdresse.value,
        values.liCodepostal.value,
        values.liComplement.value,
        values.liContact.value,
        values.liEmail.value,
        values.liIntitule.value,
        values.liPays.value,
        values.liPrincipal.value,
        values.liVille.value,
      ]);

      const save = React.useCallback(async () => {
        setLoading(true);
        if (setCanCloseDialog) {
          setCanCloseDialog(false);
        }
        if (getErrors()) {
          setLoading(false);
          setCanCloseDialog(true);
          return;
        }
        let url = FLIVRAISON_URL;
        if (fLivraison) {
          url += "/" + fLivraison.liNo;
        }
        const response = await requestApi({
          method: fLivraison ? PATCH : POST,
          path: url,
          allowError: true,
          token: token,
          body: getValue(),
        });
        if (setCanCloseDialog) {
          setCanCloseDialog(true);
        }
        if (response.statusCode === 200 || response.statusCode === 201) {
          if (user && !user.sage && response.statusCode === 201) {
            let param = "";
            if (cart !== undefined) {
              param = "?cart=" + cart.id;
            }
            const response = await requestApi({
              method: POST,
              path: AUTHENTICATION_TOKEN_URL + param,
              allowError: true,
              body: {
                identifier: user.ctEmail,
                password: user.motDePasse,
              },
            });
            if (response.statusCode === 200) {
              await initAppHelper(response.content.token);
            } else if (response.statusCode === 401) {
              toastr.info(t("word.info"), t("error.reconnect"));
            }
          }
          if (onAddressChanged) {
            onAddressChanged(response.content);
          }
          if (closeDialog) {
            closeDialog(true);
          }
          dispatch(addUpdateLivraison(response.content));
          toastr.success(
            t("word.success"),
            t(
              fLivraison
                ? "sentence.notification.livraison_updated"
                : "sentence.notification.livraison_created"
            )
          );
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [
        setCanCloseDialog,
        getErrors,
        fLivraison,
        token,
        getValue,
        user,
        onAddressChanged,
        closeDialog,
        dispatch,
        t,
        cart,
      ]);

      const deleteLivraison = React.useCallback(async () => {
        setLoading(true);
        if (setCanCloseDialog) {
          setCanCloseDialog(false);
        }
        const response = await requestApi({
          method: DELETE,
          path: FLIVRAISON_URL + "/" + fLivraison?.liNo,
          allowError: true,
          token: token,
        });
        if (setCanCloseDialog) {
          setCanCloseDialog(true);
        }
        if (response.statusCode === 204) {
          handleCloseDialogForm();
          if (onAddressChanged) {
            onAddressChanged(response.content);
          }
          if (closeDialog) {
            closeDialog(true);
          }
          dispatch(
            set({
              // @ts-ignore
              user: {
                ...user,
                fLivraisons:
                  user?.fLivraisons?.filter(
                    (x) => x.liNo !== fLivraison?.liNo
                  ) ?? [],
              },
            })
          );
          toastr.success(
            t("word.success"),
            t("sentence.notification.livraison_deleted")
          );
        } else {
          if (
            response.content?.detail &&
            response.content.detail.includes("est utilis")
          ) {
            toastr.error(t("word.error"), t("sentence.cantDeleteLivraison"));
          } else {
            for (let message of getErrorApi(response.content)) {
              toastr.error(t("word.error"), t(message));
            }
          }
        }
        setLoading(false);
      }, [
        closeDialog,
        dispatch,
        fLivraison?.liNo,
        handleCloseDialogForm,
        onAddressChanged,
        setCanCloseDialog,
        t,
        token,
        user,
      ]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        setValues(getDefaultValues());
        if (fLivraison) {
          setTimeout(() => {
            getErrors();
          });
        }
      }, [fLivraison]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setThisFPays(getThisPays());
      }, [fPays]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          <Dialog
            maxWidth={maxWidth}
            fullScreen={fullScreen}
            onClose={handleCloseDialogForm}
            open={openDialogForm}
          >
            <DialogContent>
              <Typography>{t("sentence.deleteLivraison")}</Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
              <Button onClick={handleCloseDialogForm}>{t("word.no")}</Button>
              <LoadingButton
                loading={loading}
                variant="contained"
                onClick={deleteLivraison}
              >
                {t("word.yes")}
              </LoadingButton>
            </DialogActions>
          </Dialog>
          <Grid container spacing={1}>
            {isBillingAddress !== true && (
              <Grid item xs={12}>
                <FormControl error={!!values.liPrincipal.error}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.liPrincipal.value}
                          onChange={handleChangeCheckbox("liPrincipal")}
                        />
                      }
                      label={t("field.mainLivraison")}
                    />
                  </FormGroup>
                  {!!values.liPrincipal.error && (
                    <FormHelperText error>
                      {t(values.liPrincipal.error ?? "")}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth={true}
                required={true}
                autoComplete="off"
                error={!!values.liIntitule.error}
                helperText={t(values.liIntitule.error ?? "")}
                sx={{ width: "100%" }}
                type="text"
                value={values.liIntitule.value}
                onChange={handleChange("liIntitule")}
                label={t("field.liIntitule.label")}
                placeholder={t("field.liIntitule.placeholder")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PhoneInputComponent
                ref={refCtTelephone}
                initCtTelephone={fLivraison?.liTelephone}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth={true}
                autoComplete="off"
                error={!!values.liContact.error}
                helperText={t(values.liContact.error ?? "")}
                sx={{ width: "100%" }}
                required
                type="text"
                value={values.liContact.value}
                onChange={handleChange("liContact")}
                label={t("field.contact")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth={true}
                autoComplete="off"
                error={!!values.liAdresse.error}
                helperText={t(values.liAdresse.error ?? "")}
                sx={{ width: "100%" }}
                required
                type="text"
                value={values.liAdresse.value}
                onChange={handleChange("liAdresse")}
                label={t("field.address")}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth={true}
                autoComplete="off"
                error={!!values.liEmail.error}
                helperText={t(values.liEmail.error ?? "")}
                sx={{ width: "100%" }}
                type="text"
                value={values.liEmail.value}
                onChange={handleChange("liEmail")}
                label={t("word.email")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth={true}
                autoComplete="off"
                error={!!values.liComplement.error}
                helperText={t(values.liComplement.error ?? "")}
                sx={{ width: "100%" }}
                type="text"
                value={values.liComplement.value}
                onChange={handleChange("liComplement")}
                label={t("field.address2")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth={true}
                autoComplete="off"
                error={!!values.liCodepostal.error}
                helperText={t(values.liCodepostal.error ?? "")}
                sx={{ width: "100%" }}
                required
                type="text"
                value={values.liCodepostal.value}
                onChange={handleChange("liCodepostal")}
                label={t("field.zipcode")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth={true}
                autoComplete="off"
                error={!!values.liVille.error}
                helperText={t(values.liVille.error ?? "")}
                sx={{ width: "100%" }}
                required
                type="text"
                value={values.liVille.value}
                onChange={handleChange("liVille")}
                label={t("field.city")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth required error={!!values.liPays.error}>
                <InputLabel id="country-label">{t("field.country")}</InputLabel>
                <Select
                  labelId="country-label"
                  value={values.liPays.value}
                  label={t("field.country")}
                  onChange={handleChangeSelect("liPays")}
                >
                  {thisFPays.map((country, indexCountry) => (
                    <MenuItem value={country.paIntitule} key={indexCountry}>
                      {country.paIntitule}
                    </MenuItem>
                  ))}
                </Select>
                {!!values.liPays.error && (
                  <FormHelperText error>
                    {t(values.liPays.error ?? "")}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {hideActionButton !== true && (
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <LoadingButton disabled={loading} onClick={closeDialog}>
                  {t("word.cancel")}
                </LoadingButton>
                <LoadingButton
                  loading={loading}
                  onClick={openDialogDeleteLivraison}
                  color="error"
                >
                  {t("word.delete")}
                </LoadingButton>
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  onClick={save}
                >
                  {t("word.save")}
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </>
      );
    }
  )
);
export default LivraisonFormComponent;
