import * as React from "react";
import { SyntheticEvent, useImperativeHandle } from "react";
import {
  FArtfournissFArticleInterface,
  FArticleInterface,
  FournisseurArticleInterface,
} from "../../../interfaces/FArticleInterface";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import {
  Autocomplete,
  Checkbox,
  Grid,
  Link as LinkReact,
  TextField,
  Tooltip,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET, POST } from "../../../utils/MethodUtils";
import { FARTFOURNISSES_URL, FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import { priceFormat } from "../../../utils/FormatUtils";
import AddIcon from "@mui/icons-material/Add";
import { InputInterface } from "../../../interfaces/InputInterface";
import { UserInterface } from "../../../interfaces/UserInterface";
import RemoveIcon from "@mui/icons-material/Remove";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";
import Typography from "@mui/material/Typography";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import LinkIcon from "@mui/icons-material/Link";
import FactoryIcon from "@mui/icons-material/Factory";
import {
  getPropertiesStartWith,
  getUrlsFournisseur,
} from "../../../helpers/FArticleHelper";
import { FArticleFournisseurLinksComponent } from "./FArticleFournisseurLinksComponent";
import Sage19Validator from "../../../helpers/validator/Sage19Validator";
import CopyClipboardComponent from "../CopyClipboardComponent";
import { useTheme } from "@mui/material/styles";
import { PRODUCT_PAGE } from "../../../utils/RouteUtils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import { green, red, yellow } from "@mui/material/colors";
import CircleIcon from "@mui/icons-material/Circle";
import {
  FOURNISSEUR_STOCK_STATUS_AVAILABLE,
  FOURNISSEUR_STOCK_STATUS_IN_STOCK,
} from "../../../utils/NoveFournisseurUtils";

interface State {
  fArticle: FArticleInterface | undefined;
  edit?: boolean;
  setFArticle?: Function;
  createFrom?: FArticleInterface;
  fournisseurProduct?: FournisseurArticleInterface;
  changeArPrixach: Function;
}

interface State2 {
  fArticle: FArticleInterface | undefined;
  fArtfournis: FArtfournissFArticleInterface;
  removeFArtfournisForm: Function;
  index: number;
  handleChangeAfPrincipal: Function;
}

interface State3 {
  fArtfournis: FArtfournissFArticleInterface;
}

interface FormStateFArtfournis {
  ref: any;
  fArtfournis: FArtfournissFArticleInterface;
}

interface FormState {
  fArtfourniss: (FormStateFArtfournis | undefined)[];
}

interface FormState2 {
  afPrincipal: InputInterface;
  ctNum: InputInterface;
  afReffourniss: InputInterface;
  afPrixach: InputInterface;
}

const FArticleFournisseurFormComponent = React.memo(
  React.forwardRef(
    (
      {
        fArticle,
        fArtfournis,
        removeFArtfournisForm,
        index,
        handleChangeAfPrincipal,
      }: State2,
      ref
    ) => {
      const { t } = useTranslation();
      const fournisseurs = useAppSelector(
        (state: RootState) => state.globalState.fournisseurs
      );
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const [fArtfournisses, setFArtfournisses] = React.useState<
        FArtfournissFArticleInterface[] | null
      >(null);
      const theme = useTheme();

      const getDefaultValue = React.useCallback((): FormState2 => {
        const thisFournisseur = fournisseurs?.find(
          (f) =>
            f.userIdentifier ===
            fArtfournis.ctNum.replace("/api/f_comptets/", "")
        );
        return {
          ctNum: {
            value: thisFournisseur ?? null,
            error: "",
          },
          afPrincipal: {
            value: fArtfournis.afPrincipal ?? 0,
            error: "",
          },
          afReffourniss: {
            value: fArtfournis.afReffourniss ?? "",
            error: "",
          },
          afPrixach: {
            value: Number(fArtfournis.afPrixach) ?? "",
            error: "",
          },
        };
      }, [fournisseurs, fArtfournis]);

      const [values, setValues] = React.useState<FormState2>(getDefaultValue());

      const handleChange = React.useCallback(
        (prop: keyof FormState2) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.value, error: "" },
              };
            });
          },
        []
      );

      const handleChangeAutocomplete = React.useCallback(
        (prop: keyof FormState2) =>
          (event: SyntheticEvent<Element, Event>, value: any) => {
            setValues((v) => {
              return {
                ...v,
                // @ts-ignore
                [prop]: { ...v[prop], value: value, error: "" },
              };
            });
          },
        []
      );

      const getValue = React.useCallback((): any => {
        const ctNumError = notEmptyValidator(
          values.ctNum.value?.userIdentifier
        );
        const afReffournissError = Sage19Validator(
          values.afReffourniss.value,
          true,
          false
        );
        if (ctNumError || afReffournissError) {
          setValues((v) => {
            if (ctNumError) {
              v.ctNum.error = ctNumError;
            }
            if (afReffournissError) {
              v.afReffourniss.error = afReffournissError;
            }
            return { ...v };
          });
          return undefined;
        }
        return {
          ctNum: "/api/f_comptets/" + values.ctNum.value?.userIdentifier,
          afReffourniss: values.afReffourniss.value,
          afPrincipal: values.afPrincipal.value,
          afPrixach: values.afPrixach.value.toString(),
        };
      }, [
        values.afPrincipal.value,
        values.afPrixach.value,
        values.afReffourniss.value,
        values.ctNum.value?.userIdentifier,
      ]);

      const searchAfReffourniss = React.useCallback(async () => {
        setFArtfournisses(null);
        if (
          !values.ctNum.value?.userIdentifier ||
          !values.afReffourniss.value
        ) {
          return;
        }
        const response = await requestApi({
          method: GET,
          path:
            FARTFOURNISSES_URL +
            objectToQuery({
              ctNum: values.ctNum.value?.userIdentifier,
              afReffourniss: values.afReffourniss.value,
            }),
          allowError: true,
          token: token,
        });
        if (response.statusCode === 200) {
          setFArtfournisses(
            response.content.filter(
              (x: FArtfournissFArticleInterface) =>
                x.fArticle?.arRef && x.fArticle.arRef !== fArticle?.arRef
            )
          );
        } else {
          if (response.statusCode === 401) {
            toastr.info(t("word.info"), t("error.reconnect"));
          } else {
            for (let message of getErrorApi(response.content)) {
              toastr.error(t("word.error"), t(message));
            }
          }
        }
      }, [
        fArticle?.arRef,
        t,
        token,
        values.afReffourniss.value,
        values.ctNum.value?.userIdentifier,
      ]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
        setAfPrincipal(newAfPrincipal: number) {
          setValues((v) => {
            return {
              ...v,
              afPrincipal: {
                ...v.afPrincipal,
                value: newAfPrincipal,
                error: "",
              },
            };
          });
        },
        getArPrixach() {
          return values.afPrixach.value;
        },
      }));

      React.useEffect(() => {
        setValues(getDefaultValue());
      }, [fournisseurs, fArtfournis]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        const timeoutTyping = setTimeout(() => {
          searchAfReffourniss();
        }, 500);
        return () => clearTimeout(timeoutTyping);
      }, [values.afReffourniss.value, values.ctNum.value?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Grid container spacing={1} sx={{ marginY: 0.5 }}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                options={fournisseurs ?? []}
                getOptionLabel={(option: UserInterface) =>
                  "[" + option.userIdentifier + "] " + option.ctIntitule ?? ""
                }
                isOptionEqualToValue={(
                  option: UserInterface,
                  value: UserInterface
                ) => option.userIdentifier === value.userIdentifier}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.userIdentifier}>
                      <span>
                        {"[" +
                          option.userIdentifier +
                          "] " +
                          option.ctIntitule ?? ""}
                      </span>
                    </li>
                  );
                }}
                onChange={handleChangeAutocomplete("ctNum")}
                value={values.ctNum.value}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="off"
                    error={!!values.ctNum.error}
                    helperText={t(values.ctNum.error ?? "")}
                    label={t("word.supplier")}
                    placeholder={t("word.supplier")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={1}>
              <Checkbox
                checked={values.afPrincipal.value === 1}
                onClick={() => {
                  handleChangeAfPrincipal(index);
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <TextField
                autoComplete="off"
                error={!!values.afReffourniss.error}
                helperText={t(values.afReffourniss.error ?? "")}
                sx={{ width: "100%" }}
                type="text"
                value={values.afReffourniss.value}
                onChange={handleChange("afReffourniss")}
                label={t("field.afReffourniss")}
              />
              {fArtfournisses && (
                <>
                  {fArtfournisses.length > 0 ? (
                    <>
                      <Typography sx={{ color: theme.palette.error.main }}>
                        {t("word.notUnique")}
                      </Typography>
                      {fArtfournisses.map((x, i) => (
                        <Box key={i} sx={{ display: "flex" }}>
                          <CopyClipboardComponent
                            text={x.fArticle?.arRef ?? ""}
                          />
                          <Link
                            style={{ textDecoration: "none", color: "inherit" }}
                            to={PRODUCT_PAGE + "/" + x.fArticle?.arRef ?? ""}
                          >
                            <IconButton>
                              <VisibilityIcon />
                            </IconButton>
                          </Link>
                        </Box>
                      ))}
                    </>
                  ) : (
                    <>{t("word.unique")}</>
                  )}
                </>
              )}
            </Grid>
            <Grid item md={2} xs={12}>
              <TextField
                fullWidth={true}
                autoComplete="off"
                error={!!values.afPrixach.error}
                helperText={t(values.afPrixach.error ?? "")}
                type="number"
                required
                value={values.afPrixach.value}
                onChange={handleChange("afPrixach")}
                label={t("field.afPrixach")}
              />
            </Grid>
          </Grid>
          <Box>
            <IconButton onClick={() => removeFArtfournisForm(index)}>
              <RemoveIcon />
            </IconButton>
          </Box>
        </Box>
      );
    }
  )
);

const FArticleFournisseurStockComponent = React.memo(
  React.forwardRef(({ fArtfournis }: State3, ref) => {
    const { i18n } = useTranslation();

    return (
      <>
        {fArtfournis.fArtfournissProp && (
          <>
            {fArtfournis.fArtfournissProp.stockStatus ===
            FOURNISSEUR_STOCK_STATUS_IN_STOCK ? (
              <>
                <CircleIcon
                  sx={{
                    width: "30px",
                    color: green[500],
                  }}
                />
              </>
            ) : fArtfournis.fArtfournissProp.stockStatus ===
              FOURNISSEUR_STOCK_STATUS_AVAILABLE ? (
              <>
                <CircleIcon
                  sx={{
                    width: "30px",
                    color: yellow[500],
                  }}
                />
              </>
            ) : (
              <>
                <CircleIcon
                  sx={{
                    width: "30px",
                    color: red[500],
                  }}
                />
              </>
            )}
            <Typography>
              {fArtfournis.fArtfournissProp.stock !== null &&
                fArtfournis.fArtfournissProp.stock > 0 && (
                  <Typography
                    variant="caption"
                    component="span"
                    color="primary"
                  >
                    {"[" + fArtfournis.fArtfournissProp.stock + "] "}
                  </Typography>
                )}
              {new Date(fArtfournis.fArtfournissProp.updated).toLocaleString(
                i18n.language,
                {
                  dateStyle: "short",
                }
              )}
            </Typography>
          </>
        )}
      </>
    );
  })
);

export const FArticleFournisseursComponent = React.memo(
  React.forwardRef(
    (
      {
        fArticle,
        edit,
        setFArticle,
        createFrom,
        fournisseurProduct,
        changeArPrixach,
      }: State,
      ref
    ) => {
      const { t, i18n } = useTranslation();
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const fournisseurs = useAppSelector(
        (state: RootState) => state.globalState.fournisseurs
      );
      const getDefaultValue = React.useCallback((): FormState => {
        let thisFArtfourniss: (FormStateFArtfournis | undefined)[];
        if (createFrom) {
          if (fournisseurProduct) {
            thisFArtfourniss = [];
            for (const prop of getPropertiesStartWith(
              fournisseurProduct,
              "idArtCatalogue"
            )) {
              if (
                // @ts-ignore
                fournisseurProduct[prop] === null
              ) {
                continue;
              }
              thisFArtfourniss.push({
                ref: React.createRef(),
                fArtfournis: {
                  // @ts-ignore
                  afPrixach: Number(fournisseurProduct[prop].prixAchat),
                  // @ts-ignore
                  afReffourniss: fournisseurProduct[prop].refFournisseur,
                  // @ts-ignore
                  ctNum: fournisseurProduct[prop].ctNum,
                  afPrincipal: 0,
                },
              });
            }
          } else {
            thisFArtfourniss = [
              {
                ref: React.createRef(),
                fArtfournis: {
                  afPrixach: "",
                  afReffourniss: "",
                  ctNum: "",
                  afPrincipal: 0,
                },
              },
            ];
          }
        } else {
          thisFArtfourniss =
            fArticle?.fArtfourniss?.map((fArtfournis) => {
              return {
                ref: React.createRef(),
                fArtfournis: fArtfournis,
              };
            }) ?? [];
        }
        return {
          fArtfourniss: thisFArtfourniss,
        };
      }, [createFrom, fArticle?.fArtfourniss, fournisseurProduct]);
      const [values, setValues] = React.useState<FormState>(getDefaultValue());
      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [loading, setLoading] = React.useState(false);

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const removeFArtfournisForm = React.useCallback((index: number) => {
        setValues((v) => {
          v.fArtfourniss[index] = undefined;
          return { ...v };
        });
      }, []);

      const addFArtfournisForm = React.useCallback(() => {
        setValues((v) => {
          v.fArtfourniss.push({
            ref: React.createRef(),
            fArtfournis: {
              afPrixach: "",
              afReffourniss: "",
              ctNum: "",
              afPrincipal: 0,
            },
          });
          return { ...v };
        });
      }, []);

      const getValue = React.useCallback((): any => {
        const result = values.fArtfourniss
          .filter((f) => f)
          .map((f) => f?.ref.current.getValue());
        if (result.filter((f) => f === undefined).length > 0) {
          return undefined;
        }
        return {
          fArtfourniss: result,
        };
      }, [values.fArtfourniss]);

      const handleChangeAfPrincipal = React.useCallback(
        (index: number): any => {
          for (const [key, value] of Object.entries(values.fArtfourniss)) {
            const isPrincipal = index.toString() === key;
            if (isPrincipal) {
              changeArPrixach(value?.ref?.current?.getArPrixach());
            }
            value?.ref?.current?.setAfPrincipal(isPrincipal ? 1 : 0);
          }
        },
        [changeArPrixach, values.fArtfourniss]
      );

      const save = React.useCallback(async () => {
        const body = getValue();
        if (body === undefined) {
          return;
        }
        setLoading(true);
        const fArticleApi = new FormData();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            ...body,
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          if (setFArticle) {
            setFArticle(response.content);
          }
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValue());
      }, [fArticle?.fArtfourniss, thisEdit, edit, fournisseurProduct]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {(fArticle || createFrom) && isAdmin && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  flex: 1,
                }}
              >
                {edit || thisEdit ? (
                  <>
                    <Grid item xs={12}>
                      {t("word.suppliers") + ": "}
                    </Grid>
                    {values.fArtfourniss.map(
                      (fArtfournisForm, indexFArtfournisForm) => (
                        <Grid item xs={12} key={indexFArtfournisForm}>
                          {fArtfournisForm && (
                            <FArticleFournisseurFormComponent
                              ref={fArtfournisForm.ref}
                              fArticle={fArticle}
                              fArtfournis={fArtfournisForm.fArtfournis}
                              removeFArtfournisForm={removeFArtfournisForm}
                              index={indexFArtfournisForm}
                              handleChangeAfPrincipal={handleChangeAfPrincipal}
                            />
                          )}
                        </Grid>
                      )
                    )}
                    <Box sx={{ textAlign: "center" }}>
                      <IconButton onClick={addFArtfournisForm}>
                        <AddIcon />
                      </IconButton>
                    </Box>
                    {!edit && (
                      <Box sx={{ textAlign: "center" }}>
                        <Tooltip title={t("word.cancel")}>
                          <IconButton
                            onClick={handleThisEdit}
                            disabled={loading}
                          >
                            <CancelIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("word.save")}>
                          <LoadingButton
                            variant="text"
                            color="inherit"
                            sx={{
                              borderRadius: "50%",
                              minWidth: "auto",
                              padding: "8px",
                              color: "rgba(0, 0, 0, 0.54)",
                            }}
                            loading={loading}
                            onClick={save}
                          >
                            <SaveIcon />
                          </LoadingButton>
                        </Tooltip>
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    <TableContainer component={Paper}>
                      <Table size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell>{t("word.supplier")}</TableCell>
                            <TableCell>{t("word.refSupplier")}</TableCell>
                            <TableCell>{t("word.buyPrice")}</TableCell>
                            <TableCell></TableCell>
                            <TableCell colSpan={3} sx={{ textAlign: "center" }}>
                              <FArticleFournisseurLinksComponent
                                fArticle={fArticle}
                              />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {fArticle?.fArtfourniss?.map(
                            (fArtfournis, indexFArtfournis) => {
                              const thisFournisseur = fournisseurs?.find(
                                (f) =>
                                  f.userIdentifier ===
                                  fArtfournis.ctNum.replace(
                                    "/api/f_comptets/",
                                    ""
                                  )
                              );
                              const urlFournisseur = getUrlsFournisseur(
                                fArtfournis,
                                thisFournisseur,
                                fArticle
                              );
                              return (
                                <TableRow key={indexFArtfournis}>
                                  <TableCell>
                                    <Typography
                                      component="span"
                                      color={
                                        fArtfournis.afPrincipal === 1
                                          ? "primary"
                                          : undefined
                                      }
                                      sx={{
                                        ...(fArtfournis.afPrincipal === 1 && {
                                          fontWeight: "bold",
                                        }),
                                      }}
                                    >
                                      <Typography
                                        component="span"
                                        color="primary"
                                      >
                                        {"[" +
                                          thisFournisseur?.userIdentifier +
                                          "] "}
                                      </Typography>
                                      {thisFournisseur?.ctIntitule}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    {fArtfournis.afReffourniss}
                                  </TableCell>
                                  <TableCell>
                                    {priceFormat(
                                      Number(fArtfournis.afPrixach) * 100,
                                      i18n.language,
                                      "EUR"
                                    )}
                                  </TableCell>
                                  <TableCell>
                                    <FArticleFournisseurStockComponent
                                      fArtfournis={fArtfournis}
                                    />
                                  </TableCell>
                                  <TableCell sx={{ padding: 0 }}>
                                    {urlFournisseur.arCodebarre && (
                                      <Tooltip
                                        title={t("word.barCode")}
                                        placement="top"
                                      >
                                        <LinkReact
                                          href={urlFournisseur.arCodebarre}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <IconButton>
                                            <QrCodeScannerIcon />
                                          </IconButton>
                                        </LinkReact>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ padding: 0 }}>
                                    {urlFournisseur.afReffourniss && (
                                      <Tooltip
                                        title={t("word.supplier")}
                                        placement="top"
                                      >
                                        <LinkReact
                                          href={urlFournisseur.afReffourniss}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <IconButton>
                                            <LinkIcon />
                                          </IconButton>
                                        </LinkReact>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                  <TableCell sx={{ padding: 0 }}>
                                    {urlFournisseur.constructeurRef && (
                                      <Tooltip
                                        title={t("word.constructor")}
                                        placement="top"
                                      >
                                        <LinkReact
                                          href={urlFournisseur.constructeurRef}
                                          target="_blank"
                                          rel="noreferrer"
                                        >
                                          <IconButton>
                                            <FactoryIcon />
                                          </IconButton>
                                        </LinkReact>
                                      </Tooltip>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                )}
              </Box>
              {edit !== undefined && !(edit || thisEdit) && (
                <Box>
                  <IconButton onClick={handleThisEdit}>
                    <EditIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </>
      );
    }
  )
);
