import {
  FDocenteteInterface,
  FDocenteteLigneInterface,
  FDocenteteLignePaginateInterface,
  SmallFDocenteteInterface,
  TransformFDocenteteInterface,
} from "../interfaces/FDocenteteInterface";
import {
  doTypeDtos,
  SAGE_DO_TYPE_ACHAT_CC,
  SAGE_DO_TYPE_ACHAT_FA,
  SAGE_DO_TYPE_ACHAT_LI,
  SAGE_DO_TYPE_VENTE_BC,
  SAGE_DO_TYPE_VENTE_DE,
  SAGE_DO_TYPE_VENTE_FC,
  SAGE_DO_TYPE_VENTE_PL,
} from "../utils/DoceneteteUtils";
import {
  FLivraisonInterface,
  UserInterface,
  UserPropInterface,
} from "../interfaces/UserInterface";
import {
  ArrayPostOrderInterface,
  OrderFArticleInterface,
  OrderInterface,
  PostOrderInterface,
} from "../interfaces/OrderInterface";
import {
  getCanChooseOrderType,
  getOrderAllFArticleAvailable,
} from "./CartHelper";
import { ORDER_TYPE_GLOBAL } from "../utils/OrderUtils";
import { AR_REF_LIVRAISON } from "../utils/FArticleUtils";
import { getQuantityDispo } from "./FArticleHelper";
import { EXPEDITION_URL, FDOCENTETES_URL } from "../utils/UrlsUtils";
import { RootState, store } from "../app/store";
import { getPriceExpedition } from "./ExpeditionHelper";
import {
  getThisPays,
  isUserBonDeCommande,
  isUserEnCompte,
  userIsBlocked,
} from "./UserHelper";
import { differenceInDays, isAfter } from "date-fns";
import {
  ArrivageFDocligneInterface,
  ArrivageInterface,
  FDocenteteLigneArrivageInterface,
} from "../interfaces/ArrivageInterface";
import {
  StepActionTransformInterface,
  TransformInterface,
} from "../components/common/entreeStock/EntreeStockArrivageComponent";
import {
  SUIVI_STOCK_TYPE_LOT,
  SUIVI_STOCK_TYPE_SERIE,
} from "../utils/FDoceneteteUtils";
import React from "react";
import notEmptyValidator from "./validator/NotEmptyValidator";
import mailValidator from "./validator/MailValidator";
import { GUADELOUPE, GUYANE, MARTINIQUE } from "../utils/UserUtils";
import i18n from "../languagues/i18n";
import phoneValidator, { formatPhone } from "./validator/PhoneValidator";
import { FDocligneInvoiceInterface } from "../interfaces/EdiInterface";
import { DoTypeInvoiceInterface } from "../components/common/edi/InvoiceComponent";
import { objectToQuery, requestApi } from "./RequestApi";
import { GET } from "../utils/MethodUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "./GetErrorApi";

const clone = require("clone");

export const displayPay = (
  fDocentete: FDocenteteInterface,
  user: UserInterface | undefined
): boolean => {
  return (
    !isUserBonDeCommande(user) &&
    fDocentete.doType !== SAGE_DO_TYPE_VENTE_DE &&
    fDocentete.doType !== SAGE_DO_TYPE_VENTE_FC &&
    getRemainingAmountAPayer(fDocentete) > 0
  );
};

export const displayTransform = (
  fDocentete: FDocenteteInterface,
  user: UserInterface | undefined
): boolean => {
  return (
    !userIsBlocked(user) &&
    fDocentete.doType === SAGE_DO_TYPE_VENTE_DE &&
    Array.isArray(fDocentete.fDoclignesPaginate) &&
    fDocentete.fDoclignesPaginate.find((fDoclignesPaginate) =>
      fDoclignesPaginate.arRef.startsWith("ZZT")
    ) === undefined
  );
};

export const paiementDone = (fDocentete: FDocenteteInterface): boolean => {
  return getRemainingAmountAPayer(fDocentete) <= 0;
};

export const getRemainingAmountAPayer = (
  fDocentete: FDocenteteInterface
): number => {
  let result =
    Math.round(parseFloat(fDocentete.doNetapayer) * 100) -
    Math.round(parseFloat(fDocentete.doMontantregle) * 100);
  const state: RootState = store.getState();
  if (state.globalState.accountingSituation) {
    result -= state.globalState.accountingSituation.creditFdocentetes
      .filter(
        (creditFdocentete) =>
          creditFdocentete.fDocenteteId === fDocentete.doPiece
      )
      .map((creditFdocentete) => creditFdocentete.amount)
      .reduce((a, b) => a + b, 0);
  }
  return result;
};

export const getOrderWithDocentete = (
  fDocentete: FDocenteteInterface,
  user: UserInterface | undefined,
  isDevis: boolean
): OrderInterface => {
  const order: OrderInterface = {
    orderType: !canChangeExpeditionMode(user, isDevis) ? ORDER_TYPE_GLOBAL : "",
    initialExpedition: fDocentete.expedition,
    initialLivraison: fDocentete.fLivraison,
    // @ts-ignore
    orderFArticles: fDocentete.fDoclignes
      .filter(
        (fDocligne: FDocenteteLigneInterface) => fDocligne.fArticle !== null
      )
      .map((fDocligne: FDocenteteLigneInterface) => {
        const fArticle = { ...fDocligne.fArticle };
        const quantity = Number(fDocligne.dlQte ?? 0);
        if (quantity > 0) {
          const priceHt = Math.round(
            (Number(fDocligne.dlMontantht) * 100) / quantity
          );
          fArticle.priceDto.priceHt = priceHt;
          if (fArticle.priceDto.tvaPercent > 0) {
            fArticle.priceDto.priceTtc = Math.round(
              (Number(fDocligne.dlMontantttc) * 100) / quantity
            );
          } else {
            fArticle.priceDto.priceTtc = priceHt;
          }
        }
        return {
          fArticle: fArticle,
          quantity: quantity,
          quantityRef: undefined,
        };
      }),
  };
  order.orderType = !getCanChooseOrderType(order, user, isDevis)
    ? ORDER_TYPE_GLOBAL
    : "";
  return order;
};

export const fDocenteteGetLivraison = (
  fDocentete: FDocenteteInterface | SmallFDocenteteInterface | undefined
): FDocenteteLignePaginateInterface | FDocenteteLigneInterface | null => {
  if (!fDocentete) {
    return null;
  }
  if (fDocentete.fDoclignesPaginate) {
    return (
      fDocentete.fDoclignesPaginate.find((x) =>
        AR_REF_LIVRAISON.includes(x.arRef)
      ) ?? null
    );
  }
  if (fDocentete.fDoclignes) {
    return (
      fDocentete.fDoclignes.find((x) =>
        AR_REF_LIVRAISON.includes(x.fArticle.arRef)
      ) ?? null
    );
  }
  return null;
};

export const canChangeExpeditionMode = (
  user: UserInterface | undefined,
  isDevis: boolean
) => {
  const userBonDeCommande = isUserBonDeCommande(user);
  return !userBonDeCommande || (userBonDeCommande && isDevis);
};

export const hasExpedition = (fDocentete: FDocenteteInterface): boolean => {
  const state: RootState = store.getState();
  if (!state.globalState.expeditions) {
    return false;
  }
  const expeditionFArticles = state.globalState.expeditions
    .filter((x) => x.fArticleId)
    .map((x) => x.fArticleId);
  for (const fDoclignePaginate of fDocentete.fDoclignesPaginate ?? []) {
    if (expeditionFArticles.includes(fDoclignePaginate.arRef)) {
      return true;
    }
  }
  return false;
};

const getDefaultExpedition = () => {
  const state: RootState = store.getState();

  if (state) {
    return (
      state.globalState.expeditions
        ?.find((expedition) => expedition.expeditionGrilles.length === 0)
        ?.id.toString() ?? ""
    );
  }
  return "";
};

export const getArrayPostOrder = (
  initOrder: OrderInterface,
  isDevis: boolean,
  user: UserInterface | undefined
): ArrayPostOrderInterface => {
  const thisOrder = { ...initOrder };
  // thisOrder.orderFArticles = thisOrder.orderFArticles.filter(
  //   (orderFArticle) => orderFArticle.fArticle.faCodefamille !== FAMILLE_SERVICE
  // );
  const allAvailable = getOrderAllFArticleAvailable(thisOrder);
  if (isDevis || thisOrder.orderType === ORDER_TYPE_GLOBAL) {
    const postOrder: PostOrderInterface = {
      doType: isDevis
        ? SAGE_DO_TYPE_VENTE_DE
        : allAvailable
        ? SAGE_DO_TYPE_VENTE_PL
        : SAGE_DO_TYPE_VENTE_BC,
      expedition: "",
      fLivraison: null,
      orderFArticles: thisOrder.orderFArticles,
      avoir: 0,
    };
    if (isDevis || !canChangeExpeditionMode(user, isDevis)) {
      const state: RootState = store.getState();
      postOrder.expedition =
        initOrder.initialExpedition?.toString() ??
        state.globalState.expeditions
          ?.find((x) => {
            return getPriceExpedition(postOrder, x, undefined) === 0;
          })
          ?.id.toString() ??
        "";
      if (initOrder.initialLivraison) {
        postOrder.fLivraison = initOrder.initialLivraison;
      }
    }
    return {
      postOrders: [postOrder],
    };
  } else {
    const orderFArticleRemain: OrderFArticleInterface[] = [
      ...thisOrder.orderFArticles,
    ];
    const orderFArticlePl: OrderFArticleInterface[] = [];
    const orderFArticleBc: OrderFArticleInterface[] = [];
    for (const orderFArticle of orderFArticleRemain) {
      const quantityDispo = getQuantityDispo(
        orderFArticle.fArticle,
        undefined,
        orderFArticle.fDepot
      );
      let quantityPl = 0;
      if (quantityDispo > orderFArticle.quantity) {
        quantityPl = orderFArticle.quantity;
      } else {
        quantityPl = quantityDispo;
      }
      const quantityBc = orderFArticle.quantity - quantityPl;
      if (quantityPl > 0) {
        orderFArticlePl.push({
          ...orderFArticle,
          ...{ quantity: quantityPl },
        });
      }
      if (quantityBc > 0) {
        orderFArticleBc.push({
          ...orderFArticle,
          ...{ quantity: quantityBc },
        });
      }
    }
    const arrayPostOrder: ArrayPostOrderInterface = {
      postOrders: [
        {
          doType: SAGE_DO_TYPE_VENTE_PL,
          expedition: "",
          fLivraison: null,
          orderFArticles: orderFArticlePl,
          avoir: 0,
        },
        {
          doType: SAGE_DO_TYPE_VENTE_BC,
          expedition: "",
          fLivraison: null,
          orderFArticles: orderFArticleBc,
          avoir: 0,
        },
      ],
    };
    arrayPostOrder.postOrders = arrayPostOrder.postOrders.filter(
      (postOrder) => postOrder.orderFArticles.length > 0
    );
    return arrayPostOrder;
  }
};

export const getFormDataForArrayPostOrder = (
  arrayPostOrder: ArrayPostOrderInterface,
  user: UserInterface | undefined,
  isAdmin: boolean | undefined,
  fDocentete?: FDocenteteInterface | undefined,
  isDevis?: boolean
) => {
  const orderApi = new FormData();
  if (user?.userIdentifier) {
    orderApi.append("userIdentifier", user.userIdentifier);
  }
  if (fDocentete?.doPiece) {
    orderApi.append("doPiece", fDocentete.doPiece);
  }
  // we sort here because backend must process "Préparation Livraison" before "Bon de commande"
  arrayPostOrder.postOrders.sort((a, b) => b.doType - a.doType);
  const state: RootState = store.getState();
  const expeditionsArticles =
    state.globalState.expeditions?.map((e) => e.fArticleId) ?? [];
  const obj: any[] = arrayPostOrder.postOrders.map((x) => {
    let expedition = x.expedition;
    if (expedition === "") {
      expedition = getDefaultExpedition();
    }
    return {
      doType: x.doType,
      toPay:
        (isUserEnCompte(user) || x.doType === SAGE_DO_TYPE_VENTE_BC) &&
        arrayPostOrder.toPay !== undefined
          ? arrayPostOrder.toPay
          : 0,
      ...(arrayPostOrder.reference && {
        reference: arrayPostOrder.reference,
      }),
      avoir: x.avoir,
      expedition: EXPEDITION_URL + "/" + expedition,
      ...(x.fLivraison && { fLivraison: Number(x.fLivraison) }),
      orderFArticles: x.orderFArticles
        .filter((o) => {
          return !expeditionsArticles.includes(o.fArticle.arRef) || isAdmin;
        })
        .map((o) => {
          return {
            fArticleId: o.fArticle.arRef,
            quantity: o.quantity,
            fDepot: o.fDepot,
          };
        }),
    };
  });
  orderApi.append("postOrdersString", JSON.stringify(obj));
  if (arrayPostOrder.bonCommande) {
    orderApi.append("bonCommande", arrayPostOrder.bonCommande);
  }
  if (isDevis) {
    orderApi.append("devisString", "true");
  }
  return orderApi;
};

export const getUrlPdfDoPiece = (doPiece: string, asJson: boolean = false) => {
  let result = FDOCENTETES_URL + "/" + doPiece + "/html";
  if (asJson) {
    result += objectToQuery({
      json: true,
    });
  }
  return result;
};

// must be same as query in: transformPlInDe [src/Service/FDocenteteService.php]
export const getNbDayTransformPlInDe = (
  fDocentete: FDocenteteInterface,
  user: UserInterface | undefined,
  userProp: UserPropInterface | undefined,
  userDateTransformDevis: boolean = true
): number | null => {
  const state: RootState = store.getState();
  if (
    !(
      userProp &&
      user &&
      fDocentete.doType === SAGE_DO_TYPE_VENTE_PL &&
      userProp.nbDayDeletePl !== null &&
      (fDocentete.doMontantregle === null ||
        Math.round(parseFloat(fDocentete.doMontantregle) * 100) === 0) &&
      fDocentete.doNetapayer !== null &&
      Math.round(parseFloat(fDocentete.doNetapayer) * 100) > 0 &&
      user.modeDeCommande &&
      (fDocentete.paiement === null || fDocentete.paiement === "") &&
      ["Comptant ss BdC", "Comptant"].includes(user.modeDeCommande) &&
      (state.globalState.accountingSituation?.creditFdocentetes === undefined ||
        state.globalState.accountingSituation.creditFdocentetes.find(
          (x) => x.fDocenteteId === fDocentete.doPiece && !x.done
        ) === undefined)
    )
  ) {
    return null;
  }
  let now = new Date();
  now = new Date(
    now.getFullYear() + "-" + (now.getMonth() + 1) + "-" + now.getDate()
  );
  let dateTransformDevis =
    fDocentete?.dateTransformDevis === null
      ? fDocentete?.dateTransformDevis
      : new Date(fDocentete.dateTransformDevis);
  if (
    dateTransformDevis !== null &&
    isAfter(new Date("2000-01-01"), dateTransformDevis) // if dateTransformDevis avant 2000 alors dateTransformDevis = null
  ) {
    dateTransformDevis = null;
  }
  const diff = differenceInDays(now, new Date(fDocentete.doDate));
  let nbJourDeletePl = userProp.nbDayDeletePl - diff;
  if (userDateTransformDevis) {
    if (dateTransformDevis !== null) {
      nbJourDeletePl = differenceInDays(dateTransformDevis, now);
    }
  }
  return nbJourDeletePl;
};

export const bonDeCommandeIsValidated = (
  fDocentete: FDocenteteInterface,
  user: UserInterface | undefined
): boolean => {
  return (
    isUserBonDeCommande(user) &&
    fDocentete.Validation !== undefined &&
    fDocentete.Validation !== null &&
    fDocentete.Validation > 0.5
  );
};

export const getAvoirFdocentete = (fDocentete: FDocenteteInterface): number => {
  const state: RootState = store.getState();
  if (!state.globalState.accountingSituation?.creditFdocentetes) {
    return 0;
  }
  return state.globalState.accountingSituation.creditFdocentetes
    .filter(
      (creditFdocentete) => creditFdocentete.fDocenteteId === fDocentete.doPiece
    )
    .map((creditFdocentete) => creditFdocentete.amount)
    .reduce((a, b) => a + b, 0);
};

export const getDoPieceWithProp = (
  thisfDoclignes: ArrivageFDocligneInterface[] | FDocligneInvoiceInterface[],
  i: number,
  doTypeDto: DoTypeInvoiceInterface | undefined
) => {
  let doPiece = "";
  if (thisfDoclignes[i].hasOwnProperty("doPiece")) {
    // @ts-ignore
    doPiece = thisfDoclignes[i].doPiece;
  } else {
    // @ts-ignore
    doPiece = thisfDoclignes[i].fDocentete.doPiece;
  }
  if (doTypeDto) {
    let suffix = doTypeDto?.suffix ?? "";
    let prop = "dlPiece" + suffix.charAt(0).toUpperCase() + suffix.slice(1);
    return thisfDoclignes[i].doType === doTypeDto.doType
      ? doPiece
      : // @ts-ignore
      thisfDoclignes[i][prop]
      ? // @ts-ignore
        thisfDoclignes[i][prop]
      : "";
  }
  return "";
};

export const mapSerialNumbers = (
  fDoclignes: ArrivageFDocligneInterface[],
  fDocenteteLigneArrivages: FDocenteteLigneArrivageInterface[]
): ArrivageFDocligneInterface[] => {
  const result: ArrivageFDocligneInterface[] = clone(fDoclignes);
  const tempFDocenteteLigneArrivages: FDocenteteLigneArrivageInterface[] =
    clone(fDocenteteLigneArrivages);
  for (const fDocligne of result) {
    fDocligne.serialNumbers = [];
    let sumSerialNumber = 0;
    if (
      fDocligne.doType !== SAGE_DO_TYPE_ACHAT_CC ||
      (fDocligne.arSuivistock !== SUIVI_STOCK_TYPE_SERIE &&
        fDocligne.arSuivistock !== SUIVI_STOCK_TYPE_LOT)
    ) {
      continue;
    }
    const thisFDocenteteLigneArrivage = tempFDocenteteLigneArrivages.find(
      (t) => t.arRef === fDocligne.arRef
    );
    if (!thisFDocenteteLigneArrivage?.serialNumbers) {
      continue;
    }
    for (const serialNumber of thisFDocenteteLigneArrivage.serialNumbers) {
      if (serialNumber.dlNo !== null || serialNumber.serial.trim() === "") {
        continue;
      }
      let thisSerialNumber = fDocligne.serialNumbers.find(
        (s) => s.serial === serialNumber.serial
      );
      if (!thisSerialNumber) {
        thisSerialNumber = {
          serial: serialNumber.serial,
          quantity: 0,
          dlNo: null,
        };
        fDocligne.serialNumbers?.push(thisSerialNumber);
      }
      while (
        serialNumber.quantity > 0 &&
        sumSerialNumber < (fDocligne.found ?? 0)
      ) {
        sumSerialNumber++;
        thisSerialNumber.quantity = Number(thisSerialNumber.quantity) + 1;
        serialNumber.quantity = Number(serialNumber.quantity) - 1;
      }
    }
    fDocligne.serialNumbers = fDocligne.serialNumbers.filter(
      (s) => s.quantity > 0
    );
  }
  return result;
};

export const getStepsForFDocligne = (
  fDocligne: ArrivageFDocligneInterface,
  doType: number | undefined
): StepActionTransformInterface[] => {
  return [SAGE_DO_TYPE_ACHAT_LI, SAGE_DO_TYPE_ACHAT_FA]
    .filter(
      (thisDoType) =>
        doType && thisDoType <= doType && fDocligne.doType < thisDoType
    )
    .map((thisDoType) => {
      let quantitySerialNumbers: number = 0;
      if (fDocligne.serialNumbers) {
        for (const serialNumber of fDocligne.serialNumbers) {
          if (serialNumber.serial.trim() === "" || serialNumber.dlNo !== null) {
            continue;
          }
          quantitySerialNumbers += Number(serialNumber.quantity);
        }
      }
      return {
        doType: thisDoType,
        arSuivistock: fDocligne.arSuivistock,
        quantity:
          ([SUIVI_STOCK_TYPE_SERIE, SUIVI_STOCK_TYPE_LOT].includes(
            fDocligne.arSuivistock
          )
            ? quantitySerialNumbers
            : fDocligne.found) ?? 0,
        doTiers: fDocligne.doTiers,
        dlNo: fDocligne.dlNo,
        serialNumbers: fDocligne.serialNumbers,
        doPiece: fDocligne.stateFDocligne.transformTo,
        oldDoType: fDocligne.doType,
        oldDoPiece: fDocligne.doPiece,
        ref: React.createRef(),
        started: false,
        success: undefined,
      };
    });
};

export const getTransform = (
  transform: TransformInterface,
  fDoclignes: ArrivageFDocligneInterface[],
  fDocenteteLigneArrivages: FDocenteteLigneArrivageInterface[],
  newDoType: number
) => {
  transform.actions = [];
  fDoclignes = mapSerialNumbers(fDoclignes, fDocenteteLigneArrivages);
  for (const fDocligne of fDoclignes) {
    if (
      transform.actions.find((a) => a.dlNo === fDocligne.dlNo) === undefined
    ) {
      transform.actions.push({
        dlNo: fDocligne.dlNo,
        steps: getStepsForFDocligne(fDocligne, newDoType),
      });
    }
  }
  return transform;
};

export const getStepDoPieceFastTransform = (
  step: StepActionTransformInterface,
  fDoclignes: ArrivageFDocligneInterface[],
  newDoType: number
) => {
  let newDoPiece = "";
  if (newDoType === SAGE_DO_TYPE_ACHAT_LI) {
    const fDocligne = fDoclignes?.find(
      (f) =>
        f.dlPieceBc === step.oldDoPiece &&
        (f.doType === SAGE_DO_TYPE_ACHAT_LI || f.dlPieceBl !== "")
    );
    if (fDocligne) {
      newDoPiece = fDocligne.doPiece;
      if (fDocligne.dlPieceBl !== "") {
        newDoPiece = fDocligne.dlPieceBl;
      }
    }
  } else if (newDoType === SAGE_DO_TYPE_ACHAT_FA) {
    const fDocligne = fDoclignes?.find(
      (f) =>
        f.dlPieceBl === step.oldDoPiece && f.doType === SAGE_DO_TYPE_ACHAT_FA
    );
    if (fDocligne) {
      newDoPiece = fDocligne.doPiece;
    }
  }
  return newDoPiece;
};

const getDocument = (
  thisfDoclignes: ArrivageFDocligneInterface[] | FDocligneInvoiceInterface[],
  indexFDocligne: number,
  doTypeDto: DoTypeInvoiceInterface | undefined,
  direction: "prev" | "next" | null = null
): string => {
  if (!thisfDoclignes) {
    return "";
  }
  if (direction === null) {
    return getDoPieceWithProp(thisfDoclignes, indexFDocligne, doTypeDto);
  }
  let index = indexFDocligne;
  let r = "";
  while (
    (direction === "prev" && index > 0) ||
    (direction === "next" && index < thisfDoclignes.length - 1)
  ) {
    if (direction === "prev") {
      index--;
    } else {
      index++;
    }
    r = getDoPieceWithProp(thisfDoclignes, index, doTypeDto);
    if (r !== "") {
      return r;
    }
  }
  return r;
};

export const addStateFDoclignes = (
  arrivage: ArrivageInterface
): ArrivageInterface | undefined => {
  if (!arrivage.fDoclignes) {
    return undefined;
  }
  if (arrivage.data.fDocenteteLigneArrivages) {
    arrivage.data.fDocenteteLigneArrivages =
      arrivage.data.fDocenteteLigneArrivages.map((f) => {
        return {
          ...f,
          ref: React.createRef(),
        };
      });
  }

  for (
    let indexFDocligne = 0;
    indexFDocligne < arrivage.fDoclignes.length;
    indexFDocligne++
  ) {
    let thisDotype = SAGE_DO_TYPE_ACHAT_CC;
    if (arrivage.fDoclignes[indexFDocligne].doType === SAGE_DO_TYPE_ACHAT_CC) {
      thisDotype = SAGE_DO_TYPE_ACHAT_LI;
    }
    let transformTo =
      arrivage.fDoclignes.find((f) => {
        let previousPiece =
          // @ts-ignore
          f.dlPieceBc === arrivage.fDoclignes[indexFDocligne].doPiece;
        if (thisDotype === SAGE_DO_TYPE_ACHAT_CC) {
          previousPiece =
            // @ts-ignore
            f.dlPieceBl === arrivage.fDoclignes[indexFDocligne].doPiece;
        }
        return (
          f.doType === thisDotype &&
          // @ts-ignore
          f.doTiers === arrivage.fDoclignes[indexFDocligne].doTiers &&
          previousPiece
        );
      })?.doPiece ?? "";
    arrivage.fDoclignes[indexFDocligne].stateFDocligne = {
      transformTo: transformTo,
      currentBonDeCommande: getDocument(
        arrivage.fDoclignes,
        indexFDocligne,
        doTypeDtos.find((x) => x.doType === SAGE_DO_TYPE_ACHAT_CC)
      ),
      currentBonDeLivraison: getDocument(
        arrivage.fDoclignes,
        indexFDocligne,
        doTypeDtos.find((x) => x.doType === SAGE_DO_TYPE_ACHAT_LI)
      ),
      currentFacture: getDocument(
        arrivage.fDoclignes,
        indexFDocligne,
        doTypeDtos.find((x) => x.doType === SAGE_DO_TYPE_ACHAT_FA)
      ),

      nextBonDeCommande: getDocument(
        arrivage.fDoclignes,
        indexFDocligne,
        doTypeDtos.find((x) => x.doType === SAGE_DO_TYPE_ACHAT_CC),
        "next"
      ),
      nextBonDeLivraison: getDocument(
        arrivage.fDoclignes,
        indexFDocligne,
        doTypeDtos.find((x) => x.doType === SAGE_DO_TYPE_ACHAT_LI),
        "next"
      ),
      nextFacture: getDocument(
        arrivage.fDoclignes,
        indexFDocligne,
        doTypeDtos.find((x) => x.doType === SAGE_DO_TYPE_ACHAT_FA),
        "next"
      ),

      previousBonDeCommande: getDocument(
        arrivage.fDoclignes,
        indexFDocligne,
        doTypeDtos.find((x) => x.doType === SAGE_DO_TYPE_ACHAT_CC),
        "prev"
      ),
      previousBonDeLivraison: getDocument(
        arrivage.fDoclignes,
        indexFDocligne,
        doTypeDtos.find((x) => x.doType === SAGE_DO_TYPE_ACHAT_LI),
        "prev"
      ),
      previousFacture: getDocument(
        arrivage.fDoclignes,
        indexFDocligne,
        doTypeDtos.find((x) => x.doType === SAGE_DO_TYPE_ACHAT_FA),
        "prev"
      ),
    };
  }
  return arrivage;
};

export const addDisplayInvoiceFDocligne = (
  invoiceFDoclignes: FDocligneInvoiceInterface[],
  doTypeDtos: DoTypeInvoiceInterface[]
): void => {
  // @ts-ignore
  for (const [index, invoiceFDocligne] of invoiceFDoclignes.entries()) {
    const display: any = {};
    for (const direction of ["prev", "next", null]) {
      for (const doTypeDto of doTypeDtos) {
        display[(direction ?? "") + doTypeDto.doType] = getDocument(
          invoiceFDoclignes,
          index,
          doTypeDto,
          // @ts-ignore
          direction
        );
      }
    }
    invoiceFDocligne.display = display;
  }
};

export const isFLivraisonValid = (fLivraison: FLivraisonInterface): string => {
  const liIntituleError = notEmptyValidator(fLivraison.liIntitule);
  const liContactError = notEmptyValidator(fLivraison.liContact);
  const liAdresseError = notEmptyValidator(fLivraison.liAdresse);
  const liEmailError = mailValidator(fLivraison.liEmail, true);
  const liCodepostalError = notEmptyValidator(fLivraison.liCodepostal);
  const liVilleError = notEmptyValidator(fLivraison.liVille);
  const liPaysError = notEmptyValidator(fLivraison.liPays);
  const ctTelephoneError = phoneValidator(
    formatPhone(fLivraison.liTelephone ?? "")
  );
  const paysMatchZip =
    (fLivraison.liPays === GUADELOUPE &&
      !fLivraison.liCodepostal.startsWith("971")) ||
    (fLivraison.liPays === MARTINIQUE &&
      !fLivraison.liCodepostal.startsWith("972")) ||
    (fLivraison.liPays === GUYANE &&
      !fLivraison.liCodepostal.startsWith("973"));
  if (
    liIntituleError ||
    liContactError ||
    liAdresseError ||
    liEmailError ||
    liCodepostalError ||
    liVilleError ||
    liPaysError ||
    ctTelephoneError ||
    paysMatchZip ||
    !getThisPays().find((x) => x.paIntitule === fLivraison.liPays)
  ) {
    return i18n.t("sentence.deliveryAddressError");
  }
  return "";
};

export const createTransformFDocentetes = (
  transform: TransformInterface
): TransformFDocenteteInterface[] => {
  const result: TransformFDocenteteInterface[] = [];
  for (const action of transform.actions) {
    for (const step of action.steps) {
      let doPiece = step.oldDoPiece;
      let doType = step.oldDoType;
      let transformFDocentete = result.find(
        (f) => f.doPiece === doPiece && f.doType === doType
      );
      if (!transformFDocentete) {
        transformFDocentete = {
          doPiece: doPiece,
          doType: doType,
          transformFDoclignes: [],
        };
        result.push(transformFDocentete);
      }
      transformFDocentete.transformFDoclignes.push({
        doPiece: step.doPiece,
        dlNo: Number(action.dlNo),
        arRef: null,
        quantity: step.quantity,
        doType: step.doType,
        serialNumbers: step.serialNumbers?.filter((s) => !s.dlNo) ?? [],
      });
    }
  }
  return result;
};

export const getNewDoc = (
  fDocligne: FDocligneInvoiceInterface,
  doType: number
) => {
  return (
    fDocligne.display[doType] !== "" &&
    fDocligne.display["prev" + doType] !== fDocligne.display[doType]
  );
};

export const getGlobalNewDoc = (
  fDocligne: FDocligneInvoiceInterface,
  thisDoTypeDtos: DoTypeInvoiceInterface[]
) => {
  return (
    thisDoTypeDtos
      .map((doTypeDto) => getNewDoc(fDocligne, doTypeDto.doType))
      .filter((bool) => bool).length > 0
  );
};

export const downloadFDocentete = async (doPiece: string) => {
  const url = getUrlPdfDoPiece(doPiece, false);
  const state: RootState = store.getState();

  const response = await requestApi({
    method: GET,
    path: url,
    allowError: false,
    getRawResponse: /\/api\/f_docentetes\/.*\/html/.test(url),
    token: state.globalState.token,
  });
  if (response.statusCode === 200) {
    let blob = new Blob([await response.content.blob()], {
      type: "application/pdf",
    });
    const url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    document.body.appendChild(a);
    a.href = url;
    a.download = (doPiece ?? "download") + ".pdf";
    a.click();
    window.URL.revokeObjectURL(url);
    toastr.success(i18n.t("word.success"), i18n.t("word.fileDownloaded"));
  } else if (response.statusCode === 401) {
    toastr.info(i18n.t("word.info"), i18n.t("error.reconnect"));
  } else {
    for (let message of getErrorApi(response.content)) {
      toastr.error(i18n.t("word.error"), i18n.t(message));
    }
  }
};
