import * as React from "react";
import {
  FArticleSmallInterface,
  ValueConfigurationFArticleOptionInterface,
} from "../../../interfaces/FArticleInterface";
import { useTranslation } from "react-i18next";
import { canBuyFArticle } from "../../../helpers/FArticleHelper";
import { LoadingButton } from "@mui/lab";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { CART_FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { PostCartFArticleInterface } from "../../../interfaces/CartInterface";
import { set, setCart } from "../../../app/globalSlice";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  quantity?: number | undefined;
  valueOptions?: ValueConfigurationFArticleOptionInterface[];
  fullWidth?: boolean;
  depot: number;
}

const FArticleAddCardButtonComponent: React.FC<State> = React.memo(
  ({ fArticle, quantity, valueOptions, fullWidth, depot }) => {
    const [loading, setLoading] = React.useState(false);
    const cart = useAppSelector((state: RootState) => state.globalState.cart);
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const dispatch = useAppDispatch();

    const handleAddCart = React.useCallback(
      async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);
        if (fArticle?.fArticleProp === undefined) {
          setLoading(false);
          return;
        }
        let filterValueOptions: ValueConfigurationFArticleOptionInterface[] =
          [];
        if (valueOptions !== undefined) {
          filterValueOptions = valueOptions.filter(
            (valueOption) =>
              valueOption.configurationFArticleValue.fArticle?.arRef !==
              undefined
          );
        }
        let thisQuantity = quantity;
        if (thisQuantity === undefined || thisQuantity === 0) {
          thisQuantity = 1;
        }
        const body: PostCartFArticleInterface = {
          fArticleId: fArticle.arRef,
          cartId: cart?.id ?? null,
          quantity: thisQuantity,
          depot: Number(depot),
          ...(filterValueOptions.length > 0 && {
            cartFArticleOptions: filterValueOptions.map((valueOption) => {
              return {
                fArticleId:
                  valueOption.configurationFArticleValue.fArticle.arRef,
              };
            }),
          }),
        };
        const response = await requestApi({
          method: POST,
          path: CART_FARTICLE_URL,
          allowError: true,
          token: token,
          body: body,
        });
        if (response.statusCode === 201) {
          dispatch(setCart(response.content));
          dispatch(set({ openCart: true }));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      },
      [
        cart?.id,
        depot,
        dispatch,
        fArticle?.arRef,
        fArticle?.fArticleProp,
        quantity,
        t,
        token,
        valueOptions,
      ]
    );

    return (
      <>
        <LoadingButton
          fullWidth={fullWidth}
          sx={{ wordBreak: "break-word" }}
          disabled={
            !canBuyFArticle(fArticle, isAdmin, valueOptions, true, depot)
          }
          variant="contained"
          loading={loading}
          onClick={handleAddCart}
        >
          <ShoppingCartIcon />
        </LoadingButton>
      </>
    );
  }
);

export default FArticleAddCardButtonComponent;
