import * as React from "react";
import { useImperativeHandle, useRef } from "react";
import {
  FArticleInterface,
  FArticleSmallInterface,
} from "../../../interfaces/FArticleInterface";
import Typography from "@mui/material/Typography";
import CircleIcon from "@mui/icons-material/Circle";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { green, grey, red, yellow } from "@mui/material/colors";
import Box from "@mui/material/Box";
import {
  canBuyFArticle,
  canBuyMoreThanDispo,
  getQuantityDispo,
  getQuantityStock,
  labelArrival,
} from "../../../helpers/FArticleHelper";
import { useTheme } from "@mui/material/styles";
import FArticleManualStockComponent from "./FArticleManualStockComponent";
import { defaultFontSize } from "../category/ConfigureViewProductsComponent";
import { DEFAULT_DEPOT } from "../../../utils/FArticleUtils";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  small: boolean;
  hideText?: boolean;
  edit?: boolean;
  setFArticle?: Function;
  asLine?: boolean;
  asLineReverse?: boolean;
  fontSize?: number;
  hideEdit?: boolean;
  createFrom?: FArticleInterface;
  depot?: number;
}

// asQtesto   : stock réel
// asQteres   : réservé (BL)
// asQtecom   : quantité commandé (produits qui arrivent)
// asQteprepa : préparé (PL)
const FArticleStockComponent = React.memo(
  React.forwardRef(
    (
      {
        fArticle,
        small,
        hideText,
        edit,
        setFArticle,
        asLine,
        asLineReverse,
        fontSize,
        hideEdit,
        createFrom,
        depot,
      }: State,
      ref
    ) => {
      const theme = useTheme();
      const refManualStock: any = useRef();
      const [asQteDispo, setAsQteDispo] = React.useState<number>(
        getQuantityDispo(fArticle, undefined, depot)
      );
      const [asQteStock, setAsQteStock] = React.useState<number>(
        getQuantityStock(fArticle)
      );
      const [thisLabelArrival, setThisLabelArrival] = React.useState<string[]>(
        labelArrival(fArticle)
      );
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const cart = useAppSelector((state: RootState) => state.globalState.cart);

      React.useEffect(() => {
        setAsQteDispo(getQuantityDispo(fArticle, undefined, depot));
        setAsQteStock(getQuantityStock(fArticle, depot));
        setThisLabelArrival(labelArrival(fArticle, depot));
      }, [cart, fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

      useImperativeHandle(ref, () => ({
        getValue() {
          return refManualStock.current.getValue();
        },
      }));

      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            ...(!asLine && {
              flexDirection: asLineReverse ? "column-reverse" : "column",
              alignItems: "center",
            }),
          }}
        >
          {!hideText && isAdmin && depot === DEFAULT_DEPOT && (
            <FArticleManualStockComponent
              fArticle={fArticle}
              setFArticle={setFArticle}
              fontSize={fontSize}
              edit={edit}
              ref={refManualStock}
              hideEditIcon={hideEdit === true}
              thisLabelArrival={thisLabelArrival}
              small={small}
              createFrom={createFrom}
            />
          )}
          {!hideText && thisLabelArrival.length > 0 && (
            <Box sx={{ marginBottom: 1 }}>
              {thisLabelArrival.map((label, indexLabel) => (
                <Typography
                  key={indexLabel}
                  sx={{
                    textAlign: asLine ? "right" : "center",
                    paddingInline: 1,
                    wordBreak: "break-word",
                    fontWeight: "bold",
                    color: grey[700],
                    fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                  }}
                >
                  {label}
                </Typography>
              ))}
            </Box>
          )}
          {canBuyFArticle(fArticle, isAdmin) ? (
            <>
              {asQteDispo > 0 ? (
                <>
                  <Typography
                    sx={{
                      fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                    }}
                  >
                    {asQteDispo}
                    {isAdmin && asQteStock > 0 && asQteDispo !== asQteStock && (
                      <Typography
                        component="span"
                        sx={{
                          color: theme.palette.primary.main,
                          fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                        }}
                      >
                        {" (r:" + asQteStock + ")"}
                      </Typography>
                    )}
                  </Typography>
                  <CircleIcon sx={{ width: "30px", color: green[500] }} />
                </>
              ) : canBuyMoreThanDispo(fArticle) ? (
                <>
                  {!hideText && thisLabelArrival.length > 0 && (
                    <Box sx={{ marginBottom: 1 }}>
                      {isAdmin && asQteStock > 0 && (
                        <Typography
                          component="span"
                          sx={{
                            color: theme.palette.primary.main,
                            fontSize:
                              (fontSize ?? defaultFontSize) / 10 + "rem",
                          }}
                        >
                          {" (r:" + asQteStock + ")"}
                        </Typography>
                      )}
                    </Box>
                  )}
                  <CircleIcon
                    sx={{
                      width: "30px",
                      color: yellow[500],
                    }}
                  />
                </>
              ) : (
                <CircleIcon
                  sx={{
                    width: "30px",
                    color:
                      fArticle?.fArticleProp === undefined
                        ? grey[500]
                        : red[500],
                    display: "flex",
                    margin: "auto",
                  }}
                />
              )}
            </>
          ) : (
            <CircleIcon
              sx={{
                width: "30px",
                color:
                  fArticle?.fArticleProp === undefined ? grey[500] : red[500],
                display: "flex",
                margin: "auto",
              }}
            />
          )}
        </Box>
      );
    }
  )
);

export default FArticleStockComponent;
