import * as React from "react";
import { useImperativeHandle } from "react";
import {
  FArticleInterface,
  FArticleSmallInterface,
} from "../../../interfaces/FArticleInterface";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { InputInterface } from "../../../interfaces/InputInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { grey } from "@mui/material/colors";
import { TXT_STOCK_IDENTIFIER } from "../../../utils/FArticleUtils";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { defaultFontSize } from "../category/ConfigureViewProductsComponent";
import Box from "@mui/material/Box";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  small: boolean;
  edit: boolean | undefined;
  setFArticle: Function | undefined;
  thisLabelArrival: string[];
  fontSize?: number;
  hideEditIcon?: boolean;
  createFrom?: FArticleInterface;
}

interface FormState {
  stockManuel: InputInterface;
}

const FArticleManualStockComponent = React.memo(
  React.forwardRef(
    (
      {
        fArticle,
        small,
        edit,
        setFArticle,
        fontSize,
        hideEditIcon,
        thisLabelArrival,
        createFrom,
      }: State,
      ref
    ) => {
      const { t } = useTranslation();
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const getDefaultValues = React.useCallback((): FormState => {
        return {
          stockManuel: {
            value: createFrom
              ? "0"
              : fArticle?.stockManuel
              ? Number(fArticle?.stockManuel).toString()
              : "0",
            error: "",
          },
        };
      }, [createFrom, fArticle?.stockManuel]);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());
      const [thisEdit, setThisEdit] = React.useState(
        edit !== undefined ? edit : false
      );
      const [loading, setLoading] = React.useState(false);

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback(() => {
        return {
          stockManuel: values.stockManuel.value,
        };
      }, [values.stockManuel.value]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        const thisValues = getValue();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            ...thisValues,
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          if (setFArticle) {
            setFArticle(response.content);
          }
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      const handleChangeSelect = React.useCallback(
        (prop: keyof FormState) => (event: SelectChangeEvent) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: {
                ...v[prop],
                value: event.target.value as string,
                error: "",
              },
            };
          });
        },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        if (edit !== undefined) {
          setThisEdit(edit);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [fArticle, thisEdit || edit]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {thisEdit || edit ? (
            <>
              <FormControl fullWidth sx={{ marginTop: 1 }}>
                <InputLabel id="manual-stock-select-label">
                  {t("word.stockManuel.label")}
                </InputLabel>
                <Select
                  labelId="manual-stock-select-label"
                  value={values.stockManuel.value}
                  label={t("word.stockManuel.label")}
                  onChange={handleChangeSelect("stockManuel")}
                >
                  {Object.keys(TXT_STOCK_IDENTIFIER).map((prop: string) => (
                    <MenuItem key={prop} value={prop}>
                      {t(
                        "word.stockManuel." +
                          TXT_STOCK_IDENTIFIER[Number(prop)].toLowerCase()
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {!edit && (
                <Box>
                  <Tooltip title={t("word.cancel")}>
                    <IconButton onClick={handleThisEdit} disabled={loading}>
                      <CancelIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("word.save")}>
                    <LoadingButton
                      variant="text"
                      color="inherit"
                      sx={{
                        borderRadius: "50%",
                        minWidth: "auto",
                        padding: "8px",
                        color: "rgba(0, 0, 0, 0.54)",
                      }}
                      loading={loading}
                      onClick={save}
                    >
                      <SaveIcon />
                    </LoadingButton>
                  </Tooltip>
                </Box>
              )}
            </>
          ) : (
            <>
              {!(
                small &&
                thisLabelArrival.includes(
                  t(
                    "word.stockManuel." +
                      TXT_STOCK_IDENTIFIER[
                        Number(fArticle?.stockManuel)
                      ]?.toLowerCase()
                  )
                )
              ) && (
                <Typography
                  sx={{
                    paddingInline: 1,
                    wordBreak: "break-word",
                    textAlign: "center",
                    color: grey[500],
                    fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                  }}
                >
                  {t(
                    "word.stockManuel." +
                      TXT_STOCK_IDENTIFIER[
                        Number(fArticle?.stockManuel)
                      ]?.toLowerCase()
                  )}
                  {isAdmin && hideEditIcon !== true && (
                    <IconButton onClick={handleThisEdit}>
                      <EditIcon />
                    </IconButton>
                  )}
                </Typography>
              )}
            </>
          )}
        </>
      );
    }
  )
);

export default FArticleManualStockComponent;
