import * as React from "react";
import { useImperativeHandle, useRef } from "react";
import {
  ConfigurationFArticleOptionInterface,
  ConfigurationFArticleValueInterface,
  FArticleInterface,
  FArticleSmallInterface,
  ValueConfigurationFArticleOptionInterface,
} from "../../../interfaces/FArticleInterface";
import {
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  Skeleton,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { FArticleSmallComponent } from "./FArticleSmallComponent";
import FArticleQuantityComponent from "./FArticleQuantityComponent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import FArticleActionButtonComponent from "./FArticleActionButtonComponent";
import Box from "@mui/material/Box";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import {
  DEFAULT_DEPOT,
  SELLING_FILTER_VALUE,
  SELLING_TEXT,
  STYLE_STRETCH_INLINE,
} from "../../../utils/FArticleUtils";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { InputInterface } from "../../../interfaces/InputInterface";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { styled, useTheme } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import SearchIcon from "@mui/icons-material/Search";
import { requestApi } from "../../../helpers/RequestApi";
import { GET, POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import WarningIcon from "@mui/icons-material/Warning";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import ListIcon from "@mui/icons-material/List";
import FilterValueAssoFilterValueFormComponent from "../filter/admin/form/filterForm/FilterValueAssoFilterValueFormComponent";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";
import { priceFormat } from "../../../utils/FormatUtils";
import {
  canBuyFArticle,
  getQuantityDispo,
} from "../../../helpers/FArticleHelper";
import getErrorApi from "../../../helpers/GetErrorApi";
import { CONDITION_AND, CONDITIONS_FILTER } from "../../../utils/FilterUtils";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { swapCard } from "../../../helpers/CardFormat";
import MenuIcon from "@mui/icons-material/Menu";

interface State {
  fArticle: FArticleInterface | undefined;
  options: ConfigurationFArticleOptionInterface[] | undefined;
  setQuantity: Function;
  quantity: number | undefined;
  edit: boolean;
  setFArticle?: Function;
}

interface State2 {
  option: ConfigurationFArticleOptionInterface;
  updateValueOptions: Function;
}

interface State3 {
  options: ConfigurationFArticleOptionInterface[] | undefined;
}

interface State4 {
  option: ConfigurationFArticleOptionInterface | undefined;
  removeOption: Function;
  index: number;
}

interface State5 {
  value: ConfigurationFArticleValueInterface | undefined;
  selectedValue: number | null;
  setSelectedValue: any;
  index: number;
  removeValue: Function;
}

interface FormState {
  name: InputInterface;
  mandatory: InputInterface;
}

interface FormState2 {
  fArticleId: InputInterface;
  type: InputInterface;
  type2: InputInterface;
}

interface FormState3 {
  ref: any;
  option: ConfigurationFArticleOptionInterface | undefined;
  key: number;
}

interface FormState4 {
  ref: any;
  value: ConfigurationFArticleValueInterface | undefined;
}

interface FoundInterface {
  icon: any;
  text: string;
  valid: boolean;
}

const columnLeft = 5;
const columnRight = 7;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const FArticleSingleValueFormComponent = React.memo(
  React.forwardRef(
    (
      { value, selectedValue, setSelectedValue, index, removeValue }: State5,
      ref
    ) => {
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const refFilterValues: any = useRef();
      const { t } = useTranslation();
      const getDefaultValues = React.useCallback((): FormState2 => {
        return {
          fArticleId: {
            value: value?.fArticleId ?? "",
            error: "",
          },
          type2: { value: value?.type2 ?? CONDITION_AND, error: "" },
          type: { value: value?.type ?? SELLING_TEXT, error: "" },
        };
      }, [value?.fArticleId, value?.type, value?.type2]);
      const [values, setValues] = React.useState<FormState2>(
        getDefaultValues()
      );
      const [found, setFound] = React.useState<FoundInterface>({
        icon: <SearchIcon />,
        text: t("word.searching"),
        valid: false,
      });

      const handleChange = React.useCallback(
        (prop: keyof FormState2) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: event.target.value, error: "" },
              };
            });
          },
        []
      );

      const changeType = React.useCallback((newValue: string) => {
        setValues((v) => {
          v.type.value = newValue;
          return { ...v };
        });
      }, []);

      const searchFArticle = React.useCallback(async () => {
        setFound({
          icon: <SearchIcon />,
          text: t("word.searching"),
          valid: false,
        });
        const search = values.fArticleId.value.trim();
        if (search === "") {
          return;
        }
        const response = await requestApi({
          method: GET,
          path: FARTICLE_URL + "/" + search,
          allowError: true,
          token: token,
        });
        if (response.statusCode === 200) {
          if (!response.content.fArticleProp?.indexable) {
            setFound({
              icon: <WarningIcon color="warning" />,
              text: t("word.unindexed"),
              valid: true,
            });
          } else {
            setFound({
              icon: <DoneIcon color="success" />,
              text: t("word.found"),
              valid: true,
            });
          }
        } else {
          setFound({
            icon: <ErrorIcon color="error" />,
            text: t("word.notFound"),
            valid: false,
          });
          if (response.statusCode === 401) {
            toastr.info(t("word.info"), t("error.reconnect"));
          }
        }
      }, [t, token, values.fArticleId.value]);

      const getValue = React.useCallback(() => {
        const fArticleId = values.fArticleId.value.trim();
        const filterValues = refFilterValues.current?.getValue();
        const fArticleIdError = notEmptyValidator(fArticleId);
        if (
          ((fArticleIdError || !found.valid) &&
            values.type.value === SELLING_TEXT) ||
          (!filterValues && values.type.value === SELLING_FILTER_VALUE)
        ) {
          const newValue: FormState2 = { ...values };
          if (values.type.value === SELLING_TEXT) {
            if (!found.valid) {
              newValue.fArticleId.error = t("error.fArticleNotFound");
            }
            if (fArticleIdError) {
              newValue.fArticleId.error = fArticleIdError;
            }
          }
          setValues(newValue);
          return undefined;
        }
        return {
          oldId: value?.id,
          type: values.type.value,
          type2: values.type2.value,
          ...(values.type.value === SELLING_TEXT && {
            fArticleId: fArticleId,
          }),
          ...(values.type.value === SELLING_FILTER_VALUE && {
            filterValues: filterValues,
          }),
          selected: selectedValue === index,
        };
      }, [found.valid, index, selectedValue, t, value?.id, values]);

      const handleChangeSelect = React.useCallback(
        (prop: keyof FormState2) => (event: SelectChangeEvent) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: {
                ...v[prop],
                value: event.target.value as string,
                error: "",
              },
            };
          });
        },
        []
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        const timeoutTyping = setTimeout(() => {
          searchFArticle();
        }, 500);
        return () => clearTimeout(timeoutTyping);
      }, [values.fArticleId.value]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          <Box>
            <Tooltip title={t("field.identifier")} placement="top">
              <IconButton size="small" onClick={() => changeType(SELLING_TEXT)}>
                <TextFieldsIcon sx={{ cursor: "pointer" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("field.filterValue")} placement="top">
              <IconButton
                size="small"
                onClick={() => changeType(SELLING_FILTER_VALUE)}
              >
                <ListIcon sx={{ cursor: "pointer" }} />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ flex: 1 }}>
              {values.type.value === SELLING_TEXT && (
                <TextField
                  fullWidth={true}
                  autoComplete="off"
                  error={!!values.fArticleId.error}
                  helperText={t(values.fArticleId.error ?? "")}
                  sx={{ width: "100%", marginTop: 1 }}
                  required
                  type="text"
                  value={values.fArticleId.value}
                  onChange={handleChange("fArticleId")}
                  label={t("field.fArticleId")}
                  placeholder={t("field.fArticleId")}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip title={found.text}>{found.icon}</Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              {values.type.value === SELLING_FILTER_VALUE && (
                <Grid container spacing={1}>
                  <Grid item xs={12} md={10}>
                    <FilterValueAssoFilterValueFormComponent
                      ref={refFilterValues}
                      filterValueAssoFilters={value?.filterValues ?? []}
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <FormControl fullWidth required>
                      <InputLabel id={"condition-filter-values"}>
                        {t("field.condition")}
                      </InputLabel>
                      <Select
                        labelId={"condition-filter-values"}
                        value={values.type2.value}
                        label={t("field.condition")}
                        onChange={handleChangeSelect("type2")}
                      >
                        {CONDITIONS_FILTER.map((condition, indexType) => (
                          <MenuItem value={condition} key={indexType}>
                            {t("word.filter.condition." + condition)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              <FormGroup>
                <FormControlLabel
                  disabled={values.type.value !== SELLING_TEXT}
                  control={
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<RadioButtonCheckedIcon />}
                      checked={
                        values.type.value !== SELLING_TEXT
                          ? false
                          : selectedValue === index
                      }
                      onChange={(e) =>
                        setSelectedValue(e.target.checked ? index : null)
                      }
                    />
                  }
                  label={t("word.selected")}
                />
              </FormGroup>
            </Box>
            <Box>
              <IconButton onClick={() => removeValue(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
          <Divider />
        </>
      );
    }
  )
);

const FArticleSingleOptionComponent = React.memo(
  React.forwardRef(({ option, updateValueOptions }: State2, ref) => {
    const theme = useTheme();
    const cart = useAppSelector((state: RootState) => state.globalState.cart);
    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const getSelectedFArticle = React.useCallback(():
      | FArticleSmallInterface
      | undefined => {
      for (const configurationFArticleValue of option.configurationFArticleValues) {
        if (configurationFArticleValue.selected) {
          return configurationFArticleValue.fArticles[0];
        }
      }
      if (option.mandatory) {
        for (const configurationFArticleValue of option.configurationFArticleValues) {
          if (configurationFArticleValue.fArticles.length > 0) {
            return configurationFArticleValue.fArticles[0];
          }
        }
      }
      return undefined;
    }, [option.configurationFArticleValues, option.mandatory]);
    const [fArticle, setFArticle] = React.useState<
      FArticleSmallInterface | undefined
    >(getSelectedFArticle());
    const getFArticleOptions =
      React.useCallback((): FArticleSmallInterface[] => {
        let result: FArticleSmallInterface[] = [];
        for (const configurationFArticleValue of option.configurationFArticleValues) {
          for (const thisFArticle of configurationFArticleValue.fArticles) {
            result.push(thisFArticle);
          }
        }
        result = result.filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.arRef === value.arRef)
        );
        result.sort((a, b) => a.priceDto.priceTtc - b.priceDto.priceTtc);
        return result;
      }, [option.configurationFArticleValues]);
    const [fArticleOptions, setFArticleOptions] = React.useState<
      FArticleSmallInterface[]
    >(getFArticleOptions());
    const [optionName] = React.useState(
      option.name + (option.mandatory ? "*" : "")
    );
    const { t, i18n } = useTranslation();

    const getError = React.useCallback(() => {
      return "";
    }, []);
    const [error, setError] = React.useState(getError());

    const handleChange = React.useCallback(
      (event: SelectChangeEvent) => {
        setFArticle(
          fArticleOptions.find(
            (fArticleOption) => fArticleOption.arRef === event.target.value
          )
        );
      },
      [fArticleOptions]
    );

    React.useEffect(() => {
      setError(getError());
    }, [cart]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      updateValueOptions();
    }, [fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setFArticleOptions(getFArticleOptions());
      setFArticle(getSelectedFArticle());
    }, [option]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {}, [fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => ({
      getValue() {
        return {
          fArticle: fArticle,
        };
      },
    }));

    return (
      <>
        <Grid
          item
          xs={12}
          md={columnLeft}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <FormControl fullWidth error={error !== ""}>
            <InputLabel id={"option-label-" + option.id}>
              {optionName}
            </InputLabel>
            <Select
              labelId={"option-label-" + option.id}
              value={fArticle?.arRef ?? ""}
              label={optionName}
              onChange={handleChange}
            >
              <MenuItem disabled={option.mandatory} value="">
                <em>{t("word.none")}</em>
              </MenuItem>
              {fArticleOptions.map((thisFArticle, indexThisFArticle) => (
                <MenuItem
                  key={indexThisFArticle}
                  value={thisFArticle.arRef}
                  disabled={!canBuyFArticle(thisFArticle, isAdmin)}
                >
                  {!canBuyFArticle(thisFArticle, isAdmin)
                    ? "🔴"
                    : getQuantityDispo(thisFArticle) > 0
                    ? "🟢"
                    : "🟡"}
                  {thisFArticle.fArticleProp?.driver?.name && (
                    <Typography
                      component="span"
                      sx={{ color: theme.palette.primary.main }}
                    >
                      {"[" + thisFArticle.fArticleProp?.driver.name + "]"}
                    </Typography>
                  )}
                  <Typography component="span">
                    {thisFArticle.arDesign}
                  </Typography>
                  <Typography component="span" sx={{ fontWeight: "bold" }}>
                    {"(" +
                      priceFormat(
                        thisFArticle.priceDto?.priceTtc,
                        i18n.language,
                        "EUR"
                      ) +
                      ")"}
                  </Typography>
                  <Typography
                    component="span"
                    sx={{ color: theme.palette.primary.main }}
                  >
                    {"[" + thisFArticle.arRef + "]"}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
            {error !== "" && <FormHelperText>{error}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={columnRight}>
          {fArticle !== undefined && (
            <FArticleSmallComponent
              fArticles={[fArticle]}
              nbColumn={1}
              inline={true}
              defaultStyleInline={STYLE_STRETCH_INLINE}
              showAddCart={false}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ marginY: 2 }} />
        </Grid>
      </>
    );
  })
);

const FArticleSingleOptionFormComponent = React.memo(
  React.forwardRef(({ option, index, removeOption }: State4, ref) => {
    const { t } = useTranslation();
    const getDefaultValues = React.useCallback((): FormState => {
      return {
        name: {
          value: option?.name ?? "",
          error: "",
        },
        mandatory: {
          value: option?.mandatory ?? false,
          error: "",
        },
      };
    }, [option?.mandatory, option?.name]);
    const [values, setValues] = React.useState<FormState>(getDefaultValues());
    const [selectedValue, setSelectedValue] = React.useState<number | null>(
      () => {
        const index = option?.configurationFArticleValues.findIndex(
          (v) => v.selected
        );
        if (index === -1) {
          return null;
        }
        return index ?? null;
      }
    );

    const getThisValues = React.useCallback((): (FormState4 | undefined)[] => {
      return (
        option?.configurationFArticleValues?.map(
          (configurationFArticleValue) => {
            return {
              ref: React.createRef(),
              value: configurationFArticleValue,
            };
          }
        ) ?? []
      );
    }, [option?.configurationFArticleValues]);

    const [thisValues, setThisValues] = React.useState<
      (FormState4 | undefined)[]
    >(getThisValues());

    const addValue = React.useCallback((): void => {
      setThisValues((x) => {
        x.push({
          ref: React.createRef(),
          value: undefined,
        });
        return [...x];
      });
    }, []);

    const removeValue = React.useCallback((index: number) => {
      setThisValues((x) => {
        x[index] = undefined;
        return [...x];
      });
    }, []);

    const handleChange = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.value, error: "" },
            };
          });
        },
      []
    );

    const handleChangeCheckbox = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.checked, error: "" },
            };
          });
        },
      []
    );

    const getValue = React.useCallback(() => {
      const configurationFArticleValues = thisValues
        .filter((thisValue) => thisValue)
        .map((thisValue) => {
          return thisValue?.ref.current.getValue();
        });
      const thisName = values.name.value.trim();
      const nameError = notEmptyValidator(thisName);
      if (
        configurationFArticleValues.findIndex((r) => r === undefined) >= 0 ||
        nameError
      ) {
        const newValue: FormState = { ...values };
        if (nameError) {
          newValue.name.error = nameError;
        }
        setValues(newValue);
        return undefined;
      }
      return {
        oldId: option?.id,
        name: thisName,
        mandatory: values.mandatory.value,
        configurationFArticleValues: configurationFArticleValues,
      };
    }, [option?.id, thisValues, values]);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setValues(getDefaultValues());
    }, [option]); // eslint-disable-line react-hooks/exhaustive-deps
    return (
      <>
        <StyledTableCell>
          <label
            htmlFor={"label-fArticle-option-name"}
            style={{ display: "none" }}
          >
            {t("word.label")}
          </label>
          <TextField
            fullWidth={true}
            autoComplete="label-fArticle-option-name"
            name={"label-fArticle-option-name"}
            error={!!values.name.error}
            helperText={t(values.name.error ?? "")}
            sx={{ width: "100%", marginTop: 1 }}
            required
            type="text"
            value={values.name.value}
            onChange={handleChange("name")}
            label={t("field.name")}
            placeholder={t("field.name")}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.mandatory.value}
                  onChange={handleChangeCheckbox("mandatory")}
                />
              }
              label={t("word.mandatory")}
            />
          </FormGroup>
        </StyledTableCell>
        <StyledTableCell>
          {thisValues.map((thisValue, indexThisValue) => (
            <React.Fragment key={indexThisValue}>
              {thisValue && (
                <FArticleSingleValueFormComponent
                  ref={thisValue.ref}
                  value={thisValue.value}
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  index={indexThisValue}
                  removeValue={removeValue}
                />
              )}
            </React.Fragment>
          ))}
          <Box sx={{ textAlign: "center" }}>
            <LoadingButton variant="contained" onClick={addValue}>
              {t("action.add.value")}
            </LoadingButton>
          </Box>
        </StyledTableCell>
        <StyledTableCell sx={{ textAlign: "right", paddingLeft: 0 }}>
          <IconButton onClick={() => removeOption(index)}>
            <DeleteIcon />
          </IconButton>
        </StyledTableCell>
      </>
    );
  })
);

const FArticleSingleOptionsFormComponent = React.memo(
  React.forwardRef(({ options }: State3, ref) => {
    const { t } = useTranslation();
    const getThisOptions = React.useCallback((): (FormState3 | undefined)[] => {
      return (
        options?.map((option, indexOption) => {
          return {
            ref: React.createRef(),
            option: option,
            key: indexOption,
          };
        }) ?? []
      );
    }, [options]);

    const [thisOptions, setThisOptions] = React.useState<
      (FormState3 | undefined)[]
    >(getThisOptions());

    const addOption = React.useCallback((): void => {
      setThisOptions((x) => {
        x.push({
          ref: React.createRef(),
          option: undefined,
          key: Math.max(...[0, ...x.map((y) => y?.key ?? 0)]) + 1,
        });
        return [...x];
      });
    }, []);

    const removeOption = React.useCallback((index: number) => {
      setThisOptions((x) => {
        x[index] = undefined;
        return [...x];
      });
    }, []);

    const getValue = React.useCallback(() => {
      const result = thisOptions
        .filter((thisOption) => thisOption)
        .map((thisOption, indexThisOption) => {
          return {
            ...thisOption?.ref.current.getValue(),
            sort: indexThisOption + 1,
          };
        });
      if (result.findIndex((r) => r === undefined) >= 0) {
        return undefined;
      }
      return result;
    }, [thisOptions]);

    const onDragEnd = React.useCallback((result: DropResult) => {
      if (
        result.destination === null ||
        result.destination === undefined ||
        result.source === null ||
        result.source === undefined
      ) {
        return;
      }
      setThisOptions((thisOptions) => {
        thisOptions = swapCard(thisOptions, result);
        return thisOptions;
      });
    }, []);

    useImperativeHandle(ref, () => ({
      getValue() {
        return getValue();
      },
    }));

    React.useEffect(() => {
      setThisOptions(getThisOptions());
    }, [options]); // eslint-disable-line react-hooks/exhaustive-deps
    return (
      <>
        <form
          name="fArticle-options-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="row">
              {(provided) => (
                <div ref={provided.innerRef}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <StyledTableRow>
                          <StyledTableCell></StyledTableCell>
                          <StyledTableCell>{t("word.option")}</StyledTableCell>
                          <StyledTableCell>{t("word.values")}</StyledTableCell>
                          <StyledTableCell />
                        </StyledTableRow>
                      </TableHead>
                      <TableBody>
                        {thisOptions.map(
                          (thisOption, indexThisOption) =>
                            thisOption && (
                              <Draggable
                                key={thisOption.key}
                                draggableId={thisOption.key.toString()}
                                index={indexThisOption}
                              >
                                {(provided) => (
                                  <StyledTableRow
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                  >
                                    <StyledTableCell>
                                      <span
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          cursor: "move",
                                          marginRight: 1,
                                        }}
                                        {...provided.dragHandleProps}
                                      >
                                        <MenuIcon />
                                      </span>
                                    </StyledTableCell>
                                    <FArticleSingleOptionFormComponent
                                      ref={thisOption.ref}
                                      option={thisOption.option}
                                      index={indexThisOption}
                                      removeOption={removeOption}
                                    />
                                  </StyledTableRow>
                                )}
                              </Draggable>
                            )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <button type="submit" style={{ display: "none" }}>
            Submit
          </button>
        </form>
        <Box sx={{ textAlign: "center", marginTop: 1 }}>
          <LoadingButton variant="contained" onClick={addOption}>
            {t("action.add.option")}
          </LoadingButton>
        </Box>
      </>
    );
  })
);

const FArticleOptionComponent = React.memo(
  React.forwardRef(
    (
      { options, fArticle, setQuantity, quantity, edit, setFArticle }: State,
      ref
    ) => {
      const getOptionsRef = React.useCallback(() => {
        return (
          options?.map((option) => {
            return { option: option, ref: React.createRef() };
          }) ?? []
        );
      }, [options]);
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const [optionsRef, setOptionsRef] = React.useState(getOptionsRef());
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const { t } = useTranslation();
      const formRef: any = useRef();
      const [loading, setLoading] = React.useState(false);
      const [thisEdit, setThisEdit] = React.useState<boolean>(
        edit !== undefined ? edit : false
      );
      const getValueOptions = React.useCallback(():
        | ValueConfigurationFArticleOptionInterface[]
        | undefined => {
        if (optionsRef === undefined) {
          return undefined;
        }
        const newValueOptions: ValueConfigurationFArticleOptionInterface[] = [];
        for (const optionRef of optionsRef) {
          newValueOptions.push({
            id: optionRef.option.id,
            mandatory: optionRef.option.mandatory,
            configurationFArticleValue:
              // @ts-ignore
              optionRef.ref.current?.getValue() ?? undefined,
          });
        }
        return newValueOptions;
      }, [optionsRef]);
      const [valueOptions, setValueOptions] = React.useState(getValueOptions());

      const updateValueOptions = React.useCallback(() => {
        setValueOptions(getValueOptions());
      }, [getValueOptions]);

      const handleThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);

      const getValue = React.useCallback(() => {
        const formValue = formRef.current.getValue();
        // https://stackoverflow.com/a/76333584/6824121
        document
          .querySelector(
            `form[name='fArticle-options-form'] button[type='submit']`
          )
          // @ts-ignore
          ?.click();
        if (!formValue) {
          return undefined;
        }
        return {
          configurationFArticleOptions: formValue,
        };
      }, []);

      const save = React.useCallback(async () => {
        setLoading(true);
        const body = getValue();
        if (!body) {
          setLoading(false);
          return;
        }
        const fArticleApi = new FormData();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            fArticleProp: body,
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          if (setFArticle) {
            setFArticle(response.content);
          }
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        setOptionsRef(getOptionsRef());
      }, [options]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setThisEdit(edit !== undefined ? edit : false);
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {fArticle?.fArticleProp?.hasOption && options === undefined ? (
            <Skeleton
              variant="rectangular"
              height={400}
              width="100%"
              sx={{ marginX: 1 }}
            />
          ) : (
            <Grid container spacing={1}>
              {(options !== undefined || isAdmin) && (
                <Grid item xs={12}>
                  <Typography sx={{ textAlign: "center" }}>
                    {t("sentence.selectValueOptions")}
                    {isAdmin && !(edit || thisEdit) && (
                      <IconButton onClick={handleThisEdit}>
                        <EditIcon />
                      </IconButton>
                    )}
                  </Typography>
                </Grid>
              )}
              {!(edit || thisEdit) ? (
                <>
                  {optionsRef?.map((optionRef, indexOption) => (
                    <FArticleSingleOptionComponent
                      key={indexOption}
                      option={optionRef.option}
                      ref={optionRef.ref}
                      updateValueOptions={updateValueOptions}
                    />
                  ))}
                  {fArticle?.fArticleProp?.hasOption && (
                    <>
                      <Grid item xs={12} md={columnLeft} />
                      <Grid item xs={12} md={columnRight}>
                        <FArticleQuantityComponent
                          fArticle={fArticle}
                          setQuantityParent={setQuantity}
                          valueOptions={valueOptions}
                        />
                        <Box sx={{ marginTop: 2 }}>
                          <FArticleActionButtonComponent
                            valueOptions={valueOptions}
                            quantity={quantity}
                            fArticle={fArticle}
                            fullWidth
                            depot={DEFAULT_DEPOT}
                          />
                        </Box>
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <FArticleSingleOptionsFormComponent
                      options={options}
                      ref={formRef}
                    />
                  </Grid>
                  {!edit && (
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Tooltip title={t("word.cancel")} placement="left">
                        <IconButton onClick={handleThisEdit} disabled={loading}>
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("word.save")} placement="right">
                        <LoadingButton
                          variant="text"
                          color="inherit"
                          sx={{
                            borderRadius: "50%",
                            minWidth: "auto",
                            padding: "8px",
                            color: "rgba(0, 0, 0, 0.54)",
                          }}
                          loading={loading}
                          onClick={save}
                        >
                          <SaveIcon />
                        </LoadingButton>
                      </Tooltip>
                    </Grid>
                  )}
                </>
              )}
            </Grid>
          )}
        </>
      );
    }
  )
);

export default FArticleOptionComponent;
