import * as React from "react";
import { useImperativeHandle } from "react";
import {
  FArticleSmallInterface,
  ValueConfigurationFArticleOptionInterface,
} from "../../../interfaces/FArticleInterface";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import {
  canBuyFArticle,
  getMaxQuantityCanBuy,
} from "../../../helpers/FArticleHelper";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useTranslation } from "react-i18next";
import { InputInterface } from "../../../interfaces/InputInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  setQuantityParent: Function;
  valueOptions?: ValueConfigurationFArticleOptionInterface[];
  index?: number;
  defaultValue?: number;
  maxWithCart?: boolean;
  maxEqualMinWidth?: boolean;
  depot?: number;
  showMaxQuantityMessage?: boolean;
}

interface FormState {
  quantity: InputInterface;
}

const FArticleQuantityComponent = React.memo(
  React.forwardRef(
    (
      {
        fArticle,
        setQuantityParent,
        valueOptions,
        index,
        defaultValue,
        maxWithCart,
        maxEqualMinWidth,
        depot,
        showMaxQuantityMessage,
      }: State,
      ref
    ) => {
      const theme = useTheme();
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const [maxQuantityCanBuy, setMaxQuantityCanBuy] = React.useState<
        number | undefined
      >(
        getMaxQuantityCanBuy(
          fArticle,
          isAdmin,
          valueOptions,
          maxWithCart,
          depot
        )
      );
      const cart = useAppSelector((state: RootState) => state.globalState.cart);
      const getDefaultQuantity = React.useCallback(() => {
        return maxQuantityCanBuy === 0
          ? "0"
          : defaultValue
          ? defaultValue.toString()
          : "1";
      }, [defaultValue, maxQuantityCanBuy]);
      const [values, setValues] = React.useState<FormState>({
        quantity: {
          value: getDefaultQuantity(),
          error: "",
        },
      });
      const isSmall = useMediaQuery(theme.breakpoints.down("md"));
      const { t } = useTranslation();

      const handleChange = React.useCallback(
        (prop: keyof FormState) =>
          (event: React.ChangeEvent<HTMLInputElement>) => {
            let value = event.target.value;
            if (prop === "quantity") {
              if (
                maxQuantityCanBuy !== undefined &&
                Number(value) > maxQuantityCanBuy
              ) {
                value = maxQuantityCanBuy.toString();
              }
              if (Number(value) < 0) {
                if (maxQuantityCanBuy === 0) {
                  value = "0";
                } else {
                  value = "1";
                }
              }
            }
            setValues((v) => {
              return {
                ...v,
                [prop]: { ...v[prop], value: value, error: "" },
              };
            });
          },
        [maxQuantityCanBuy]
      );

      const onBlur = React.useCallback(() => {
        if (
          values.quantity.value === "" ||
          isNaN(Number(values.quantity.value))
        ) {
          setValues((v) => {
            return {
              ...v,
              quantity: {
                ...v.quantity,
                value: "1",
                error: "",
              },
            };
          });
        } else if (!Number.isInteger(Number(values.quantity.value))) {
          setValues((v) => {
            return {
              ...v,
              quantity: {
                ...v.quantity,
                value: Math.round(Number(values.quantity.value)).toString(),
                error: "",
              },
            };
          });
        }
      }, [values.quantity.value]);

      const addQuantity = React.useCallback(() => {
        setValues((v) => {
          let value = Number(v.quantity.value) + 1;
          if (
            maxQuantityCanBuy !== undefined &&
            Number(value) > maxQuantityCanBuy
          ) {
            value = maxQuantityCanBuy;
          }
          return {
            ...v,
            quantity: {
              ...v.quantity,
              value: value.toString(),
              error: "",
            },
          };
        });
      }, [maxQuantityCanBuy]);

      const removeQuantity = React.useCallback(() => {
        setValues((v) => {
          let newQuantity = Number(v.quantity.value) - 1;
          if (newQuantity < 1) {
            if (maxQuantityCanBuy === 0) {
              newQuantity = 0;
            } else {
              newQuantity = 1;
            }
          }
          return {
            ...v,
            quantity: {
              ...v.quantity,
              value: newQuantity.toString(),
              error: "",
            },
          };
        });
      }, [maxQuantityCanBuy]);

      const updateQuantityToMax = React.useCallback(() => {
        const newMax = getMaxQuantityCanBuy(
          fArticle,
          isAdmin,
          valueOptions,
          maxWithCart,
          depot
        );
        if (
          (newMax === undefined || newMax > 0) &&
          values.quantity.value === "0"
        ) {
          values.quantity.value = defaultValue ? defaultValue.toString() : "1";
        }
        setMaxQuantityCanBuy(newMax);
      }, [
        defaultValue,
        depot,
        fArticle,
        isAdmin,
        maxWithCart,
        valueOptions,
        values.quantity,
      ]);

      const updateQuantityValueWithMax = React.useCallback(() => {
        if (maxQuantityCanBuy === undefined) {
          return;
        }
        if (Number(values.quantity.value) > maxQuantityCanBuy) {
          setValues((x) => {
            return {
              ...x,
              quantity: {
                ...x.quantity,
                value: maxQuantityCanBuy.toString(),
                error: "",
              },
            };
          });
        }
      }, [maxQuantityCanBuy, values.quantity.value]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return Number(values.quantity.value);
        },
      }));

      React.useEffect(() => {
        updateQuantityValueWithMax();
      }, [maxQuantityCanBuy]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues({
          quantity: {
            value: getDefaultQuantity(),
            error: "",
          },
        });
      }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (
          maxQuantityCanBuy !== undefined &&
          Number(values.quantity.value) > maxQuantityCanBuy
        ) {
          setValues((v) => {
            v.quantity.value = maxQuantityCanBuy;
            return { ...v };
          });
          return;
        }
        if (setQuantityParent) {
          if (index !== undefined) {
            setQuantityParent(Number(values.quantity.value), index);
          } else {
            setQuantityParent(Number(values.quantity.value));
          }
        }
      }, [values.quantity.value]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        updateQuantityToMax();
      }, [valueOptions, cart, fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

      let nbChar = values.quantity.value.toString().length;
      if (nbChar < 2) {
        nbChar = 2;
      }
      return (
        <Box sx={{ textAlign: "center" }}>
          <FormControl variant="outlined" required>
            <InputLabel>{t("field.quantity")}</InputLabel>
            <OutlinedInput
              error={!!values.quantity.error}
              type="number"
              className="center-input"
              value={values.quantity.value}
              disabled={
                !canBuyFArticle(
                  fArticle,
                  isAdmin,
                  undefined,
                  maxWithCart,
                  depot
                )
              }
              onBlur={onBlur}
              onChange={handleChange("quantity")}
              inputProps={{
                sx: {
                  minWidth: nbChar * 9,
                  ...(!isSmall && { paddingY: 1 }),
                  ...(!isSmall && maxEqualMinWidth && { maxWidth: nbChar * 9 }),
                },
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={
                      !canBuyFArticle(
                        fArticle,
                        isAdmin,
                        undefined,
                        maxWithCart,
                        depot
                      )
                    }
                    aria-label={t("field.action.add_quantity")}
                    onClick={addQuantity}
                    edge="end"
                  >
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              }
              startAdornment={
                <InputAdornment position="start">
                  <IconButton
                    disabled={
                      !canBuyFArticle(
                        fArticle,
                        isAdmin,
                        undefined,
                        maxWithCart,
                        depot
                      )
                    }
                    aria-label={t("field.action.remove_quantity")}
                    onClick={removeQuantity}
                    edge="end"
                  >
                    <RemoveIcon />
                  </IconButton>
                </InputAdornment>
              }
              label={t("field.quantity")}
            />
            {!!values.quantity.error && (
              <FormHelperText error>
                {t(values.quantity.error ?? "")}
              </FormHelperText>
            )}
          </FormControl>
          {showMaxQuantityMessage !== false &&
            maxQuantityCanBuy !== undefined &&
            maxQuantityCanBuy > 0 && (
              <FormHelperText sx={{ marginBottom: 1, textAlign: "center" }}>
                {t("sentence.farticle.maxQuantityCanBuy").replace(
                  "%quantity%",
                  maxQuantityCanBuy.toString()
                )}
              </FormHelperText>
            )}
        </Box>
      );
    }
  )
);

export default FArticleQuantityComponent;
