import * as React from "react";
import { SyntheticEvent, useImperativeHandle } from "react";
import { FArticleSmallInterface } from "../../../interfaces/FArticleInterface";
import { Autocomplete, Chip, TextField, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { InputInterface } from "../../../interfaces/InputInterface";
import Typography from "@mui/material/Typography";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { FARTICLE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { defaultFontSize } from "../category/ConfigureViewProductsComponent";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  edit?: boolean;
  tooltip?: boolean;
  sx?: SxProps<Theme>;
  setFArticle?: any;
  hideKeywordLabel?: boolean;
  fontSize?: number;
}

interface FormState {
  keywordsIcecat: InputInterface;
}

const FArticleKeywordIcecatComponent = React.memo(
  React.forwardRef(
    (
      {
        fArticle,
        edit,
        tooltip,
        sx,
        setFArticle,
        hideKeywordLabel,
        fontSize,
      }: State,
      ref
    ) => {
      const { t } = useTranslation();
      const [valueInputValue, setValueInputValue] = React.useState("");
      const [thisEdit, setThisEdit] = React.useState(false);
      const [loading, setLoading] = React.useState(false);
      const changeThisEdit = React.useCallback(() => {
        setThisEdit((x) => !x);
      }, []);
      const [openCopied, setOpenCopied] = React.useState<boolean>(false);
      const handleValueInputValueChange = React.useCallback(
        (event: SyntheticEvent<Element, Event>, value: string) => {
          try {
            const json = JSON.parse(value);
            if (typeof json[Symbol.iterator] === "function") {
              setValues((v) => {
                v.keywordsIcecat.value = [...v.keywordsIcecat.value, ...json];
                v.keywordsIcecat.error = "";
                return { ...v };
              });
            } else {
              setValueInputValue(value);
            }
          } catch (e) {
            setValueInputValue(value);
          }
        },
        []
      );
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );
      const getDefaultValues = React.useCallback((): FormState => {
        let keywordsIcecat = fArticle?.fArticleProp?.keywordsIcecat;
        if (keywordsIcecat) {
          keywordsIcecat = [...keywordsIcecat];
        }
        return {
          keywordsIcecat: {
            value: keywordsIcecat ?? [],
            error: "",
          },
        };
      }, [fArticle?.fArticleProp?.keywordsIcecat]);
      const [values, setValues] = React.useState<FormState>(getDefaultValues());

      const handleChangeAutocomplete = React.useCallback(
        (prop: keyof FormState) =>
          (event: SyntheticEvent<Element, Event>, value: any) => {
            setValues((v) => {
              return {
                ...v,
                // @ts-ignore
                [prop]: { ...v[prop], value: value, error: "" },
              };
            });
          },
        []
      );

      const getValue = React.useCallback(() => {
        return {
          keywordsIcecat: values.keywordsIcecat.value
            .map((x: string) => x.trim())
            .filter((x: string) => x !== ""),
        };
      }, [values.keywordsIcecat.value]);

      const save = React.useCallback(async () => {
        setLoading(true);
        const fArticleApi = new FormData();
        fArticleApi.append(
          "json",
          JSON.stringify({
            arRef: fArticle?.arRef,
            fArticleProp: getValue(),
          })
        );
        const response = await requestApi({
          method: POST,
          path: FARTICLE_URL,
          allowError: true,
          timeout: 30_000,
          token: token,
          body: fArticleApi,
          formData: true,
        });
        if (response.statusCode === 201) {
          toastr.success(
            t("word.success"),
            t("sentence.notification.farticle_updated")
          );
          if (setFArticle) {
            setFArticle(response.content);
          }
          setThisEdit(false);
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      }, [fArticle?.arRef, getValue, setFArticle, t, token]);

      const addValueInputValue = React.useCallback(() => {
        const newValue = valueInputValue.trim().split(/,|;/);
        setValues((v) => {
          v.keywordsIcecat.value.push(...newValue);
          v.keywordsIcecat.value = v.keywordsIcecat.value
            .map((x: string) => x.trim())
            .filter((x: string) => x);
          v.keywordsIcecat.error = "";
          return { ...v };
        });
        setValueInputValue("");
      }, [valueInputValue]);

      const copy = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          e.preventDefault();
          navigator.clipboard.writeText(
            JSON.stringify(fArticle?.fArticleProp?.keywordsIcecat ?? [])
          );
          setOpenCopied(true);
          setTimeout(() => {
            setOpenCopied(false);
          }, 500);
        },
        [fArticle?.fArticleProp?.keywordsIcecat]
      );

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        setValues(getDefaultValues());
      }, [fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (!edit) {
          setThisEdit(false);
        }
      }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <Box sx={{ paddingBottom: 2 }}>
          {(isAdmin ||
            (fArticle?.fArticleProp?.keywordsIcecat &&
              fArticle?.fArticleProp?.keywordsIcecat.length > 0)) &&
            !hideKeywordLabel && (
              <>
                {!edit && !thisEdit && isAdmin ? (
                  <Typography
                    sx={{
                      fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                    }}
                    component="span"
                  >
                    {t("word.keywordsIcecat") + ":"}
                    <IconButton onClick={changeThisEdit}>
                      <EditIcon />
                    </IconButton>
                    {fArticle?.fArticleProp?.keywordsIcecat &&
                      fArticle?.fArticleProp?.keywordsIcecat.length > 0 && (
                        <Tooltip title={t("word.copied")} open={openCopied}>
                          <IconButton onClick={copy}>
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      fontSize: (fontSize ?? defaultFontSize) / 10 + "rem",
                    }}
                    component="span"
                  >
                    {t("word.keywordsIcecat") + ":"}
                  </Typography>
                )}
              </>
            )}
          {fArticle &&
            fArticle.fArticleProp &&
            (edit || thisEdit ? (
              <>
                <Autocomplete
                  multiple
                  fullWidth
                  open={false}
                  popupIcon={""}
                  options={[]}
                  inputValue={valueInputValue}
                  onInputChange={handleValueInputValueChange}
                  onChange={handleChangeAutocomplete("keywordsIcecat")}
                  value={values.keywordsIcecat.value}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          addValueInputValue();
                        }
                      }}
                      required
                      autoComplete="off"
                      error={!!values.keywordsIcecat.error}
                      helperText={t(values.keywordsIcecat.error ?? "")}
                      label={t("word.values")}
                      placeholder={t("word.values")}
                    />
                  )}
                />
                {!edit && (
                  <>
                    <Tooltip title={t("word.cancel")}>
                      <IconButton onClick={changeThisEdit} disabled={loading}>
                        <CancelIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("word.save")}>
                      <LoadingButton
                        variant="text"
                        color="inherit"
                        sx={{
                          borderRadius: "50%",
                          minWidth: "auto",
                          padding: "8px",
                          color: "rgba(0, 0, 0, 0.54)",
                        }}
                        loading={loading}
                        onClick={save}
                      >
                        <SaveIcon />
                      </LoadingButton>
                    </Tooltip>
                  </>
                )}
              </>
            ) : (
              fArticle.fArticleProp?.keywordsIcecat &&
              fArticle.fArticleProp?.keywordsIcecat.length > 0 && (
                <Box sx={sx}>
                  {tooltip ? (
                    <Tooltip
                      title={
                        <>
                          {fArticle.fArticleProp?.keywordsIcecat.map(
                            (keyword, indexKeyword) => (
                              <Chip
                                key={indexKeyword}
                                label={keyword}
                                variant="outlined"
                                sx={{
                                  marginLeft: 0.5,
                                  marginTop: 0.5,
                                  color: "white",
                                  fontSize:
                                    (fontSize ?? defaultFontSize) / 10 + "rem",
                                }}
                              />
                            )
                          )}
                        </>
                      }
                    >
                      <Chip
                        label={
                          <Typography
                            component="span"
                            sx={{
                              fontSize:
                                (fontSize ?? defaultFontSize) / 10 + "rem",
                            }}
                          >
                            {t("word.keywordsIcecat")}
                          </Typography>
                        }
                        variant="outlined"
                        color="primary"
                      />
                    </Tooltip>
                  ) : (
                    fArticle.fArticleProp?.keywordsIcecat.map(
                      (keyword, indexKeyword) => (
                        <Chip
                          key={indexKeyword}
                          label={keyword}
                          color="primary"
                          variant="outlined"
                          sx={{
                            marginLeft: 0.5,
                            marginTop: 0.5,
                            fontSize:
                              (fontSize ?? defaultFontSize) / 10 + "rem",
                          }}
                        />
                      )
                    )
                  )}
                </Box>
              )
            ))}
        </Box>
      );
    }
  )
);

export default FArticleKeywordIcecatComponent;
