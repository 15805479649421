const PREFIX_PAGE = "/informatique-guadeloupe";
export const LOGIN_PAGE = PREFIX_PAGE + "/login";
export const REGISTER_PAGE = PREFIX_PAGE + "/register";
export const HOME_PAGE = "/";
export const ADMIN_PREFIX = PREFIX_PAGE + "/admin";
export const BRAND_PAGE = PREFIX_PAGE + "/brands";
export const BROWSER_PREFIX = PREFIX_PAGE + "/browser";
export const ACCOUNT_PAGE = PREFIX_PAGE + "/account";
export const CONFIGURATOR_PAGE = PREFIX_PAGE + "/configurator";
export const ORDER_PAGE = PREFIX_PAGE + "/order";
export const ORDERS_PAGE = PREFIX_PAGE + "/orders";
export const CATEGORY_PAGE = PREFIX_PAGE + "/categories";
export const PRODUCT_PAGE = PREFIX_PAGE + "/product";
export const PRODUCTS_PAGE = PREFIX_PAGE + "/products";
export const PRODUCT_DUPLICATE_PAGE = PREFIX_PAGE + "/product/duplicate";
export const RESET_PASSWORD_PAGE = PREFIX_PAGE + "/reset-password";
export const STATS_PAGE = ADMIN_PREFIX + "/stats";
export const RAW_HTML_PAGE = PREFIX_PAGE + "/docs";
export const SAV_PAGE = PREFIX_PAGE + "/sav";
export const ACCOUNTING_SITUATION_PAGE = PREFIX_PAGE + "/accounting-situation";
export const ABOUT_PAGE = PREFIX_PAGE + "/about";
export const ADMIN_SAV_PAGE = ADMIN_PREFIX + "/sav";
export const FILTER_PAGE = ADMIN_PREFIX + "/filters";
export const EXPEDITION_PAGE = ADMIN_PREFIX + "/expeditions";
export const ADMIN_CATEGORY_PAGE = ADMIN_PREFIX + "/categories";
export const ADMIN_BRAND_PAGE = ADMIN_PREFIX + "/brands";
export const ADMIN_CRON_PAGE = ADMIN_PREFIX + "/crons";
export const ADMIN_USER_PAGE = ADMIN_PREFIX + "/users";
export const CONFIGURATION_PAGE = ADMIN_PREFIX + "/configurations";
export const FEEDBACK_PAGE = PREFIX_PAGE + "/feedbacks";
export const ADMIN_FEEDBACK_PAGE = ADMIN_PREFIX + "/feedbacks";
export const ADMIN_CONSOLE_PAGE = ADMIN_PREFIX + "/console";
export const ADMIN_EDI_PAGE = ADMIN_PREFIX + "/edi";
export const ADMIN_NOVE_FOURNISSEUR_PAGE = ADMIN_PREFIX + "/nove-fournisseur";
export const ADMIN_INVOICE_PAGE = ADMIN_PREFIX + "/invoice";
export const BROWSER_PRODUCT_FILTER_VALUE_PAGE =
  BROWSER_PREFIX + "/product-filter-value";
export const BROWSER_FILTER_VALUE_ASSO =
  PREFIX_PAGE + BROWSER_PREFIX + "/filter-value-assos";
export const TAG_PAGE = ADMIN_PREFIX + "/tags";
export const ADMIN_MAIL_PAGE = ADMIN_PREFIX + "/mails";
export const ADMIN_PAYMENT_PAGE = ADMIN_PREFIX + "/payments";
export const FISCAL_CODE_PAGE = ADMIN_PREFIX + "/fiscal-code";
export const NEWS_PAGE = PREFIX_PAGE + "/news";
export const FAVORIS_PAGE = PREFIX_PAGE + "/favoris";
export const COMPARE_PAGE = PREFIX_PAGE + "/compare";
export const PDF_PAGE = ADMIN_PREFIX + "/pdf";
export const ARRIVAGE_PAGE = ADMIN_PREFIX + "/arrivage";
export const ENTERING_INVOICE_PAGE = ADMIN_PREFIX + "/entering-invoice";
