export const EAN = "EAN";
export const STOCK = "STOCK";
export const FABRICANT = "FABRICANT";
export const PRICE = "PRICE";
export const REF_FABRICANT = "REF_FABRICANT";
export const DESIGNATION = "DESIGNATION";
export const PRIX_ACHAT = "PRIX_ACHAT";
export const REF_FOURNISSEUR = "REF_FOURNISSEUR";
export const STOCK_STATUS = "STOCK_STATUS";

export const FOURNISSEUR_STOCK_STATUS_IN_STOCK = "IN_STOCK";
export const FOURNISSEUR_STOCK_STATUS_AVAILABLE = "AVAILABLE";
export const FOURNISSEUR_STOCK_STATUS_OBSOLETE = "OBSOLETE";

export const FOURNISSEUR_FIELD_MAPPING_FIELD = [
  {
    prop: EAN,
    color: "#FF0000", // Red
  },
  {
    prop: STOCK,
    color: "#008000", // Green
  },
  {
    prop: FABRICANT,
    color: "#0000FF", // Blue
  },
  {
    prop: PRICE,
    color: "#FFD700", // Gold
  },
  {
    prop: REF_FABRICANT,
    color: "#8A2BE2", // Blue Violet
  },
  {
    prop: DESIGNATION,
    color: "#FF4500", // Orange Red
  },
  {
    prop: PRIX_ACHAT,
    color: "#A52A2A", // Brown
  },
  {
    prop: REF_FOURNISSEUR,
    color: "#00CED1", // Dark Turquoise
  },
  {
    prop: STOCK_STATUS,
    color: "#9400D3", // Dark Violet
  },
];
