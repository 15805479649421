import * as React from "react";
import {
  FArticleSmallInterface,
  ValueConfigurationFArticleOptionInterface,
} from "../../../interfaces/FArticleInterface";
import { FormHelperText } from "@mui/material";
import Box from "@mui/material/Box";
import { getMaxQuantityCanBuy } from "../../../helpers/FArticleHelper";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  valueOptions?: ValueConfigurationFArticleOptionInterface[];
  maxWithCart?: boolean;
  depot?: number;
}

const FArticleMaxQuantityComponent = React.memo(
  React.forwardRef(
    ({ fArticle, valueOptions, maxWithCart, depot }: State, ref) => {
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const { t } = useTranslation();
      const cart = useAppSelector((state: RootState) => state.globalState.cart);
      const [maxQuantityCanBuy, setMaxQuantityCanBuy] = React.useState<
        number | undefined
      >(
        getMaxQuantityCanBuy(
          fArticle,
          isAdmin,
          valueOptions,
          maxWithCart,
          depot
        )
      );
      const updateQuantityToMax = React.useCallback(() => {
        const newMax = getMaxQuantityCanBuy(
          fArticle,
          isAdmin,
          valueOptions,
          maxWithCart,
          depot
        );
        setMaxQuantityCanBuy(newMax);
      }, [depot, fArticle, isAdmin, maxWithCart, valueOptions]);

      React.useEffect(() => {
        updateQuantityToMax();
      }, [valueOptions, cart, fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <Box sx={{ textAlign: "center" }}>
          {maxQuantityCanBuy !== undefined && maxQuantityCanBuy > 0 && (
            <FormHelperText sx={{ marginBottom: 1, textAlign: "center" }}>
              {t("sentence.farticle.maxQuantityCanBuy").replace(
                "%quantity%",
                maxQuantityCanBuy.toString()
              )}
            </FormHelperText>
          )}
        </Box>
      );
    }
  )
);

export default FArticleMaxQuantityComponent;
