import * as React from "react";
import { useRef } from "react";
import {
  FArticleInterface,
  ReviewInterface,
} from "../../../interfaces/FArticleInterface";
import {
  Card,
  CardContent,
  Container,
  Rating,
  Skeleton,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET, PATCH, POST } from "../../../utils/MethodUtils";
import { FARTICLE_PROP_URL, REVIEW_URL } from "../../../utils/UrlsUtils";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { LoadingButton } from "@mui/lab";
import InfoIcon from "@mui/icons-material/Info";
import CkEditorComponent from "../../content/CkEditorComponent";
import { toastr } from "react-redux-toastr";
import SearchComponent from "../SearchComponent";

interface State {
  fArticle: FArticleInterface | undefined;
  reviews: ReviewInterface[] | undefined;
  setReviews: any;
}

interface State2 {
  fArticle: FArticleInterface;
  review?: ReviewInterface;
  setShowCreateContent?: any;
  indexReview?: number;
  setReview?: any;
  addReview?: any;
  setEdit?: any;
}

interface State3 {
  review: ReviewInterface;
  indexReview: number;
  setReview: any;
  addReview: any;
  fArticle: FArticleInterface;
}

const CardContentComponent = React.memo(
  React.forwardRef(
    ({ fArticle, review, indexReview, setReview, addReview }: State3, ref) => {
      const { t, i18n } = useTranslation();
      const [edit, setEdit] = React.useState(false);
      const user = useAppSelector((state: RootState) => state.globalState.user);
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );

      return (
        <>
          {edit ? (
            <FArticleReviewContentComponent
              fArticle={fArticle!}
              review={review}
              indexReview={indexReview}
              setReview={setReview}
              setEdit={setEdit}
            />
          ) : (
            <Card variant="outlined" sx={{ marginBottom: 1 }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Typography color="text.secondary" gutterBottom>
                      {review.ctIntitule}
                    </Typography>
                    <Rating
                      value={review.rating / 2}
                      precision={0.5}
                      readOnly
                    />
                    {!review.valid && isAdmin && (
                      <Typography color="error">
                        [{t("word.notValidated")}]
                      </Typography>
                    )}
                  </Box>
                  <Typography variant="caption">
                    {new Date(review.updated).toLocaleDateString(
                      i18n.language,
                      {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        timeZone: "America/Cuiaba",
                      }
                    )}
                  </Typography>
                </Box>
                <div
                  dangerouslySetInnerHTML={{
                    __html: review.content ?? "",
                  }}
                />
                {user?.userIdentifier === review.userIdentifier && (
                  <Box sx={{ textAlign: "right" }}>
                    <LoadingButton
                      variant="contained"
                      onClick={() => {
                        setEdit(true);
                      }}
                    >
                      {t("word.update")}
                    </LoadingButton>
                  </Box>
                )}
              </CardContent>
            </Card>
          )}
        </>
      );
    }
  )
);

const FArticleReviewContentComponent = React.memo(
  React.forwardRef(
    (
      {
        fArticle,
        review,
        setShowCreateContent,
        indexReview,
        setReview,
        addReview,
        setEdit,
      }: State2,
      ref
    ) => {
      const { t } = useTranslation();
      const [value, setValue] = React.useState<number | null>(
        review ? review.rating / 2 : null
      );
      const [showErrorRating, setShowErrorRating] =
        React.useState<boolean>(false);
      const ckEditorRef: any = useRef();
      const [loading, setLoading] = React.useState<boolean>(false);
      const token = useAppSelector(
        (state: RootState) => state.globalState.token
      );

      const save = React.useCallback(async () => {
        const data = ckEditorRef.current.getValue();
        const rating = Math.round((value ?? 0) * 2);
        if (rating === 0) {
          setShowErrorRating(true);
          return;
        }
        setLoading(true);
        let path = REVIEW_URL;
        if (review) {
          path += "/" + review.id;
        }
        const response = await requestApi({
          method: review ? PATCH : POST,
          path: path,
          allowError: true,
          token: token,
          body: {
            rating: rating,
            content: data,
            fArticleProp: FARTICLE_PROP_URL + "/" + fArticle.fArticleProp.id,
          },
        });
        if (response.statusCode === 200 || response.statusCode === 201) {
          toastr.success(t("word.success"), t("word.success"));
          if (review) {
            if (indexReview && setReview) {
              setReview(response.content, indexReview);
            }
          } else {
            if (addReview) {
              addReview(response.content);
            }
          }
          if (setEdit) {
            setEdit(false);
          }
          if (setShowCreateContent) {
            setShowCreateContent(false);
          }
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          toastr.error(t("word.error"), t("error.tryAgain"));
        }
        setLoading(false);
      }, [
        addReview,
        fArticle.fArticleProp.id,
        indexReview,
        review,
        setEdit,
        setReview,
        setShowCreateContent,
        t,
        token,
        value,
      ]);

      return (
        <>
          <Box sx={{ textAlign: "center" }}>
            <Rating
              value={value}
              precision={0.5}
              onChange={(event, newValue) => {
                setShowErrorRating(false);
                setValue(newValue);
              }}
              size="large"
            />
            {showErrorRating && (
              <Typography color="error">
                {t("sentence.selectRating")}
              </Typography>
            )}
          </Box>
          <CkEditorComponent ref={ckEditorRef} text={review?.content ?? ""} />
          <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
            {setShowCreateContent && (
              <Tooltip title={t("word.cancel")}>
                <LoadingButton
                  onClick={() => {
                    setShowCreateContent(false);
                  }}
                  disabled={loading}
                >
                  {t("word.cancel")}
                </LoadingButton>
              </Tooltip>
            )}
            <Tooltip title={t("word.save")}>
              <LoadingButton
                variant="contained"
                onClick={save}
                loading={loading}
              >
                {t("word.save")}
              </LoadingButton>
            </Tooltip>
          </Box>
        </>
      );
    }
  )
);

const FArticleReviewsComponent = React.memo(
  React.forwardRef(({ fArticle, reviews, setReviews }: State, ref) => {
    const { t } = useTranslation();
    const [page, setPage] = React.useState<number>(1);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [nbPage, setNbPage] = React.useState<number | undefined>(undefined);
    const [showCreateContent, setShowCreateContent] =
      React.useState<boolean>(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);

    const loadReviews = React.useCallback(async () => {
      if (fArticle?.arRef === undefined) {
        return;
      }
      const itemsPerPage = 10;
      setLoading(true);
      const response = await requestApi({
        method: GET,
        path:
          REVIEW_URL +
          objectToQuery({
            page: page,
            fArticleProp: fArticle?.fArticleProp.id,
            itemsPerPage: itemsPerPage,
            "order[created]": "desc",
          }),
        allowError: false,
        paginate: true,
        token: token,
      });
      const nbPage = Math.ceil(
        response.content["hydra:totalItems"] / itemsPerPage
      );
      setReviews((x: any) => {
        return [...(x ?? []), ...response.content["hydra:member"]].filter(
          (value, index, self) =>
            index === self.findIndex((y) => y.id === value.id)
        );
      });
      setNbPage(nbPage);
      setLoading(false);
    }, [fArticle?.arRef, fArticle?.fArticleProp.id, page, setReviews, token]);

    const setReview = React.useCallback(
      (newReview: ReviewInterface, indexReview: number) => {
        setReviews((array: ReviewInterface[]) => {
          array[indexReview] = newReview;
          return [...array];
        });
      },
      [setReviews]
    );

    const addReview = React.useCallback(
      (newReview: ReviewInterface) => {
        setReviews((array: ReviewInterface[]) => {
          array.unshift(newReview);
          return [...array];
        });
      },
      [setReviews]
    );

    React.useEffect(() => {
      loadReviews();
    }, [page, fArticle?.arRef]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      const el = document.querySelector(".lastComment");
      const newObserver = new IntersectionObserver(
        ([e]) => {
          if (
            nbPage !== undefined &&
            page < nbPage &&
            e.intersectionRatio > 0
          ) {
            setNbPage(undefined);
            setTimeout(() => {
              setPage((x) => x + 1);
            });
          }
        },
        { threshold: [1] }
      );
      if (el !== null) {
        newObserver.observe(el);
      }
      return () => {
        newObserver.disconnect();
      };
    }, [reviews]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <Container maxWidth="xl">
        {reviews === undefined ? (
          <Skeleton
            variant="rectangular"
            height={150}
            sx={{ marginTop: 2, marginBottom: 2 }}
          />
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {!showCreateContent && fArticle?.fArticleProp.reviewId === null && (
                <LoadingButton
                  onClick={() => {
                    setShowCreateContent((x) => !x);
                  }}
                  variant="contained"
                  disabled={fArticle?.fArticleProp.alreadyBuy !== true}
                >
                  {t("word.addReview")}
                </LoadingButton>
              )}
              {fArticle?.fArticleProp.alreadyBuy !== true && (
                <Tooltip title={t("sentence.explainCantAddReview")}>
                  <InfoIcon />
                </Tooltip>
              )}
            </Box>
            {showCreateContent && (
              <Box sx={{ marginTop: 2 }}>
                <FArticleReviewContentComponent
                  setShowCreateContent={setShowCreateContent}
                  fArticle={fArticle!}
                  addReview={addReview}
                />
              </Box>
            )}
            {reviews?.length === 0 ? (
              <Typography>{t("sentence.noReview")}</Typography>
            ) : (
              <>
                {fArticle &&
                  reviews?.map((review, indexReview) => {
                    return (
                      <CardContentComponent
                        review={review}
                        key={indexReview}
                        indexReview={indexReview}
                        setReview={setReview}
                        addReview={addReview}
                        fArticle={fArticle}
                      />
                    );
                  })}
                {loading && <SearchComponent nbColumn={1} nbLines={4} />}
              </>
            )}
          </>
        )}
        <Box className="lastComment" />
      </Container>
    );
  })
);

export default FArticleReviewsComponent;
