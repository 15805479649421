import React from "react";
import {
  Alert,
  Card,
  CardContent,
  CircularProgress,
  Container,
  LinearProgress,
} from "@mui/material";
import LoginComponent from "../user/LoginComponent";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useTranslation } from "react-i18next";
import {
  FournisseurFieldMappingInterface,
  FournisseurFileFieldChangedInterface,
  FournisseurFileInterface,
} from "../../../interfaces/NoveFournisseurInterface";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET, PATCH, POST } from "../../../utils/MethodUtils";
import {
  FOURNISSEUR_FIELD_MAPPING_URL,
  FOURNISSEUR_FILE_FIELD_CHANGED_URL,
  FOURNISSEUR_FILE_URL,
} from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import { RootState } from "../../../app/store";
import { UserInterface } from "../../../interfaces/UserInterface";
import { set } from "../../../app/globalSlice";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import { styled, useTheme } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CopyClipboardComponent from "../CopyClipboardComponent";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import JsonDisplayerComponent from "./JsonDisplayerComponent";
import {
  EAN,
  FOURNISSEUR_FIELD_MAPPING_FIELD,
  FOURNISSEUR_STOCK_STATUS_OBSOLETE,
  REF_FABRICANT,
  STOCK_STATUS,
} from "../../../utils/NoveFournisseurUtils";
import Box from "@mui/material/Box";
import { getAllPathObject } from "../../../helpers/FileHelper";
import { getDiff } from "json-difference";
import { LoadingButton } from "@mui/lab";
import Typography from "@mui/material/Typography";
import getErrorApi from "../../../helpers/GetErrorApi";
import { EventSourcePolyfill } from "event-source-polyfill";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import { PRODUCT_PAGE } from "../../../utils/RouteUtils";
import { Link } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";

const clone = require("clone");

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const humanizeDuration = require("humanize-duration");

interface State {
  initFournisseurFile?: FournisseurFileInterface;
  id: number;
}

interface State2 {
  fournisseur: UserInterface | undefined;
  fournisseurFile: FournisseurFileInterface | undefined;
}

interface State3 {
  fournisseurFileId: number | undefined;
}

const TableSingleNoveFournisseurComponent = React.memo<State2>(
  ({ fournisseur, fournisseurFile }) => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [downloading, setDownloading] = React.useState<number | undefined>(
      undefined
    );
    const download = React.useCallback(
      async (fournisseurFileId: number | undefined) => {
        if (!fournisseurFileId) {
          return;
        }
        setDownloading(fournisseurFileId);
        try {
          const res = await fetch(
            (process.env.REACT_APP_API_URL ?? "") +
              fournisseurFile?.path
                ?.replace("/srv", "")
                ?.replace("/app/public", "")
          );
          const blob = await res.blob();
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download =
            "[" +
            fournisseur?.userIdentifier +
            "]" +
            fournisseur?.ctIntitule +
            "[" +
            fournisseurFile?.id +
            "]";
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        } catch (e) {
          // nothing
        }
        setDownloading(undefined);
      },
      [fournisseur?.ctIntitule, fournisseurFile?.id, fournisseurFile?.path]
    );

    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <StyledTableCell>{t("word.supplier")}</StyledTableCell>
            <StyledTableCell>{t("column.done")}</StyledTableCell>
            <StyledTableCell>{t("column.nbProducts")}</StyledTableCell>
            <StyledTableCell>{t("word.fileDeleted")}</StyledTableCell>
            <StyledTableCell>{t("word.executionTime")}</StyledTableCell>
            <StyledTableCell>{t("word.createdOn")}</StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>
              {fournisseur?.ctIntitule}
              <CopyClipboardComponent
                className="RobotoMono"
                component="span"
                sx={{
                  color: theme.palette.success.main,
                  wordBreak: "keep-all",
                  marginLeft: 1,
                }}
                text={fournisseur?.userIdentifier ?? ""}
              />
            </StyledTableCell>
            <StyledTableCell>
              {fournisseurFile?.done ? (
                <DoneIcon color="success" />
              ) : (
                <CloseIcon color="error" />
              )}
            </StyledTableCell>
            <StyledTableCell>{fournisseurFile?.nbProducts}</StyledTableCell>
            <StyledTableCell>
              {fournisseurFile?.fileDeleted ? (
                <DoneIcon color="success" />
              ) : (
                <CloseIcon color="error" />
              )}
            </StyledTableCell>
            <StyledTableCell>{fournisseurFile?.executionTime}</StyledTableCell>
            <StyledTableCell>
              {fournisseurFile?.created &&
                new Date(fournisseurFile?.created).toLocaleString(
                  i18n.language,
                  {
                    dateStyle: "long",
                    timeStyle: "medium",
                    timeZone: "America/Cuiaba",
                  }
                )}
            </StyledTableCell>
            <StyledTableCell>
              <LoadingButton
                variant="text"
                color="inherit"
                sx={{
                  borderRadius: "50%",
                  minWidth: "auto",
                  padding: "12px",
                }}
                loading={downloading === fournisseurFile?.id}
                onClick={() => {
                  download(fournisseurFile?.id);
                }}
              >
                <DownloadIcon />
              </LoadingButton>
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    );
  }
);

const ProgressBarNoveFournisseurComponent = React.memo<State3>(
  ({ fournisseurFileId }) => {
    const tokenMercure = useAppSelector(
      (state: RootState) => state.globalState.tokenMercure
    );
    const [progressValue, setProgressValue] = React.useState<any>(null);

    React.useEffect(() => {
      if (!fournisseurFileId) {
        return;
      }
      const url = new URL(
        process.env.REACT_APP_API_URL + "/.well-known/mercure"
      );
      url.searchParams.append("topic", "/api/admin/fournisseur_files");

      const es = new EventSourcePolyfill(url.toString(), {
        headers: {
          Authorization: "Bearer " + tokenMercure,
        },
        heartbeatTimeout: 3600 * 1000, // 1 hour
      });
      es.addEventListener("message", (event: any) => {
        const data: any = JSON.parse(event.data);
        if (data.data.id !== fournisseurFileId) {
          return;
        }
        setProgressValue(data.data);
      });
      return () => {
        es.close();
      };
    }, [tokenMercure, fournisseurFileId]);

    let percent = null;
    if (progressValue) {
      percent =
        Math.round((progressValue.index / progressValue.max) * 100_000) / 1000;
    }
    return (
      <>
        {percent !== null && (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ width: "100%", mr: 1 }}>
              <LinearProgress variant="determinate" value={percent} />
            </Box>
            <Box sx={{ minWidth: 180 }}>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {`${progressValue.index} / ${progressValue.max}`}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {`${percent}% `}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {humanizeDuration(progressValue.remainingTime, {
                  language: "fr",
                  largest: 2,
                  round: true,
                })}
              </Typography>
            </Box>
          </Box>
        )}
      </>
    );
  }
);

const DetailsNoveFournisseurComponent = React.memo<State3>(
  ({ fournisseurFileId }) => {
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [fournisseurFileFields, setFournisseurFileFields] = React.useState<
      FournisseurFileFieldChangedInterface[] | undefined
    >(undefined);

    const load = React.useCallback(async () => {
      if (!fournisseurFileId) {
        return;
      }
      setLoading(true);
      const response = await requestApi({
        method: GET,
        path:
          FOURNISSEUR_FILE_FIELD_CHANGED_URL +
          objectToQuery({
            field: STOCK_STATUS,
            value: FOURNISSEUR_STOCK_STATUS_OBSOLETE,
            fournisseurFile: fournisseurFileId,
          }),
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        setFournisseurFileFields(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [fournisseurFileId, t, token]);

    React.useEffect(() => {
      load();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {loading ? (
          <Box sx={{ margin: 2 }}>
            <CircularProgress />
          </Box>
        ) : fournisseurFileFields !== undefined ? (
          <>
            {fournisseurFileFields.length === 0 ? (
              <>{t("word.noResult")}</>
            ) : (
              <TableContainer component={Paper}>
                <Table sx={{ width: "100%" }}>
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>{t("word.reference")}</StyledTableCell>
                      <StyledTableCell>{t("word.stock.label")}</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fournisseurFileFields.map(
                      (fournisseurFileField, index) => (
                        <StyledTableRow key={index}>
                          <StyledTableCell>
                            <Link
                              to={
                                PRODUCT_PAGE + "/" + fournisseurFileField.arRef
                              }
                            >
                              {fournisseurFileField.arRef}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell>
                            {fournisseurFileField.value}
                          </StyledTableCell>
                        </StyledTableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </>
        ) : (
          <>{t("word.error")}</>
        )}
      </>
    );
  }
);

const SingleNoveFournisseurComponent = React.memo<State>(
  ({ initFournisseurFile, id }) => {
    const theme = useTheme();
    const refreshPage = useAppSelector(
      (state: RootState) => state.globalState.refreshPage
    );
    const [init, setInit] = React.useState(false);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const fournisseurFieldMappings = useAppSelector(
      (state: RootState) => state.globalState.fournisseurFieldMappings
    );
    const [
      canSaveFournisseurFieldMappings,
      setCanSaveFournisseurFieldMappings,
    ] = React.useState<boolean>(false);
    const fournisseurs = useAppSelector(
      (state: RootState) => state.globalState.fournisseurs
    );
    const [fournisseurFile, setFournisseurFile] = React.useState<
      FournisseurFileInterface | undefined
    >(initFournisseurFile);
    const [selectedField, setSelectedField] = React.useState<string>("");
    const [loadingSaveMapping, setLoadingSaveMapping] =
      React.useState<boolean>(false);
    const [loadingUpdateProducts, setLoadingUpdateProducts] =
      React.useState<boolean>(false);
    const [selectedPath, setSelectedPath] = React.useState<string>("");
    const getFournisseur = React.useCallback(() => {
      return fournisseurs?.find(
        (f) => f.userIdentifier === fournisseurFile?.userIdentifier
      );
    }, [fournisseurFile?.userIdentifier, fournisseurs]);
    const [fournisseur, setFournisseur] = React.useState<
      UserInterface | undefined
    >(getFournisseur());
    const getThisFournisseurFieldMappings =
      React.useCallback((): FournisseurFieldMappingInterface[] => {
        if (!fournisseurFile?.firstLines) {
          return [];
        }
        let allPaths: string[] = [];
        getAllPathObject(fournisseurFile?.firstLines![0], allPaths);
        const allProps = FOURNISSEUR_FIELD_MAPPING_FIELD.map((x) => x.prop);
        return (
          fournisseurFieldMappings?.filter(
            (f) =>
              f.userIdentifier === fournisseurFile?.userIdentifier &&
              allProps.includes(f.field) &&
              allPaths.includes(f.path)
          ) ?? []
        );
      }, [
        fournisseurFieldMappings,
        fournisseurFile?.firstLines,
        fournisseurFile?.userIdentifier,
      ]);

    const [thisFournisseurFieldMappings, setThisFournisseurFieldMappings] =
      React.useState<FournisseurFieldMappingInterface[]>(
        getThisFournisseurFieldMappings()
      );

    const load = React.useCallback(async () => {
      setInit(true);
      dispatch(set({ refreshPage: true }));
      const response = await requestApi({
        method: GET,
        path: FOURNISSEUR_FILE_URL + "/" + id,
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        setFournisseurFile(response.content);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      }
      dispatch(set({ refreshPage: false }));
    }, [dispatch, id, t, token]);

    const map = React.useCallback(() => {
      if (selectedField === "" || selectedPath === "") {
        return;
      }
      setThisFournisseurFieldMappings((x) => {
        x = x.filter((f) => f.field !== selectedField);
        x = x.filter((f) => f.path !== selectedPath);
        x.push({
          path: selectedPath,
          field: selectedField,
          userIdentifier: fournisseurFile!.userIdentifier,
        });
        return [...x];
      });
      setSelectedPath("");
      setSelectedField("");
    }, [fournisseurFile, selectedField, selectedPath]);

    const checkThisFournisseurFieldMappingsChanged = React.useCallback(
      (
        a: FournisseurFieldMappingInterface[],
        b: FournisseurFieldMappingInterface[]
      ) => {
        const diff = getDiff(a, b);
        setCanSaveFournisseurFieldMappings(
          diff.added.length !== 0 ||
            diff.edited.length !== 0 ||
            diff.removed.length !== 0
        );
      },
      []
    );

    const saveMapping = React.useCallback(async () => {
      setLoadingSaveMapping(true);
      const response = await requestApi({
        method: POST,
        path: FOURNISSEUR_FIELD_MAPPING_URL,
        allowError: true,
        token: token,
        body: {
          fournisseurFileId: fournisseurFile?.id,
          fournisseurFieldMappings: clone(thisFournisseurFieldMappings).map(
            (x: FournisseurFieldMappingInterface) => {
              if (x.hasOwnProperty("id")) {
                delete x.id;
              }
              return { ...x };
            }
          ),
        },
      });
      if (response.statusCode === 200) {
        const newFournisseurFieldMappings: FournisseurFieldMappingInterface[] =
          [
            ...clone(fournisseurFieldMappings).filter(
              (f: FournisseurFieldMappingInterface) =>
                f.userIdentifier !== fournisseurFile?.userIdentifier
            ),
            ...response.content,
          ];
        setFournisseurFile((f) => {
          if (f) {
            f!.warningVerifyFile = false;
          }
          return clone(f);
        });
        dispatch(
          set({ fournisseurFieldMappings: newFournisseurFieldMappings })
        );
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoadingSaveMapping(false);
    }, [
      dispatch,
      fournisseurFieldMappings,
      fournisseurFile?.id,
      fournisseurFile?.userIdentifier,
      t,
      thisFournisseurFieldMappings,
      token,
    ]);

    const processFournisseurFile = React.useCallback(async () => {
      setLoadingUpdateProducts(true);
      const response = await requestApi({
        method: PATCH,
        path: FOURNISSEUR_FILE_URL + "/" + fournisseurFile?.id + "/process",
        allowError: true,
        token: token,
        body: {},
      });
      if (response.statusCode === 200) {
        toastr.success(t("word.success"), t("word.success"));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoadingUpdateProducts(false);
    }, [fournisseurFile?.id, t, token]);

    React.useEffect(() => {
      load();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (init && refreshPage) {
        load();
      }
    }, [id, refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      setFournisseur(getFournisseur());
      setThisFournisseurFieldMappings(getThisFournisseurFieldMappings());
    }, [fournisseurFile, fournisseurFieldMappings]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      map();
    }, [selectedPath]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      checkThisFournisseurFieldMappingsChanged(
        getThisFournisseurFieldMappings(),
        thisFournisseurFieldMappings
      );
    }, [thisFournisseurFieldMappings]); // eslint-disable-line react-hooks/exhaustive-deps

    const hasSelectedEnoughMapping =
      thisFournisseurFieldMappings.filter((x) =>
        [EAN, REF_FABRICANT].includes(x.field)
      ).length > 0;

    return (
      <>
        <Container sx={{ marginY: 2 }}>
          <LoginComponent redirect={null} requireAdmin={true}>
            <ProgressBarNoveFournisseurComponent
              fournisseurFileId={fournisseurFile?.id}
            />
            <TableSingleNoveFournisseurComponent
              fournisseur={fournisseur}
              fournisseurFile={fournisseurFile}
            />
            <Box sx={{ marginBottom: 1, textAlign: "center" }}>
              {canSaveFournisseurFieldMappings && (
                <Typography color="error">
                  {t("sentence.firstSaveMapping")}
                </Typography>
              )}
              {!hasSelectedEnoughMapping && (
                <Typography color="error">
                  {t("sentence.hasNotSelectedEnoughMapping")}
                </Typography>
              )}
              {fournisseurFile?.warningVerifyFile && (
                <Container maxWidth="sm" sx={{ marginTop: 1 }}>
                  <Alert severity="warning">
                    {t("sentence.explainWarningVerifyFile")}
                  </Alert>
                </Container>
              )}
              {fournisseurFile?.done && (
                <Accordion defaultExpanded={false} sx={{ marginTop: 1 }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
                    sx={{
                      backgroundColor: theme.palette.primary.main,
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        marginTop: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {t("word.details")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <DetailsNoveFournisseurComponent
                      fournisseurFileId={fournisseurFile?.id}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
              <LoadingButton
                variant="contained"
                loading={loadingUpdateProducts}
                sx={{ marginTop: 1 }}
                onClick={processFournisseurFile}
                disabled={
                  canSaveFournisseurFieldMappings ||
                  !hasSelectedEnoughMapping ||
                  fournisseurFile?.warningVerifyFile
                }
              >
                {t("word.updateArticles")}
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginTop: 1,
              }}
            >
              {FOURNISSEUR_FIELD_MAPPING_FIELD.map((f: any, index: number) => (
                <Card
                  variant="outlined"
                  key={index}
                  sx={{
                    marginBottom: 1,
                    borderColor: f.color,
                    cursor: "pointer",
                    ...(thisFournisseurFieldMappings.find(
                      (x) => f.prop === x.field
                    ) && {
                      backgroundColor: "rgba(92, 184, 92, 0.3)",
                    }),
                    ...(selectedField === f.prop && {
                      backgroundColor: theme.palette.primary.main,
                      color: theme.palette.primary.contrastText,
                    }),
                  }}
                  onClick={() => {
                    if (selectedField === f.prop) {
                      setSelectedField("");
                      setThisFournisseurFieldMappings((x) => {
                        x = x.filter((y) => y.field !== f.prop);
                        return [...x];
                      });
                    } else {
                      setSelectedField(f.prop);
                    }
                  }}
                >
                  <CardContent sx={{ padding: 1 }}>{f.prop}</CardContent>
                </Card>
              ))}
            </Box>
            {canSaveFournisseurFieldMappings && (
              <Box sx={{ marginBottom: 1, textAlign: "center" }}>
                <LoadingButton
                  variant="contained"
                  loading={loadingSaveMapping}
                  onClick={saveMapping}
                >
                  {t("word.saveNewMapping")}
                </LoadingButton>
              </Box>
            )}
            {fournisseurFile?.firstLines?.map((f, indexF) => (
              <JsonDisplayerComponent
                key={indexF}
                object={f}
                setSelectedPath={setSelectedPath}
                thisFournisseurFieldMappings={thisFournisseurFieldMappings}
              />
            ))}
          </LoginComponent>
        </Container>
      </>
    );
  }
);

export default SingleNoveFournisseurComponent;
