import * as React from "react";
import { useRef } from "react";
import { Grid, TextField, Tooltip } from "@mui/material";
import {
  MailInterface,
  MailParamInterface,
} from "../../../interfaces/MailInterface";
import { InputInterface } from "../../../interfaces/InputInterface";
import { useTranslation } from "react-i18next";
import CopyClipboardComponent from "../CopyClipboardComponent";
import Box from "@mui/material/Box";
import { LoadingButton } from "@mui/lab";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { PATCH } from "../../../utils/MethodUtils";
import { MAIL_PREVIEW_URL, MAIL_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import CkEditorComponent from "../../content/CkEditorComponent";
import { RAW_HTML_PAGE } from "../../../utils/RouteUtils";

interface State {
  mail: MailInterface;
}

interface State2 {
  mailParams: MailParamInterface[];
}

interface FormState {
  name: InputInterface;
  subject: InputInterface;
}

// @ts-ignore
const MailParamComponent: React.FC<State2> = React.memo(({ mailParams }) => {
  return mailParams.map((mailParam, indexMailParam) => (
    <Tooltip
      title={mailParam.description ?? ""}
      key={indexMailParam}
      placement="top"
    >
      <Box sx={{ paddingLeft: 1 }}>
        <CopyClipboardComponent
          className="RobotoMono"
          component="span"
          text={"@" + mailParam.identifier + "@"}
          placement="bottom"
        />
      </Box>
    </Tooltip>
  ));
});

const MailFormComponent: React.FC<State> = React.memo(({ mail }) => {
  const { t } = useTranslation();
  const refContent: any = useRef();
  const getDefaultValue = React.useCallback((): FormState => {
    return {
      name: { value: mail.name ?? "", error: "" },
      subject: { value: mail.subject ?? "", error: "" },
    };
  }, [mail.name, mail.subject]);
  const [values, setValues] = React.useState<FormState>(getDefaultValue());
  const getDefaultPreviewValue = React.useCallback((): any => {
    let result: any = {};
    for (const prop of ["Content", "Subject"]) {
      // @ts-ignore
      for (const mailParam of mail["mailParams" + prop]) {
        if (!result.hasOwnProperty(mailParam.identifier)) {
          result[mailParam.identifier] = {
            value: "",
            error: "",
            empty: mailParam.empty,
          };
        }
      }
    }
    return result;
  }, [mail]);
  const [previewValues, setPreviewValues] = React.useState<any>(
    getDefaultPreviewValue()
  );
  const [loading, setLoading] = React.useState(false);
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const [preview, setPreview] = React.useState<string | null>(null);

  const handleChange = React.useCallback(
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPreview(null);
      setValues((v) => {
        return {
          ...v,
          [prop]: { ...v[prop], value: event.target.value, error: "" },
        };
      });
    },
    []
  );

  const handleChangeCkEditor = React.useCallback(() => {
    setPreview(null);
  }, []);

  const handleChangePreview = React.useCallback(
    (prop: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPreview(null);
      setPreviewValues((v: any) => {
        return {
          ...v,
          [prop]: { ...v[prop], value: event.target.value, error: "" },
        };
      });
    },
    []
  );

  const getValue = React.useCallback(async () => {
    return {
      name: values.name.value,
      subject: values.subject.value,
      content: await refContent.current.getValue(),
    };
  }, [values.name.value, values.subject.value]);

  const previewToQuery = React.useCallback(async () => {
    const result: any = {
      mail: JSON.stringify(await getValue()),
    };
    for (const key in previewValues) {
      result[key] = previewValues[key].value;
    }
    return result;
  }, [getValue, previewValues]);

  const save = React.useCallback(async () => {
    setLoading(true);
    const response = await requestApi({
      method: PATCH,
      path: MAIL_URL + "/" + mail.id,
      allowError: true,
      token: token,
      body: await getValue(),
    });
    if (response.statusCode === 200) {
      toastr.success(t("word.success"), t("sentence.mailValidated"));
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [getValue, mail.id, t, token]);

  const handlePreview = React.useCallback(async () => {
    setPreview(objectToQuery(await previewToQuery()));
  }, [previewToQuery]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6} />
      <Grid item xs={12} md={6} sx={{ display: "flex", flexWrap: "wrap" }}>
        <MailParamComponent mailParams={mail.mailParamsSubject} />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          autoComplete="off"
          error={!!values.name.error}
          helperText={t(values.name.error ?? "")}
          sx={{ width: "100%" }}
          type="text"
          value={values.name.value}
          onChange={handleChange("name")}
          label={t("field.name")}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          autoComplete="off"
          error={!!values.subject.error}
          helperText={t(values.subject.error ?? "")}
          sx={{ width: "100%" }}
          type="text"
          value={values.subject.value}
          onChange={handleChange("subject")}
          label={t("field.subject")}
        />
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <MailParamComponent mailParams={mail.mailParamsContent} />
        </Box>
        <CkEditorComponent
          ref={refContent}
          text={mail.content}
          onChange={handleChangeCkEditor}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
        }}
      >
        <Box>
          {Object.keys(previewValues).map((field, indexField) => (
            <Box key={indexField} sx={{ paddingTop: 1 }}>
              <TextField
                autoComplete="off"
                error={!!previewValues[field].error}
                helperText={t(previewValues[field].error ?? "")}
                sx={{ width: "100%" }}
                type="text"
                disabled={previewValues[field].empty}
                value={previewValues[field].value}
                onChange={handleChangePreview(field)}
                label={field}
              />
            </Box>
          ))}
          <LoadingButton disabled={loading} onClick={handlePreview}>
            {t("word.preview")}
          </LoadingButton>
        </Box>
        <LoadingButton variant="contained" loading={loading} onClick={save}>
          {t("word.save")}
        </LoadingButton>
      </Grid>
      {preview && (
        <Grid item xs={12}>
          <iframe
            title="document"
            style={{ width: "100%", height: "91.7vh" }}
            src={
              window.origin +
              RAW_HTML_PAGE +
              "?url=" +
              encodeURIComponent(
                MAIL_PREVIEW_URL.replace("{id}", mail.id.toString()) + preview
              )
            }
          />
        </Grid>
      )}
    </Grid>
  );
});

export default MailFormComponent;
