import * as React from "react";
import { FArticleSmallInterface } from "../../../interfaces/FArticleInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import Typography from "@mui/material/Typography";
import { Card, CardContent, DialogProps, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DialogContent from "@mui/material/DialogContent";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH } from "../../../utils/MethodUtils";
import { FARTICLE_ICECAT_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import { IcecatListProductsComponent } from "./IcecatListProductsComponent";
import IconButton from "@mui/material/IconButton";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import { IcecatAskProductComponent } from "./IcecatAskProductComponent";
import Badge from "@mui/material/Badge";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  setFArticle: Function;
  refImages: any;
}

export interface IcecatImageInterface {
  url: string;
  selected: number | null;
}

export const IcecatFArticleComponent = React.memo(
  React.forwardRef(({ fArticle, setFArticle, refImages }: State, ref) => {
    const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
    const [maxWidth2] = React.useState<DialogProps["maxWidth"]>("lg");
    const [maxWidth3] = React.useState<DialogProps["maxWidth"]>("xl");
    const [maxWidth4] = React.useState<DialogProps["maxWidth"]>("xl");
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const [openDialog, setOpenDialog] = React.useState(false);
    const [openDialog2, setOpenDialog2] = React.useState(false);
    const [openDialog3, setOpenDialog3] = React.useState(false);
    const [openDialog4, setOpenDialog4] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [icecatImages, setIcecatImages] = React.useState<
      IcecatImageInterface[]
    >([]);

    const handleDialog = React.useCallback(() => {
      setOpenDialog((x) => !x);
    }, []);

    const handleDialog2 = React.useCallback(() => {
      setOpenDialog2((x) => !x);
    }, []);

    const handleDialog3 = React.useCallback(() => {
      setOpenDialog3((x) => !x);
    }, []);

    const handleDialog4 = React.useCallback(() => {
      setOpenDialog4((x) => !x);
    }, []);

    const isAdmin = useAppSelector(
      (state: RootState) => state.globalState.isAdmin
    );
    const { t, i18n } = useTranslation();

    const updateIcecat = React.useCallback(
      async (icecatId: number | null = null, notFound = false) => {
        if (!fArticle?.fArticleProp?.icecatId && !icecatId && !notFound) {
          handleDialog3();
          return;
        }
        setOpenDialog3(false);
        setLoading(true);
        const response = await requestApi({
          method: PATCH,
          path: FARTICLE_ICECAT_URL.replace(
            "{id}",
            fArticle?.fArticleProp?.id.toString() ?? ""
          ),
          allowError: true,
          token: token,
          body: {
            icecatNotFound: notFound,
            ...(icecatId && {
              icecatId: icecatId.toString(),
            }),
          },
        });
        if (response.statusCode === 200) {
          if (
            response.content.fArticle === null &&
            response.content.icecat === null
          ) {
            toastr.error(
              t("word.error"),
              t("sentence.notification.icecat_unavailable")
            );
            setLoading(false);
            return;
          }
          if (response.content.fArticle) {
            setFArticle(response.content.fArticle);
            toastr.success(
              t("word.success"),
              t("sentence.notification.icecat_updated")
            );
          }
          if (response.content.icecat) {
            let newImages =
              response.content.icecat?.data?.Gallery ??
              response.content.icecat?.Gallery ??
              response.content.icecat?.Product?.ProductGallery?.ProductPicture;
            if (newImages) {
              newImages = newImages.map((g: any, indexImage: number) => {
                let url = g?.Pic;
                if (!url && g.hasOwnProperty("@attributes")) {
                  url = g["@attributes"]?.Original;
                }
                return {
                  url: url,
                  selected: indexImage + 1,
                };
              });
              if (newImages) {
                setIcecatImages(newImages);
                if (newImages.length > 0) {
                  setOpenDialog2(true);
                }
              }
            }
          }
        } else if (response.statusCode === 401) {
          toastr.info(t("word.info"), t("error.reconnect"));
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
        setLoading(false);
      },
      [
        fArticle?.fArticleProp?.icecatId,
        fArticle?.fArticleProp?.id,
        handleDialog3,
        setFArticle,
        t,
        token,
      ]
    );

    const selectImage = React.useCallback((index: number) => {
      setIcecatImages((i) => {
        // @ts-ignore
        const allSelected: number[] = i
          .map((y) => y.selected)
          .filter((y) => y !== null);
        let maxSelected = 1;
        if (allSelected && allSelected.length > 0) {
          maxSelected = Math.max(...allSelected) + 1;
        }
        if (i[index].selected === null) {
          i[index].selected = maxSelected;
        } else {
          const oldSelected = i[index].selected;
          i[index].selected = null;
          i = i.map((y) => {
            if (
              y.selected !== null &&
              oldSelected !== null &&
              y.selected > oldSelected
            ) {
              y.selected--;
            }
            return y;
          });
        }
        return [...i];
      });
    }, []);

    const addImages = React.useCallback(() => {
      const imagesToAdd = icecatImages.filter((image) => image.selected);
      // @ts-ignore
      imagesToAdd.sort((a, b) => b.selected - a.selected);
      if (imagesToAdd) {
        refImages.current.addImages(imagesToAdd.map((image) => image.url));
      }
      setOpenDialog2(false);
    }, [icecatImages, refImages]);

    React.useEffect(() => {
      if (!openDialog2) {
        setIcecatImages([]);
      }
    }, [openDialog2]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {fArticle?.fArticleProp && isAdmin && (
          <>
            <Dialog
              maxWidth={maxWidth2}
              fullScreen={fullScreen}
              fullWidth={true}
              onClose={handleDialog2}
              open={openDialog2}
            >
              <DialogContent>
                <Grid container spacing={1}>
                  {icecatImages.map((image, indexImage) => (
                    <Grid item xs={12} md={3} key={indexImage}>
                      {image.selected !== null ? (
                        <Badge color="primary" badgeContent={image.selected}>
                          <img
                            src={image.url}
                            loading="lazy"
                            onClick={() => selectImage(indexImage)}
                            style={{
                              maxWidth: "100%",
                              margin: "auto",
                              display: "block",
                              cursor: "pointer",
                              border: "3px solid " + theme.palette.primary.main,
                              padding: 0,
                            }}
                            alt={indexImage.toString()}
                          />
                        </Badge>
                      ) : (
                        <img
                          src={image.url}
                          loading="lazy"
                          onClick={() => selectImage(indexImage)}
                          style={{
                            maxWidth: "100%",
                            margin: "auto",
                            display: "block",
                            border: "1px dashed gray",
                            padding: "2px",
                            cursor: "pointer",
                          }}
                          alt={indexImage.toString()}
                        />
                      )}
                    </Grid>
                  ))}
                </Grid>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "space-between" }}>
                <Button onClick={handleDialog2}>{t("word.cancel")}</Button>
                <LoadingButton variant="contained" onClick={addImages}>
                  {t("word.save")}
                </LoadingButton>
              </DialogActions>
            </Dialog>
            <Dialog
              maxWidth={maxWidth}
              fullScreen={fullScreen}
              fullWidth={true}
              onClose={handleDialog}
              open={openDialog}
            >
              <DialogContent>
                <pre>{fArticle.fArticleProp?.lastErrorIcecat}</pre>
              </DialogContent>
            </Dialog>
            <Dialog
              maxWidth={maxWidth3}
              fullScreen={fullScreen}
              fullWidth={true}
              onClose={handleDialog3}
              open={openDialog3}
            >
              <DialogContent>
                <IcecatListProductsComponent
                  fArticle={fArticle}
                  updateIcecat={updateIcecat}
                />
              </DialogContent>
            </Dialog>
            <Dialog
              maxWidth={maxWidth4}
              fullScreen={fullScreen}
              fullWidth={true}
              onClose={handleDialog4}
              open={openDialog4}
            >
              <DialogContent>
                <IcecatAskProductComponent
                  fArticle={fArticle}
                  closeDialog={handleDialog4}
                  setFArticle={setFArticle}
                />
              </DialogContent>
            </Dialog>
            <Card variant="outlined">
              <CardContent>
                <Typography>Icecat</Typography>
                <Divider />
                {t("word.lastUpdate") + ": "}
                {fArticle.fArticleProp?.lastIcecatUpdate ? (
                  <>
                    <Typography component="span">
                      {new Date(
                        fArticle.fArticleProp?.lastIcecatUpdate
                      ).toLocaleDateString(i18n.language, {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        timeZone: "America/Cuiaba",
                      })}
                    </Typography>
                    {fArticle.fArticleProp?.dateAskIcecat && (
                      <Typography>
                        {t("word.dateAskedIcecat") + ": "}
                        {new Date(
                          fArticle.fArticleProp?.dateAskIcecat
                        ).toLocaleDateString(i18n.language, {
                          year: "numeric",
                          month: "numeric",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          timeZone: "America/Cuiaba",
                        })}
                      </Typography>
                    )}
                    {fArticle.fArticleProp?.icecatId ? (
                      <>
                        <Typography>
                          {t("word.free")}:{" "}
                          {fArticle.fArticleProp?.freeIcecat ? (
                            <DoneIcon color="success" />
                          ) : (
                            <CloseIcon color="error" />
                          )}
                        </Typography>
                      </>
                    ) : (
                      <LoadingButton
                        variant="outlined"
                        loading={false}
                        onClick={handleDialog4}
                      >
                        {t("word.askIcecat")}
                      </LoadingButton>
                    )}
                    {fArticle.fArticleProp?.lastErrorIcecat && (
                      <LoadingButton
                        variant="contained"
                        color="info"
                        loading={false}
                        onClick={handleDialog}
                      >
                        {t("word.seeError")}
                      </LoadingButton>
                    )}
                  </>
                ) : (
                  t("word.never")
                )}
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  onClick={() => updateIcecat()}
                >
                  {t("word.update")}
                </LoadingButton>
                {fArticle?.fArticleProp?.icecatId && (
                  <IconButton onClick={handleDialog3}>
                    <ViewModuleIcon />
                  </IconButton>
                )}
              </CardContent>
            </Card>
          </>
        )}
      </>
    );
  })
);
