import * as React from "react";
import { useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { set } from "../../app/globalSlice";
import { LoadingButton } from "@mui/lab";
import MenuIcon from "@mui/icons-material/Menu";
import RefreshIcon from "@mui/icons-material/Refresh";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as LogoNoveSvg } from "../../assets/logoNove.svg";
import Modal from "@mui/material/Modal";
import { useContainerDimensions } from "./useContainerDimensions";
import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import { HOME_PAGE, PRODUCT_PAGE, PRODUCTS_PAGE } from "../../utils/RouteUtils";
import Badge from "@mui/material/Badge";
import NotificationComponent from "./NotificationComponent";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import SearchFArticleComponent from "./fArticle/SearchFArticleComponent";
import {
  DriverInterface,
  FArticleSmallInterface,
} from "../../interfaces/FArticleInterface";
import { objectToQuery } from "../../helpers/RequestApi";
import ZoomComponent from "./ZoomComponent";

interface State {
  handleToggleSidebar: Function;
  openCartDrawer: Function;
}

const AppBarDashboard: React.FC<State> = React.memo(
  ({ handleToggleSidebar, openCartDrawer }) => {
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("md"));
    const refreshPage = useAppSelector(
      (state: RootState) => state.globalState.refreshPage
    );
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const notifications = useAppSelector(
      (state: RootState) => state.globalState.notifications
    );
    const accountingSituation = useAppSelector(
      (state: RootState) => state.globalState.accountingSituation
    );
    const headerText = useAppSelector(
      (state: RootState) => state.globalState.headerText
    );
    const searchRef: any = useRef();
    const componentRef = useRef();
    const headerTextRef = useRef();
    const { width } = useContainerDimensions(componentRef);
    const dimHeaderText = useContainerDimensions(headerTextRef);
    const [openSearch, setSearchOpen] = React.useState(false);
    const dispatch = useAppDispatch();
    const cart = useAppSelector((state: RootState) => state.globalState.cart);
    const navigate = useNavigate();
    const [widthHeaderText, setWidthHeaderText] = React.useState<
      number | undefined
    >(undefined);

    const handleSearchOpen = React.useCallback(() => {
      setSearchOpen(true);
    }, []);

    const handleSearchClose = React.useCallback(() => {
      setSearchOpen(false);
    }, []);

    const handleClickRefresh = React.useCallback(() => {
      dispatch(set({ refreshPage: true }));
    }, [dispatch]);

    const onSearchFArticles = React.useCallback(
      (result: FArticleSmallInterface | DriverInterface) => {
        // @ts-ignore
        if (result?.arRef) {
          // @ts-ignore
          navigate(PRODUCT_PAGE + "/" + result.fArticleProp?.slug, {
            state: result,
          });
        } else {
          // @ts-ignore
          navigate(PRODUCTS_PAGE + objectToQuery({ "brands[]": result.name }));
        }
      },
      [navigate]
    );

    const margin = 100;

    React.useEffect(() => {
      if (
        headerText !== "" &&
        dimHeaderText.width > 0 &&
        widthHeaderText === undefined
      ) {
        setWidthHeaderText(dimHeaderText.width);
      }
    }, [dimHeaderText.width]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
      if (openSearch) {
        setTimeout(() => {
          searchRef.current.focus();
          searchRef.current.select();
        });
      }
    }, [openSearch]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#605ca8",
            overflow: "hidden",
          }}
        >
          <Typography
            sx={{
              color: "yellow",
              textAlign: "center",
              ...(widthHeaderText &&
                widthHeaderText > width && {
                  animation:
                    "marquee " + widthHeaderText * 0.01 + "s linear infinite",
                  paddingLeft: margin + "px",
                  width: widthHeaderText - width + margin + "px", // width <p> - width.window + margin
                }),
              whiteSpace: "nowrap",
            }}
          >
            <Typography
              // @ts-ignore
              ref={headerTextRef}
              sx={{ fontSize: "1.5rem" }}
              component="span"
            >
              {headerText}
            </Typography>
          </Typography>
        </Box>
        <Box ref={componentRef} sx={{ zIndex: 2 }}>
          <AppBar position="static" color="transparent">
            <Toolbar>
              <Box sx={{ flex: 1, display: "flex", alignItems: "center" }}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  sx={{
                    paddingX: "12px",
                  }}
                  onClick={() => handleToggleSidebar()}
                >
                  <Badge
                    invisible={
                      !(
                        isSmall &&
                        accountingSituation &&
                        accountingSituation.sumCreditFdocentetes < 0
                      )
                    }
                    variant="dot"
                    color="error"
                  >
                    <MenuIcon fontSize={"large"} />
                  </Badge>
                </IconButton>
                {isSmall ? (
                  <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    onClick={() => handleSearchOpen()}
                  >
                    <SearchIcon fontSize={"large"} />
                  </IconButton>
                ) : (
                  <SearchFArticleComponent
                    onSuccess={onSearchFArticles}
                    largeArRef={true}
                  />
                )}
              </Box>
              <Box
                sx={{
                  flex: 0,
                  textAlign: "center",
                  ...(!isSmall && { marginX: 1 }),
                }}
              >
                <Link
                  style={{ textDecoration: "none", color: "inherit" }}
                  to={HOME_PAGE}
                >
                  <LogoNoveSvg
                    style={{
                      width: "110px",
                      paddingTop: "10px",
                      cursor: "pointer",
                    }}
                  />
                </Link>
                <Typography className="RobotoMono">
                  {user?.userIdentifier}
                </Typography>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  textAlign: "right",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {!isSmall && <ZoomComponent />}
                {notifications !== undefined && notifications.length > 0 && (
                  <NotificationComponent />
                )}
                <LoadingButton
                  variant="text"
                  color="inherit"
                  sx={{
                    borderRadius: "50%",
                    minWidth: "auto",
                    padding: "12px",
                  }}
                  loading={refreshPage}
                  onClick={handleClickRefresh}
                >
                  <RefreshIcon fontSize={"large"} />
                </LoadingButton>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  onClick={() => openCartDrawer()}
                >
                  <Badge
                    badgeContent={cart?.cartFArticles.length ?? 0}
                    color="primary"
                  >
                    <ShoppingCartIcon
                      fontSize={isSmall ? "inherit" : "large"}
                    />
                  </Badge>
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <Modal open={openSearch} onClose={handleSearchClose}>
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "11%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: width - 50,
                boxShadow: 24,
              }}
            >
              <SearchFArticleComponent
                ref={searchRef}
                largeArRef={true}
                onSuccess={onSearchFArticles}
                handleSearchClose={handleSearchClose}
              />
            </Box>
          </Modal>
        </Box>
      </>
    );
  }
);

export default AppBarDashboard;
