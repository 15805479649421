import React from "react";
import { FLotserieInterface } from "../../../interfaces/FLotserieInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { DEFAULT_DEPOT, getStyleInline } from "../../../utils/FArticleUtils";
import { FArticleSmallComponent } from "../fArticle/FArticleSmallComponent";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";
import { SavInterface } from "../../../interfaces/SavInterface";
import { ORIGIN_NOVE, ORIGIN_OUTSIDE } from "../../../utils/SavUtils";
import Box from "@mui/material/Box";
import { Card, CircularProgress, Link } from "@mui/material";
import { RAW_HTML_PAGE } from "../../../utils/RouteUtils";
import { SAV_USER_FDOCENTETE_URL } from "../../../utils/UrlsUtils";
import DrawerComponent from "../DrawerComponent";
import { requestApi } from "../../../helpers/RequestApi";
import { GET } from "../../../utils/MethodUtils";
import { FDocenteteLignePaginateInterface } from "../../../interfaces/FDocenteteInterface";
import BuildIcon from "@mui/icons-material/Build";
import { getUrlPdfDoPiece } from "../../../helpers/FDocenteteHelper";
import { isDesktop } from "react-device-detect";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

interface State {
  fLotseries: FLotserieInterface[];
  handleDrawerOpen: Function;
  asAdmin: boolean;
  search: string;
}

const SavSerialNumbersComponent: React.FC<State> = React.memo(
  ({ fLotseries, handleDrawerOpen, asAdmin, search }) => {
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const [styleInline] = React.useState<string>(getStyleInline());
    const { t, i18n } = useTranslation();
    const [openDrawer, setOpenDrawer] = React.useState("");
    const [loadingFDocentete, setLoadingFDocentete] = React.useState("");
    const getSav = React.useCallback(
      async (
        fLotserie: FLotserieInterface,
        searchFDocentete: string | null = null
      ): Promise<SavInterface | null> => {
        if (loadingFDocentete) {
          return null;
        }
        const isOwn =
          fLotserie.fDocligne?.fDocentete.doTiers.ctNum ===
          user?.userIdentifier;
        const fDoclignePaginate: FDocenteteLignePaginateInterface = {
          arRef: fLotserie.fArticle.arRef,
          dlDesign: fLotserie.fArticle.arDesign,
          dlQte: Number(fLotserie.fDocligne?.dlQte ?? 1),
          dlNo: Number(fLotserie.fDocligne?.dlNo ?? 0),
          images: null,
          lsNoserie: fLotserie.lsNoserie,
          slug: fLotserie.fArticle.fArticleProp?.slug,
          lineIndex: 1,
          farticlePropId: fLotserie.fArticle.fArticleProp?.id,
          deNo: DEFAULT_DEPOT,
        };
        const result: SavInterface = {
          state: null,
          stateProduct: null,
          userIdentifier: asAdmin ? "" : user?.userIdentifier,
          company: asAdmin ? "" : user?.ctIntitule,
          contact: asAdmin ? "" : user?.ctIntitule,
          phone: asAdmin ? "" : user?.ctTelephone,
          mail: asAdmin ? "" : user?.ctEmail,
          fDocenteteId: isOwn ? fLotserie.fDocligne?.fDocentete.doPiece : "",
          fDocenteteDate: isOwn ? fLotserie.fDocligne?.fDocentete.doDate : "",
          fArticleId: "",
          dlDesign: "",
          fArticleSerialNumber: isOwn ? "" : fLotserie.lsNoserie,
          reason: "",
          packageState: "",
          date: "",
          rma: "",
          expirationDate: "",
          origin: isOwn ? ORIGIN_NOVE : ORIGIN_OUTSIDE,
          dataLose: false,
          category: "",
          brand: "",
          model: "",
          ...(isOwn && {
            fDoclignePaginate: fDoclignePaginate,
          }),
        };
        if (searchFDocentete) {
          setLoadingFDocentete(searchFDocentete);
          const response = await requestApi({
            method: GET,
            path: SAV_USER_FDOCENTETE_URL.replace(
              "{doPiece}",
              searchFDocentete
            ),
            allowError: true,
            token: token,
            timeout: 30_000,
          });
          if (response.statusCode === 200) {
            result.origin = ORIGIN_NOVE;
            result.fDoclignePaginate = fDoclignePaginate;
            for (const key in response.content) {
              // @ts-ignore
              result[key] = response.content[key];
            }
          }
        }
        setLoadingFDocentete("");
        return result;
      },
      [
        asAdmin,
        loadingFDocentete,
        token,
        user?.ctEmail,
        user?.ctIntitule,
        user?.ctTelephone,
        user?.userIdentifier,
      ]
    );
    const handleOpen = React.useCallback((doPiece: string) => {
      setOpenDrawer(doPiece);
    }, []);
    const handleClose = React.useCallback(() => {
      setOpenDrawer("");
    }, []);

    return (
      <>
        <DrawerComponent
          open={openDrawer !== ""}
          onClose={handleClose}
          handleDrawerClose={handleClose}
          drawerwidth={window.innerWidth}
          content={
            <iframe
              title="document"
              style={{ width: "100%", height: "91.7vh" }}
              src={
                window.origin +
                RAW_HTML_PAGE +
                "?url=" +
                getUrlPdfDoPiece(openDrawer, !isDesktop)
              }
            />
          }
        />
        {fLotseries.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>
                      {t("column.fArticleSerialNumber")}
                    </StyledTableCell>
                    <StyledTableCell>{t("word.product")}</StyledTableCell>
                    {!asAdmin && (
                      <StyledTableCell>{t("word.boughtAt")}</StyledTableCell>
                    )}
                    {!asAdmin && <StyledTableCell />}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fLotseries.map((fLotserie, indexFLotserie) => {
                    const boughtByUser =
                      user &&
                      user.userIdentifier ===
                        fLotserie.fDocligne?.fDocentete.doTiers.ctNum;
                    const fDocentetes = fLotserie.fDocentetes
                      .split(",")
                      .map((fDocentete) => {
                        const temp = fDocentete.split("|");
                        return {
                          doPiece: temp[1],
                          doTiers: temp[0],
                        };
                      });
                    fDocentetes.sort((a, b) =>
                      a.doTiers.localeCompare(b.doTiers)
                    );
                    return (
                      <StyledTableRow key={indexFLotserie}>
                        <StyledTableCell>{fLotserie.lsNoserie}</StyledTableCell>
                        <StyledTableCell>
                          <FArticleSmallComponent
                            fArticles={[fLotserie.fArticle]}
                            nbColumn={1}
                            inline={true}
                            slider={false}
                            defaultStyleInline={styleInline}
                          />
                          {asAdmin && (
                            <>
                              <Typography>
                                {t("word.boughtBy") + ":"}
                              </Typography>
                              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                {fDocentetes.map(
                                  (fDocentete, indexFDocentete) => (
                                    <Card
                                      variant="outlined"
                                      key={indexFDocentete}
                                      sx={{ marginLeft: 1, marginBottom: 1 }}
                                    >
                                      <Typography>
                                        {fDocentete.doTiers}
                                        {loadingFDocentete ? (
                                          <CircularProgress
                                            size={16}
                                            sx={{ marginLeft: 1 }}
                                          />
                                        ) : (
                                          <Link
                                            sx={{ cursor: "pointer" }}
                                            onClick={() => {
                                              getSav(
                                                fLotserie,
                                                fDocentete.doPiece
                                              ).then((thisSav) => {
                                                if (thisSav) {
                                                  handleDrawerOpen(thisSav);
                                                }
                                              });
                                            }}
                                          >
                                            <BuildIcon
                                              sx={{ marginLeft: 1 }}
                                              fontSize="small"
                                            />
                                          </Link>
                                        )}
                                      </Typography>
                                      <Link
                                        sx={{ cursor: "pointer" }}
                                        onClick={() =>
                                          handleOpen(fDocentete.doPiece)
                                        }
                                      >
                                        <Typography>
                                          {fDocentete.doPiece}
                                        </Typography>
                                      </Link>
                                    </Card>
                                  )
                                )}
                              </Box>
                            </>
                          )}
                        </StyledTableCell>
                        {!asAdmin && (
                          <StyledTableCell>
                            {boughtByUser ? (
                              <>
                                <Typography>
                                  {fLotserie.fDocligne?.fDocentete.doPiece}
                                </Typography>
                                <Typography>
                                  {new Date(
                                    // @ts-ignore
                                    fLotserie.fDocligne?.fDocentete.doDate
                                  ).toLocaleString(i18n.language, {
                                    dateStyle: "long",
                                    timeZone: "Etc/UTC",
                                  })}
                                </Typography>
                              </>
                            ) : (
                              <Typography>{t("word.never")}</Typography>
                            )}
                          </StyledTableCell>
                        )}
                        {!asAdmin && (
                          <StyledTableCell>
                            <LoadingButton
                              variant="contained"
                              onClick={() => {
                                getSav(fLotserie).then((thisSav) => {
                                  if (thisSav) {
                                    handleDrawerOpen(thisSav);
                                  }
                                });
                              }}
                            >
                              {t("word.sav.create")}
                            </LoadingButton>
                          </StyledTableCell>
                        )}
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          search.length > 4 && <Typography>{t("word.noResult")}</Typography>
        )}
      </>
    );
  }
);

export default SavSerialNumbersComponent;
