const getErrorApi = (response: any): string[] => {
  if (
    Array.isArray(response?.violations?.violations) &&
    response.violations.violations.length > 0
  ) {
    return response.violations.violations.map((error: any) => {
      return error.title;
    });
  }
  if (Array.isArray(response?.violations) && response.violations.length > 0) {
    return response.violations.map((error: any) => {
      return error.message;
    });
  }
  if (response?.detail) {
    return [response.detail];
  }
  return ["error.tryAgain"];
};
export default getErrorApi;
