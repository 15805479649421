import React from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import { useTranslation } from "react-i18next";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET, PATCH } from "../../../utils/MethodUtils";
import { REVIEW_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import {
  Container,
  DialogProps,
  FormControlLabel,
  useTheme,
} from "@mui/material";
import LoginComponent from "../../../components/common/user/LoginComponent";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { useSearchParams } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import useMediaQuery from "@mui/material/useMediaQuery";
import CopyClipboardComponent from "../CopyClipboardComponent";
import { formatCkEditor } from "../../../helpers/CkEditorHelper";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { ReviewInterface } from "../../../interfaces/FArticleInterface";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import DialogContent from "@mui/material/DialogContent";

interface State {
  asAdmin: boolean;
}

interface State2 {
  initReview: ReviewInterface;
}

const ValidReviewComponent: React.FC<State2> = React.memo(({ initReview }) => {
  const [review, setReview] = React.useState<ReviewInterface>(initReview);
  const [loading, setLoading] = React.useState(false);
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const { t } = useTranslation();
  const newReview = useAppSelector(
    (state: RootState) => state.globalState.newReview
  );
  const dispatch = useAppDispatch();

  const switchValid = React.useCallback(async () => {
    setLoading(true);
    const response = await requestApi({
      method: PATCH,
      path: REVIEW_URL + "/" + review.id,
      allowError: false,
      token: token,
      body: {
        valid: !review.valid,
      },
    });
    if (response.statusCode === 200) {
      setReview(response.content);
      let newValue = newReview ?? 0;
      if (!review.valid) {
        newValue--;
      } else {
        newValue++;
      }
      dispatch(set({ newReview: newValue }));
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      toastr.error(t("word.error"), t("error.tryAgain"));
    }
    setLoading(false);
  }, [dispatch, newReview, review.id, review.valid, t, token]);

  return (
    <>
      <LoadingButton
        variant="text"
        color="inherit"
        sx={{
          borderRadius: "50%",
          minWidth: "auto",
          padding: "12px",
        }}
        loading={loading}
        onClick={switchValid}
      >
        {review.valid ? (
          <DoneIcon color={"success"} />
        ) : (
          <CloseIcon color={"error"} />
        )}
      </LoadingButton>
    </>
  );
});

const ValidFilterReviewComponent: React.FC = React.memo(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [validChecked, setValidChecked] = React.useState<string>(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    if (searchParamsObject.hasOwnProperty("valid")) {
      return searchParamsObject["valid"];
    }
    return "";
  });
  const { t } = useTranslation();

  const handleChangeCheckbox = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      const newValue = (event.target as HTMLInputElement).value;
      setValidChecked((x) => {
        if (newValue === x) {
          return "";
        }
        return newValue;
      });
    },
    []
  );

  React.useEffect(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    if (validChecked.length > 0) {
      searchParamsObject["valid"] = validChecked;
    } else if (searchParamsObject.hasOwnProperty("valid")) {
      delete searchParamsObject["valid"];
    }
    setSearchParams(searchParamsObject, {
      replace: true,
    });
  }, [validChecked]); // eslint-disable-line react-hooks/exhaustive-deps

  console.log(validChecked);
  return (
    <RadioGroup row value={validChecked}>
      <FormControlLabel
        value={"0"}
        control={<Radio onClick={handleChangeCheckbox} sx={{ paddingY: 0 }} />}
        label={t("column.valid") + "(" + t("word.no") + ")"}
      />
      <FormControlLabel
        value={"1"}
        control={<Radio onClick={handleChangeCheckbox} sx={{ paddingY: 0 }} />}
        label={t("column.valid") + "(" + t("word.yes") + ")"}
      />
    </RadioGroup>
  );
});

const ReviewsComponent: React.FC<State> = React.memo(({ asAdmin }) => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const theme = useTheme();
  const [maxWidth] = React.useState<DialogProps["maxWidth"]>("sm");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [defaultItemsPerPage] = React.useState(asAdmin ? 50 : 10);
  const [reviews, setReviews] = React.useState<ReviewInterface[] | undefined>(
    undefined
  );
  const [totalItems, setTotalItems] = React.useState(0);
  const [review, setReview] = React.useState<
    ReviewInterface | null | undefined
  >(undefined);
  const { t, i18n } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const handleDrawerOpen = React.useCallback(
    (thisContact: ReviewInterface | null) => {
      setReview(thisContact);
    },
    []
  );
  const handleDialogUnreadClose = React.useCallback(() => {
    setReview(undefined);
  }, []);
  const getColumns = React.useCallback((): GridColDef[] => {
    return [
      {
        field: "id",
        headerName: t("column.id"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "fArticleId",
        headerName: t("column.fArticleId"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row?.fArticleProp?.fArticleId ?? ""}
          />
        ),
      },
      {
        field: "userIdentifier",
        headerName: t("column.userIdentifier"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <CopyClipboardComponent
            className="RobotoMono"
            component="span"
            text={params.row.userIdentifier ?? ""}
          />
        ),
      },
      {
        field: "content",
        headerName: t("word.content"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <div
            dangerouslySetInnerHTML={{
              __html: formatCkEditor(params.row.content),
            }}
          />
        ),
      },
      {
        field: "valid",
        headerName: t("column.valid"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <ValidReviewComponent initReview={params.row} />
        ),
      },
      {
        field: "actions",
        headerName: t("column.actions"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <IconButton onClick={() => handleDrawerOpen(params.row)}>
              <VisibilityIcon />
            </IconButton>
          </>
        ),
      },
    ];
  }, [handleDrawerOpen, t]);
  const [columns] = React.useState<GridColDef[]>(getColumns());
  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (reviews !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (!searchParamsObject.hasOwnProperty("page")) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (!force) {
        let hasOrder = false;
        for (const [key] of Object.entries(searchParamsObject)) {
          if (key.startsWith("order")) {
            hasOrder = true;
            break;
          }
        }
        if (!hasOrder) {
          searchParamsObject["order[id]"] = "desc";
          hasChanged = true;
        }
      }
      if (hasChanged) {
        dispatch(set({ refreshPage: false }));
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path: REVIEW_URL + objectToQuery(searchParamsObject),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = Math.max(
          ...response.content["hydra:member"].map((o: ReviewInterface) => o.id)
        );
        const newReviews = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              id: maxId,
            };
          }),
          ...response.content["hydra:member"],
        ];
        setReviews(newReviews);
        setTotalItems(response.content["hydra:totalItems"]);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [
      defaultItemsPerPage,
      dispatch,
      reviews,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setReviews(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setReviews(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        let field = sort.field;
        if (field === "newMessage") {
          field = "newMessageUser";
          if (asAdmin) {
            field = "newMessageAdmin";
          }
        }
        searchParamsObject["order[" + field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [asAdmin, init, searchParams, setSearchParams]
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!user) {
      setSearchParams(
        {},
        {
          replace: true,
        }
      );
    }
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams, user?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <LoginComponent redirect={null} requireAdmin={asAdmin}>
        <Dialog
          maxWidth={maxWidth}
          fullScreen={fullScreen}
          onClose={handleDialogUnreadClose}
          open={!!review}
        >
          <DialogContent>
            <div
              dangerouslySetInnerHTML={{
                __html: formatCkEditor(review?.content),
              }}
            />
          </DialogContent>
        </Dialog>
        <Container sx={{ marginY: 2 }}>
          <ValidFilterReviewComponent />
          {searchParamsObject.itemsPerPage && searchParamsObject.page && (
            // https://mui.com/x/react-data-grid/components/#pagination
            <DataGrid
              initialState={{
                sorting: {
                  sortModel: getSortModel(),
                },
              }}
              loading={refreshPage}
              rows={refreshPage ? [] : reviews ?? []}
              onSortModelChange={onSortModelChange}
              sortingMode="server"
              page={Number(searchParamsObject.page) - 1}
              rowsPerPageOptions={[10, 25, 50]}
              pageSize={Number(searchParamsObject.itemsPerPage)}
              onPageSizeChange={onPageSizeChange}
              onPageChange={onPageChange}
              rowCount={totalItems}
              columns={columns}
              autoHeight={true}
              disableExtendRowFullWidth={true}
              localeText={getLocaleDataGrid(i18n.language)}
            />
          )}
        </Container>
      </LoginComponent>
    </>
  );
});

export default ReviewsComponent;
