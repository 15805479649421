import React from "react";
import Layout from "../components/common/Layout";
import { getTitle } from "../helpers/SearchParamHelper";
import NoveFournisseurComponent from "../components/common/noveFournisseur/NoveFournisseurComponent";
import { useLocation, useParams } from "react-router-dom";
import SingleNoveFournisseurComponent from "../components/common/noveFournisseur/SingleNoveFournisseurComponent";

const NoveFournisseurScreen: React.FC = React.memo(() => {
  const params = useParams();
  const { state }: any = useLocation();

  React.useEffect(() => {
    document.title = getTitle("noveFournisseur");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      {params.id ? (
        <SingleNoveFournisseurComponent
          initFournisseurFile={state}
          id={Number(params.id)}
        />
      ) : (
        <NoveFournisseurComponent />
      )}
    </Layout>
  );
});

export default NoveFournisseurScreen;
