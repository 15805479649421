import * as React from "react";
import { useRef } from "react";
import { LoadingButton } from "@mui/lab";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import FArticleArRefComponent from "./FArticleArRefComponent";
import { FArticleInterface } from "../../../interfaces/FArticleInterface";
import Typography from "@mui/material/Typography";
import { requestApi } from "../../../helpers/RequestApi";
import { POST } from "../../../utils/MethodUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../../helpers/GetErrorApi";
import { FARTICLE_CHANGE_AR_REF_URL } from "../../../utils/UrlsUtils";
import { PRODUCT_PAGE } from "../../../utils/RouteUtils";
import { useNavigate } from "react-router-dom";

interface State {
  fArticle: FArticleInterface | undefined;
  onCancel: any;
}

const DialogChangeArRefComponent: React.FC<State> = React.memo(
  ({ fArticle, onCancel }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const refArRef: any = useRef();
    const navigate = useNavigate();

    const save = React.useCallback(async () => {
      setLoading(true);
      const response = await requestApi({
        method: POST,
        path: FARTICLE_CHANGE_AR_REF_URL.replace(
          "{arRef}",
          fArticle?.arRef ?? ""
        ),
        allowError: true,
        token: token,
        body: {
          ...refArRef.current.getValue(),
        },
      });
      if (response.statusCode === 200 || response.statusCode === 201) {
        onCancel();
        navigate(PRODUCT_PAGE + "/" + response.content.arRef);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [fArticle?.arRef, navigate, onCancel, t, token]);

    return (
      <>
        <DialogContent>
          <Typography>{t("sentence.specifyNewArRef")}</Typography>
          <FArticleArRefComponent
            fArticle={undefined}
            createFrom={fArticle}
            ref={refArRef}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between" }}>
          <LoadingButton onClick={onCancel} disabled={loading}>
            {t("word.cancel")}
          </LoadingButton>
          <LoadingButton variant="contained" onClick={save} loading={loading}>
            {t("word.save")}
          </LoadingButton>
        </DialogActions>
      </>
    );
  }
);

export default DialogChangeArRefComponent;
