import * as React from "react";
import { useImperativeHandle } from "react";
import {
  FArticleSmallInterface,
  ValueConfigurationFArticleOptionInterface,
} from "../../../interfaces/FArticleInterface";
import Box from "@mui/material/Box";
import {
  getMaxQuantityCanBuy,
  getPriceHt,
  getPriceTtc,
} from "../../../helpers/FArticleHelper";
import { useTranslation } from "react-i18next";
import { InputInterface } from "../../../interfaces/InputInterface";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import FArticleQuantityComponent from "./FArticleQuantityComponent";
import Typography from "@mui/material/Typography";
import { DEFAULT_DEPOT, OCCASION_DEPOT } from "../../../utils/FArticleUtils";
import FArticleAddCardButtonComponent from "./FArticleAddCardButtonComponent";
import FArticleStockComponent from "./FArticleStockComponent";
import FArticleMaxQuantityComponent from "./FArticleMaxQuantityComponent";
import Divider from "@mui/material/Divider";
import { priceFormat } from "../../../utils/FormatUtils";
import { useTheme } from "@mui/material/styles";

interface State {
  fArticle: FArticleSmallInterface | undefined;
  valueOptions?: ValueConfigurationFArticleOptionInterface[];
  index?: number;
  defaultValue?: number;
  maxWithCart?: boolean;
  maxEqualMinWidth?: boolean;
  depot?: number;
  isExpedition: boolean | undefined;
  setQuantityParent?: Function;
  fullWidth?: boolean;
  setFArticle?: Function;
  edit?: boolean;
  refStock?: any;
}

interface FormState {
  quantity: InputInterface;
}

const FArticleFDepotComponent = React.memo(
  React.forwardRef(
    (
      {
        fArticle,
        valueOptions,
        index,
        defaultValue,
        maxWithCart,
        maxEqualMinWidth,
        depot,
        isExpedition,
        setQuantityParent,
        fullWidth,
        setFArticle,
        edit,
        refStock,
      }: State,
      ref
    ) => {
      const isAdmin = useAppSelector(
        (state: RootState) => state.globalState.isAdmin
      );
      const theme = useTheme();
      const [quantity, setQuantity] = React.useState<number | undefined>(
        undefined
      );
      const [maxQuantityCanBuy, setMaxQuantityCanBuy] = React.useState<
        number | undefined
      >(
        getMaxQuantityCanBuy(
          fArticle,
          isAdmin,
          valueOptions,
          maxWithCart,
          depot
        )
      );
      const cart = useAppSelector((state: RootState) => state.globalState.cart);
      const getDefaultQuantity = React.useCallback(() => {
        return maxQuantityCanBuy === 0
          ? "0"
          : defaultValue
          ? defaultValue.toString()
          : "1";
      }, [defaultValue, maxQuantityCanBuy]);
      const [values, setValues] = React.useState<FormState>({
        quantity: {
          value: getDefaultQuantity(),
          error: "",
        },
      });
      const { t, i18n } = useTranslation();

      const updateQuantityToMax = React.useCallback(() => {
        const newMax = getMaxQuantityCanBuy(
          fArticle,
          isAdmin,
          valueOptions,
          maxWithCart,
          depot
        );
        if (
          (newMax === undefined || newMax > 0) &&
          values.quantity.value === "0"
        ) {
          values.quantity.value = defaultValue ? defaultValue.toString() : "1";
        }
        setMaxQuantityCanBuy(newMax);
      }, [
        defaultValue,
        depot,
        fArticle,
        isAdmin,
        maxWithCart,
        valueOptions,
        values.quantity,
      ]);

      const updateQuantityValueWithMax = React.useCallback(() => {
        if (maxQuantityCanBuy === undefined) {
          return;
        }
        if (Number(values.quantity.value) > maxQuantityCanBuy) {
          setValues((x) => {
            return {
              ...x,
              quantity: {
                ...x.quantity,
                value: maxQuantityCanBuy.toString(),
                error: "",
              },
            };
          });
        }
      }, [maxQuantityCanBuy, values.quantity.value]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return Number(values.quantity.value);
        },
      }));

      React.useEffect(() => {
        updateQuantityValueWithMax();
      }, [maxQuantityCanBuy]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        setValues({
          quantity: {
            value: getDefaultQuantity(),
            error: "",
          },
        });
      }, [defaultValue]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        if (
          maxQuantityCanBuy !== undefined &&
          Number(values.quantity.value) > maxQuantityCanBuy
        ) {
          setValues((v) => {
            v.quantity.value = maxQuantityCanBuy;
            return { ...v };
          });
          return;
        }
        if (setQuantityParent) {
          if (index !== undefined) {
            setQuantityParent(Number(values.quantity.value), index);
          } else {
            setQuantityParent(Number(values.quantity.value));
          }
        }
      }, [values.quantity.value]); // eslint-disable-line react-hooks/exhaustive-deps

      React.useEffect(() => {
        updateQuantityToMax();
      }, [valueOptions, cart, fArticle]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <>
          {depot === OCCASION_DEPOT && (
            <>
              <Divider />
              <Typography sx={{ marginBottom: 1 }}>
                {t("sentence.availableOccasion")}
                <Typography
                  component="span"
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                  }}
                >
                  {priceFormat(
                    getPriceHt(fArticle, OCCASION_DEPOT),
                    i18n.language,
                    "EUR"
                  )}
                </Typography>
                HT,{" "}
                <Typography
                  component="span"
                  sx={{
                    color: theme.palette.primary.main,
                    fontWeight: "bold",
                  }}
                >
                  {priceFormat(
                    getPriceTtc(fArticle, OCCASION_DEPOT),
                    i18n.language,
                    "EUR"
                  )}{" "}
                </Typography>
                {"€ TTC"}
              </Typography>
            </>
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {!fArticle?.fArticleProp?.hasOption && (
              <>
                <FArticleQuantityComponent
                  fArticle={fArticle}
                  setQuantityParent={setQuantity}
                  maxEqualMinWidth={true}
                  depot={depot}
                  showMaxQuantityMessage={false}
                />
                <FArticleAddCardButtonComponent
                  fArticle={fArticle}
                  depot={depot ?? DEFAULT_DEPOT}
                  fullWidth={fullWidth}
                  valueOptions={valueOptions}
                  quantity={quantity}
                />
              </>
            )}
          </Box>
          <FArticleMaxQuantityComponent fArticle={fArticle} depot={depot} />
          <FArticleStockComponent
            fArticle={fArticle}
            setFArticle={setFArticle}
            edit={edit}
            small={false}
            depot={depot}
            ref={refStock}
          />
          {isExpedition && (
            <Typography>{t("sentence.cantBuyExpedition")}</Typography>
          )}
        </>
      );
    }
  )
);

export default FArticleFDepotComponent;
