import * as React from "react";
import { BadgeProps, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GetPostOrdersInterface } from "../../../interfaces/OrderInterface";
import { Link } from "react-router-dom";
import { PRODUCT_PAGE } from "../../../utils/RouteUtils";
import { getUrlFArticleImage } from "../../../helpers/FileHelper";
import { IMAGE_SIZE_LOGO, IMAGE_SIZE_SMALL } from "../../../utils/FileUtils";
import HideImageIcon from "@mui/icons-material/HideImage";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InfoIcon from "@mui/icons-material/Info";
import TableBody from "@mui/material/TableBody";
import { getQuantityDispo } from "../../../helpers/FArticleHelper";
import { getAcompteFArticle } from "../../../helpers/CartHelper";
import CartFArticleStatusComponent from "../cart/CartFArticleStatusComponent";
import { priceFormat } from "../../../utils/FormatUtils";
import TableContainer from "@mui/material/TableContainer";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Badge from "@mui/material/Badge";
import { useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { isUserEnCompte } from "../../../helpers/UserHelper";
import { DEFAULT_PERCENT_ACOMPTE } from "../../../utils/FArticleUtils";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}, &.${tableCellClasses.footer}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: -6,
  },
}));

interface State {
  postOrder: GetPostOrdersInterface;
}

const TablePostOrderComponent: React.FC<State> = React.memo(({ postOrder }) => {
  const { t, i18n } = useTranslation();
  const user = useAppSelector((state: RootState) => state.globalState.user);
  const userProp = useAppSelector(
    (state: RootState) => state.globalState.userProp
  );

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <StyledTableCell />
            <StyledTableCell>{t("field.arDesign")}</StyledTableCell>
            <StyledTableCell>{t("word.wHt")}</StyledTableCell>
            <StyledTableCell>{t("word.wTtc")}</StyledTableCell>
            <StyledTableCell>{t("word.quantity")}</StyledTableCell>
            <StyledTableCell>{t("word.totalHt")}</StyledTableCell>
            <StyledTableCell>{t("word.totalTtc")}</StyledTableCell>
            <StyledTableCell>{t("word.acompte")}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {postOrder.orderFArticles.map((orderFArticle) => {
            const quantityDispo = getQuantityDispo(
              orderFArticle.fArticle,
              undefined,
              orderFArticle.fDepot
            );
            const acompte = getAcompteFArticle(
              orderFArticle.fArticle,
              orderFArticle.quantity,
              user,
              userProp,
              orderFArticle.fDepot,
              null,
              isUserEnCompte(user),
              true
            );
            return (
              <StyledTableRow key={orderFArticle.fArticle.arRef}>
                <StyledTableCell sx={{ textAlign: "center" }}>
                  {orderFArticle.fArticle.fArticleProp?.images !== null &&
                  orderFArticle.fArticle.fArticleProp?.images.length > 0 ? (
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        textAlign: "center",
                      }}
                      to={
                        PRODUCT_PAGE +
                        "/" +
                        orderFArticle.fArticle.fArticleProp?.slug
                      }
                      state={orderFArticle.fArticle}
                    >
                      <img
                        src={
                          (process.env.REACT_APP_API_URL ?? "") +
                          getUrlFArticleImage(
                            orderFArticle.fArticle.arRef,
                            orderFArticle.fArticle.fArticleProp?.images[0],
                            IMAGE_SIZE_SMALL
                          )
                        }
                        style={{
                          margin: "auto",
                          width: IMAGE_SIZE_SMALL / 2,
                        }}
                        alt={
                          "[" +
                          orderFArticle.fArticle.arRef +
                          "] " +
                          orderFArticle.fArticle.arDesign
                        }
                        loading="lazy"
                      />
                    </Link>
                  ) : (
                    <Link
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                        textAlign: "center",
                      }}
                      to={
                        PRODUCT_PAGE +
                        "/" +
                        orderFArticle.fArticle.fArticleProp?.slug
                      }
                      state={orderFArticle.fArticle}
                    >
                      <HideImageIcon
                        sx={{
                          width: IMAGE_SIZE_LOGO,
                          height: IMAGE_SIZE_LOGO,
                        }}
                      />
                    </Link>
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {orderFArticle.fArticle.arDesign}
                  {orderFArticle.quantity > quantityDispo && (
                    <CartFArticleStatusComponent
                      quantity={orderFArticle.quantity}
                      quantityDispo={quantityDispo}
                      fArticle={orderFArticle.fArticle}
                    />
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {priceFormat(
                    orderFArticle.fArticle.priceDto.priceHt,
                    i18n.language,
                    "EUR"
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {priceFormat(
                    orderFArticle.fArticle.priceDto.priceTtc,
                    i18n.language,
                    "EUR"
                  )}
                </StyledTableCell>
                <StyledTableCell>{orderFArticle.quantity}</StyledTableCell>
                <StyledTableCell>
                  {priceFormat(
                    orderFArticle.fArticle.priceDto.priceHt *
                      orderFArticle.quantity,
                    i18n.language,
                    "EUR"
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {priceFormat(
                    orderFArticle.fArticle.priceDto.priceTtc *
                      orderFArticle.quantity,
                    i18n.language,
                    "EUR"
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {acompte > 0 ? (
                    <StyledBadge
                      badgeContent={
                        <Tooltip
                          disableFocusListener
                          title={t("sentence.acompte").replace(
                            "%percent%",
                            (
                              userProp?.percentAcompte ??
                              DEFAULT_PERCENT_ACOMPTE * 10
                            ).toString()
                          )}
                        >
                          <InfoIcon />
                        </Tooltip>
                      }
                    >
                      {priceFormat(acompte, i18n.language, "EUR")}
                    </StyledBadge>
                  ) : (
                    priceFormat(acompte, i18n.language, "EUR")
                  )}
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default TablePostOrderComponent;
