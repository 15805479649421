import React, { useRef } from "react";
import { SavInterface } from "../../interfaces/SavInterface";
import {
  Accordion,
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import SavFormClientComponent from "./client/SavFormClientComponent";
import SavFormProductComponent from "./product/SavFormProductComponent";
import SavFormServiceComponent from "./service/SavFormServiceComponent";
import { InputInterface } from "../../interfaces/InputInterface";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import ContentComponent from "../../components/content/ContentComponent";
import { Link } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import CkEditorComponent from "../../components/content/CkEditorComponent";
import checkedValidator from "../../helpers/validator/CheckedValidator";
import { requestApi } from "../../helpers/RequestApi";
import { GET, POST } from "../../utils/MethodUtils";
import { MAIL_URL, SAV_MAIL_URL, SAV_URL } from "../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import getErrorApi from "../../helpers/GetErrorApi";
import SavFormCommentsComponent from "./comments/SavFormCommentsComponent";
import SavFormNoveComponent from "./nove/SavFormNoveComponent";
import { UserInterface } from "../../interfaces/UserInterface";
import { ABOUT_PAGE, ADMIN_MAIL_PAGE } from "../../utils/RouteUtils";
import { STATE_PENDING_REQUEST } from "../../utils/SavUtils";
import { STORAGE_SAV_COMMENT } from "../../utils/StorageUtils";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import { set } from "../../app/globalSlice";
import SearchComponent from "../../components/common/SearchComponent";
import { MailInterface } from "../../interfaces/MailInterface";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import mailValidator from "../../helpers/validator/MailValidator";

interface State {
  sav?: SavInterface | null;
  successFunction: Function;
  asAdmin: boolean;
  setSav: Function;
}

interface State2 {
  sav: SavInterface;
  setSav: Function;
}

interface FormState {
  dataLose: InputInterface;
  cgv: InputInterface;
}

interface FormState2 {
  from: InputInterface;
  fDocentete: InputInterface;
  to: InputInterface;
  subject: InputInterface;
}

interface SavFDocenteteInterface {
  doPiece: string;
  doType: string;
  doTiers: string;
  doDate: string;
}

const SavFormMailComponent: React.FC<State2> = React.memo(({ sav, setSav }) => {
  const mails = useAppSelector((state: RootState) => state.globalState.mails);
  const [mail, setMail] = React.useState<MailInterface | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [savFDocentetes, setSavFDocentetes] = React.useState<
    SavFDocenteteInterface[]
  >([]);

  const getDefaultValue = React.useCallback((): FormState2 => {
    return {
      from: {
        value: "sav@nove.fr",
        error: "",
      },
      to: {
        value: "",
        error: "",
      },
      fDocentete: {
        value: "",
        error: "",
      },
      subject: {
        value: mail?.subject,
        error: "",
      },
    };
  }, [mail?.subject]);
  const [values, setValues] = React.useState<FormState2>(getDefaultValue());

  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const ckEditorRef: any = useRef();

  const load = React.useCallback(async () => {
    if (!mails) {
      const response = await requestApi({
        method: GET,
        path: MAIL_URL,
        allowError: true,
        token: token,
      });
      if (response.statusCode === 200) {
        dispatch(set({ mails: response.content }));
        setMail(
          response.content.find(
            (m: MailInterface) => m.identifier === "DEMANDE_SAV"
          )
        );
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
    } else {
      setMail(mails?.find((m) => m.identifier === "DEMANDE_SAV"));
    }
  }, [dispatch, mails, t, token]);

  const getFDocentetes = React.useCallback(async () => {
    if (!mail) {
      return;
    }
    const response = await requestApi({
      method: GET,
      path: SAV_URL + "/" + sav.id + "/f_docentetes",
      allowError: true,
      token: token,
    });
    if (response.statusCode === 200) {
      setSavFDocentetes(response.content);
      if (response.content.length > 0) {
        setValues((v) => {
          v.fDocentete.value = response.content[0].doPiece;
          return { ...v };
        });
      } else {
        setValues((v) => {
          v.fDocentete.value = "";
          return { ...v };
        });
      }
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      toastr.error(t("word.error"), t("error.tryAgain"));
    }
  }, [mail, sav.id, t, token]);

  const handleChangeSelect = React.useCallback(
    (prop: keyof FormState2) => (event: SelectChangeEvent) => {
      setValues((v) => {
        return {
          ...v,
          [prop]: {
            ...v[prop],
            value: event.target.value as string,
            error: "",
          },
        };
      });
    },
    []
  );

  const handleChange = React.useCallback(
    (prop: keyof FormState2) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => {
        return {
          ...v,
          [prop]: { ...v[prop], value: event.target.value, error: "" },
        };
      });
    },
    []
  );

  const save = React.useCallback(async () => {
    const toError = mailValidator(values.to.value);
    const fromError = mailValidator(values.from.value);
    if (toError || fromError) {
      const newValue: FormState2 = { ...values };
      if (toError) {
        newValue.to.error = toError;
      }
      if (fromError) {
        newValue.from.error = fromError;
      }
      setValues(newValue);
      return undefined;
    }
    setLoading(true);
    const response = await requestApi({
      method: POST,
      path: SAV_MAIL_URL,
      allowError: true,
      token: token,
      body: {
        subject: values.subject.value,
        fDocenteteDoPiece: values.fDocentete.value,
        from: values.from.value,
        to: values.to.value,
        content: ckEditorRef.current.getValue(),
        sav: SAV_URL + "/" + sav.id,
      },
    });
    if (response.statusCode === 201) {
      setSav((s: SavInterface) => {
        s.savMails?.unshift(response.content);
        return { ...s };
      });
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      toastr.error(t("word.error"), t("error.tryAgain"));
    }
    setLoading(false);
  }, [sav.id, setSav, t, token, values]);

  React.useEffect(() => {
    load();
  }, [sav]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (mail) {
      setValues(getDefaultValue());
      getFDocentetes();
    }
  }, [mail]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {sav.savMails?.map((savMail, indexSavMail) => (
        <Alert key={indexSavMail} severity="info" sx={{ marginTop: 2 }}>
          {t("word.mailSendedOn") + " "}
          {new Date(savMail.created).toLocaleString(i18n.language, {
            dateStyle: "long",
            timeStyle: "medium",
            timeZone: "America/Cuiaba",
          })}{" "}
          <Link
            to={ADMIN_MAIL_PAGE + "?tab=1&search=" + savMail.mailSendHistory.id}
          >
            {savMail.mailSendHistory.id}
          </Link>{" "}
        </Alert>
      ))}
      <Accordion sx={{ marginTop: 2 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
          sx={{ backgroundColor: theme.palette.primary.main }}
        >
          <Typography component="p" variant="h6" sx={{ color: "white" }}>
            {t("word.sendMailSav")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {!mail ? (
            <>
              <SearchComponent nbColumn={1} nbLines={1} />
            </>
          ) : (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="off"
                    error={!!values.subject.error}
                    helperText={t(values.subject.error ?? "")}
                    sx={{ width: "100%" }}
                    type="text"
                    value={values.subject.value}
                    onChange={handleChange("subject")}
                    label={t("field.subject")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="fdocentete">
                      {t("word.documentAchat")}
                    </InputLabel>
                    <Select
                      labelId="fdocentete"
                      value={values.fDocentete.value ?? ""}
                      label={t("word.documentAchat")}
                      onChange={handleChangeSelect("fDocentete")}
                    >
                      <MenuItem value="" disabled={true}>
                        <em>{t("word.none")}</em>
                      </MenuItem>
                      {savFDocentetes.map(
                        (savFDocentete, indexSavFDocentete) => (
                          <MenuItem
                            value={savFDocentete.doPiece}
                            key={indexSavFDocentete}
                          >
                            {"[" +
                              savFDocentete.doPiece +
                              "] " +
                              savFDocentete.doTiers +
                              " " +
                              new Date(savFDocentete.doDate).toLocaleDateString(
                                i18n.language,
                                {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  timeZone: "America/Cuiaba",
                                }
                              )}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    autoComplete="off"
                    error={!!values.from.error}
                    helperText={t(values.from.error ?? "")}
                    sx={{ width: "100%" }}
                    type="text"
                    value={values.from.value}
                    onChange={handleChange("from")}
                    label={t("field.from")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    autoComplete="off"
                    error={!!values.to.error}
                    helperText={t(values.to.error ?? "")}
                    sx={{ width: "100%" }}
                    type="text"
                    value={values.to.value}
                    onChange={handleChange("to")}
                    label={t("field.to")}
                  />
                </Grid>
              </Grid>
              <CkEditorComponent ref={ckEditorRef} text={mail.content} />
              <Box sx={{ textAlign: "right", marginTop: 2 }}>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  onClick={save}
                >
                  {t("word.save")}
                </LoadingButton>
              </Box>
            </>
          )}
        </AccordionDetails>
      </Accordion>
    </>
  );
});

const SavFormComponent: React.FC<State> = React.memo(
  ({ sav, setSav, successFunction, asAdmin }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const identificationRef: any = useRef();
    const productRef: any = useRef();
    const serviceRef: any = useRef();
    const reservedNoveRef: any = useRef();
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const [loading, setLoading] = React.useState(false);
    const [userForm, setUserForm] = React.useState<UserInterface | undefined>(
      undefined
    );
    const ckEditorRef: any = useRef();
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const getDefaultValue = React.useCallback((): FormState => {
      return {
        dataLose: {
          value: sav?.dataLose ?? false,
          error: "",
        },
        cgv: {
          value: !!sav?.id,
          error: "",
        },
      };
    }, [sav]);
    const [values, setValues] = React.useState<FormState>(getDefaultValue());

    const save = React.useCallback(async () => {
      setLoading(true);
      const identificationValue = identificationRef.current.getValue();
      const productValue = productRef.current.getValue();
      const serviceValue = serviceRef.current.getValue();
      const cgvError = checkedValidator(values.cgv.value);
      if (cgvError || !identificationValue || !productValue || !serviceValue) {
        const newValue: FormState = { ...values };
        if (cgvError) {
          newValue.cgv.error = cgvError;
        }
        setValues(newValue);
        setLoading(false);
        return undefined;
      }
      const ckEditorValue = ckEditorRef.current.getValue();
      const savComments = [];
      if (ckEditorValue) {
        savComments.push({
          text: ckEditorValue,
          private: false,
        });
      }
      const reservedNoveValue = reservedNoveRef?.current?.getValue();
      const postSav = {
        userIdentifier: identificationValue.userIdentifier,
        company: identificationValue.company,
        contact: identificationValue.contact,
        phone: identificationValue.phone,
        mail: identificationValue.mail,
        fDocenteteId: productValue.fDocenteteId ?? null,
        fArticleId: productValue.fArticleId ?? null,
        fArticleSerialNumber: productValue.fArticleSerialNumber ?? null,
        reason: serviceValue.reason,
        state: reservedNoveValue?.state ?? STATE_PENDING_REQUEST,
        packageState: serviceValue.packageState,
        origin: productValue.origin,
        dataLose: values.dataLose.value,
        category: productValue.category ?? null,
        brand: productValue.brand ?? null,
        model: productValue.model ?? null,
        savComments: savComments,
      };
      const response = await requestApi({
        method: POST,
        path: SAV_URL,
        allowError: true,
        token: token,
        body: postSav,
      });
      if (response.statusCode === 201) {
        toastr.success(
          t("word.success"),
          t("sentence.notification.sav_created")
        );
        successFunction(response.content);
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [successFunction, t, token, values]);

    const handleChangeCheckbox = React.useCallback(
      (prop: keyof FormState) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setValues((v) => {
            return {
              ...v,
              [prop]: { ...v[prop], value: event.target.checked, error: "" },
            };
          });
        },
      []
    );

    React.useEffect(() => {
      setValues(getDefaultValue());
    }, [user, asAdmin, sav]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        <Grid container spacing={1} sx={{ marginBottom: 2 }}>
          {sav?.id && (
            <Grid item xs={12}>
              <Typography
                sx={{ color: theme.palette.primary.main, textAlign: "right" }}
              >
                {t("word.sav.number") + ": " + sav.id}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography
              component="p"
              variant="h6"
              sx={{ color: theme.palette.primary.main }}
            >
              {t("word.sav.clientIdentification")}
            </Typography>
          </Grid>
          <SavFormClientComponent
            sav={sav}
            ref={identificationRef}
            asAdmin={asAdmin}
            setUserForm={setUserForm}
          />
          <Grid item xs={12}>
            <Typography
              component="p"
              variant="h6"
              sx={{ color: theme.palette.primary.main }}
            >
              {t("word.sav.productIdentification")}
            </Typography>
          </Grid>
          <SavFormProductComponent
            sav={sav}
            ref={productRef}
            userForm={userForm}
            setSav={setSav}
          />
          <Grid item xs={12}>
            <Typography
              component="p"
              variant="h6"
              sx={{ color: theme.palette.primary.main }}
            >
              {t("word.sav.serviceIdentification")}
            </Typography>
          </Grid>
          <SavFormServiceComponent sav={sav} ref={serviceRef} />
          <Grid item xs={12}>
            {!sav?.id && (
              <>
                <Typography
                  component="p"
                  variant="h6"
                  sx={{ color: theme.palette.primary.main }}
                >
                  {t("word.comment")}
                </Typography>
                <CkEditorComponent
                  ref={ckEditorRef}
                  text={""}
                  small={true}
                  idLocaleStorage={STORAGE_SAV_COMMENT + sav?.id}
                />
              </>
            )}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={!!sav?.id}
                    checked={values.dataLose.value}
                    onChange={handleChangeCheckbox("dataLose")}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={t("field.dataLose")}
              />
            </FormGroup>
            {values.dataLose.value && (
              <ContentComponent
                contentName="dataLose"
                saveLocalStorage={true}
              />
            )}
            <FormControl error={!!values.cgv.error}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={!!sav?.id}
                      checked={values.cgv.value}
                      onChange={handleChangeCheckbox("cgv")}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={
                    <Link to={ABOUT_PAGE + "?tab=cgv"} target="_blank">
                      {t("sentence.agreeCvg")}
                    </Link>
                  }
                />
                {!!values.cgv.error && (
                  <FormHelperText error>
                    {t(values.cgv.error ?? "")}
                  </FormHelperText>
                )}
              </FormGroup>
            </FormControl>
            <ContentComponent contentName="savForm" saveLocalStorage={true} />
          </Grid>
        </Grid>
        {!sav?.id ? (
          <>
            {asAdmin && (
              <Box sx={{ marginBottom: 1 }}>
                <SavFormNoveComponent
                  sav={sav}
                  asAdmin={asAdmin}
                  ref={reservedNoveRef}
                />
              </Box>
            )}
            <Box sx={{ textAlign: "right" }}>
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={save}
              >
                {t("word.saveSav")}
              </LoadingButton>
            </Box>
          </>
        ) : (
          <SavFormCommentsComponent
            sav={sav}
            asAdmin={asAdmin}
            refFormSav={reservedNoveRef}
            setSav={setSav}
          />
        )}
        {sav && <SavFormMailComponent sav={sav} setSav={setSav} />}
      </>
    );
  }
);

export default SavFormComponent;
