import React from "react";
import Box from "@mui/material/Box";
import { Card, CardContent } from "@mui/material";
import CopyClipboardComponent from "../CopyClipboardComponent";
import { FournisseurFieldMappingInterface } from "../../../interfaces/NoveFournisseurInterface";
import { FOURNISSEUR_FIELD_MAPPING_FIELD } from "../../../utils/NoveFournisseurUtils";
import { isObject } from "../../../helpers/FileHelper";

interface State {
  object: any;
  path?: string;
  setSelectedPath: Function;
  thisFournisseurFieldMappings: FournisseurFieldMappingInterface[];
}

const JsonDisplayerComponent = React.memo<State>(
  ({ object, path, setSelectedPath, thisFournisseurFieldMappings }) => {
    return (
      <>
        <Card variant="outlined">
          <CardContent sx={{ padding: 1 }}>
            {Object.keys(object).map((key) => {
              const currentPath = "." + (path ?? "") + key;
              const fournisseurFieldMapping = thisFournisseurFieldMappings.find(
                (f) => f.path === currentPath
              );
              return (
                <Box key={key}>
                  {isObject(object[key]) ? (
                    <>
                      <>{key + ": "}</>
                      <JsonDisplayerComponent
                        object={object[key]}
                        setSelectedPath={setSelectedPath}
                        path={currentPath}
                        thisFournisseurFieldMappings={
                          thisFournisseurFieldMappings
                        }
                      />
                    </>
                  ) : Array.isArray(object[key]) ? (
                    <>
                      <>{key + ": "}</>
                      <Card variant="outlined">
                        <CardContent sx={{ padding: 1 }}>
                          {object[key].map((o: any, i: number) => (
                            <JsonDisplayerComponent
                              key={i}
                              object={o}
                              setSelectedPath={setSelectedPath}
                              path={currentPath + "[" + i + "]"}
                              thisFournisseurFieldMappings={
                                thisFournisseurFieldMappings
                              }
                            />
                          ))}
                        </CardContent>
                      </Card>
                    </>
                  ) : (
                    <Box sx={{ display: "flex" }}>
                      {key !== "." && (
                        <CopyClipboardComponent
                          className="RobotoMono"
                          component="span"
                          text={key + ": " + object[key]}
                          afterCopy={() => {
                            setSelectedPath(currentPath);
                          }}
                        />
                      )}
                      {fournisseurFieldMapping && (
                        <Card
                          variant="outlined"
                          sx={{
                            borderColor: FOURNISSEUR_FIELD_MAPPING_FIELD.find(
                              (x) => x.prop === fournisseurFieldMapping.field
                            )?.color,
                          }}
                        >
                          <CardContent
                            sx={{
                              padding: "0!important",
                              margin: "0!important",
                            }}
                          >
                            {fournisseurFieldMapping.field}
                          </CardContent>
                        </Card>
                      )}
                    </Box>
                  )}
                </Box>
              );
            })}
          </CardContent>
        </Card>
      </>
    );
  }
);

export default JsonDisplayerComponent;
