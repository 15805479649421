import React, { useEffect } from "react";
import { Chip, useMediaQuery, useTheme } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getTitle, searchParamToObject } from "../../helpers/SearchParamHelper";
import Layout from "../../components/common/Layout";
import LoginComponent from "../../components/common/user/LoginComponent";
import TabPanelComponent from "../../components/common/TabPanelComponent";
import FeedbackComponent from "../../components/common/feedback/FeedbackComponent";
import { useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import ReviewsComponent from "../../components/common/feedback/ReviewsComponent";

const AdminFeedbackScreen: React.FC = React.memo(() => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const newMessageAdmin = useAppSelector(
    (state: RootState) => state.globalState.newMessageAdmin
  );
  const newReview = useAppSelector(
    (state: RootState) => state.globalState.newReview
  );
  const getValue = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    return searchParamsObject.tab ? Number(searchParamsObject.tab) : 0;
  }, [searchParams]);
  const [value, setValue] = React.useState(getValue());
  const handleChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      // @ts-ignore
      setSearchParams({
        tab: newValue,
      });
    },
    [setSearchParams]
  );

  useEffect(() => {
    document.title = getTitle("feedback");
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setValue(getValue());
  }, [searchParams]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Layout>
      <LoginComponent redirect={null} requireAdmin={true}>
        <Tabs
          value={value}
          onChange={handleChange}
          scrollButtons="auto"
          variant={!isSmall ? "standard" : "scrollable"}
          centered={!isSmall}
        >
          <Tab
            label={
              <>
                {t("title.feedback")}
                {newMessageAdmin !== undefined && newMessageAdmin > 0 && (
                  <Chip
                    label={newMessageAdmin ?? 0}
                    variant="outlined"
                    color="primary"
                    sx={{ marginLeft: 1, cursor: "pointer" }}
                  />
                )}
              </>
            }
          />
          <Tab
            label={
              <>
                {t("word.customerReviews")}
                {newReview !== undefined && newReview > 0 && (
                  <Chip
                    label={newReview ?? 0}
                    variant="outlined"
                    color="info"
                    sx={{ marginLeft: 1, cursor: "pointer" }}
                  />
                )}
              </>
            }
          />
        </Tabs>
        <TabPanelComponent
          sx={{ marginTop: 1 }}
          value={value}
          index={0}
          removeComponentOnHidden={true}
        >
          <FeedbackComponent asAdmin={true} />
        </TabPanelComponent>
        <TabPanelComponent
          sx={{ marginTop: 1 }}
          value={value}
          index={1}
          removeComponentOnHidden={true}
        >
          <ReviewsComponent asAdmin={true} />
        </TabPanelComponent>
      </LoginComponent>
    </Layout>
  );
});

export default AdminFeedbackScreen;
