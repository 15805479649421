import * as React from "react";
import {
  FDocenteteInterface,
  OpenDialogOrderInterface,
} from "../../../interfaces/FDocenteteInterface";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import DialogOrderComponent from "./DialogOrderComponent";
import { OrderInterface } from "../../../interfaces/OrderInterface";
import { requestApi } from "../../../helpers/RequestApi";
import { GET, PATCH } from "../../../utils/MethodUtils";
import {
  BON_DE_COMMANDE_URL,
  CART_URL,
  FDOCENTETES_URL,
} from "../../../utils/UrlsUtils";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import {
  bonDeCommandeIsValidated,
  displayTransform,
  getOrderWithDocentete,
  paiementDone,
} from "../../../helpers/FDocenteteHelper";
import { SAGE_DO_TYPE_VENTE_FA } from "../../../utils/DoceneteteUtils";
import Box from "@mui/material/Box";
import DialogEditLinkedFileComponent from "../fdocentete/DialogEditLinkedFileComponent";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@mui/material";
import { set, setCart } from "../../../app/globalSlice";

interface State {
  fDocentete: FDocenteteInterface;
  withBonCommande: boolean;
}

const FDocenteteTransformComponent: React.FC<State> = React.memo(
  ({ fDocentete, withBonCommande }) => {
    const { t } = useTranslation();
    const [order, setOrder] = React.useState<OrderInterface | undefined>(
      undefined
    );
    const [openDialog, setOpenDialog] =
      React.useState<OpenDialogOrderInterface>({
        open: false,
        devis: false,
      });
    const [openDialog2, setOpenDialog2] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState(false);
    const [loading2, setLoading2] = React.useState(false);
    const [loadingDownload, setLoadingDownload] = React.useState(false);
    const token = useAppSelector((state: RootState) => state.globalState.token);
    const user = useAppSelector((state: RootState) => state.globalState.user);
    const cart = useAppSelector((state: RootState) => state.globalState.cart);
    const dispatch = useAppDispatch();

    const updateOrder = React.useCallback(async () => {
      setLoading(true);
      const response = await requestApi({
        method: GET,
        path: FDOCENTETES_URL + "/" + fDocentete.doPiece,
        allowError: true,
        token: token,
        timeout: 30_000,
      });
      if (response.statusCode === 200) {
        setOrder(getOrderWithDocentete(response.content, user, false));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
      setLoading(false);
    }, [fDocentete, t, token, user]);

    const downloadBonCommande = React.useCallback(async () => {
      setLoadingDownload(true);
      await requestApi({
        method: GET,
        path: BON_DE_COMMANDE_URL.replace("{id}", fDocentete.doPiece),
        allowError: true,
        token: token,
        blob: true,
      });
      setLoadingDownload(false);
    }, [fDocentete.doPiece, token]);

    const onTransform = React.useCallback(async () => {
      await updateOrder();
    }, [updateOrder]);

    const editDevis = React.useCallback(async () => {
      setLoading2(true);
      if (cart?.id && fDocentete.fDoclignesPaginate) {
        const response = await requestApi({
          method: PATCH,
          path: CART_URL + "/" + cart.id,
          allowError: true,
          token: token,
          body: {
            cartFArticles: fDocentete.fDoclignesPaginate.map(
              (fDoclignesPaginate) => {
                return {
                  quantity: Number(fDoclignesPaginate.dlQte),
                  fArticleId: fDoclignesPaginate.arRef,
                  depot: Number(fDoclignesPaginate.deNo),
                };
              }
            ),
          },
        });
        if (response.statusCode === 200) {
          if (response.content.cartFArticles.length > 0) {
            dispatch(setCart(response.content));
            dispatch(
              set({
                openCart: true,
                editingFDocentete: fDocentete.doPiece,
              })
            );
          } else {
            toastr.error(t("word.error"), t("sentence.cannotEditDevis"));
          }
        } else {
          for (let message of getErrorApi(response.content)) {
            toastr.error(t("word.error"), t(message));
          }
        }
      }
      setLoading2(false);
    }, [
      cart?.id,
      dispatch,
      fDocentete.doPiece,
      fDocentete.fDoclignesPaginate,
      t,
      token,
    ]);

    React.useEffect(() => {
      if (order) {
        setOpenDialog((x) => {
          x.open = true;
          return { ...x };
        });
      }
    }, [order]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {displayTransform(fDocentete, user) ? (
          <>
            <LoadingButton
              variant="contained"
              onClick={onTransform}
              loading={loading}
            >
              {t("sentence.transformDevis")}
            </LoadingButton>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                marginTop: 1,
              }}
            >
              <LoadingButton
                variant="contained"
                color="info"
                loading={loading2}
                onClick={editDevis}
              >
                {t("word.editDevis")}
              </LoadingButton>
              <Tooltip title={t("sentence.editDevis")}>
                <InfoIcon />
              </Tooltip>
            </Box>
            <DialogOrderComponent
              withBonCommande={withBonCommande}
              order={order}
              fDocentete={fDocentete}
              openDialog={openDialog.open}
              devis={openDialog.devis}
              setOpenDialog={setOpenDialog}
            />
          </>
        ) : (
          <>
            {withBonCommande && (
              <>
                {fDocentete.fDocentetemedias.length > 0 && (
                  <Box>
                    <LoadingButton
                      variant="contained"
                      loading={loadingDownload}
                      onClick={downloadBonCommande}
                    >
                      {t("word.bonCommande.see")}
                    </LoadingButton>
                  </Box>
                )}
                {!paiementDone(fDocentete) &&
                  !bonDeCommandeIsValidated(fDocentete, user) &&
                  fDocentete.doType < SAGE_DO_TYPE_VENTE_FA && (
                    <Box>
                      <DialogEditLinkedFileComponent
                        fDocentete={fDocentete}
                        openDialog={openDialog2}
                        setOpenDialog={setOpenDialog2}
                      />
                      <LoadingButton
                        onClick={() => {
                          setOpenDialog2(true);
                        }}
                      >
                        {t("word.bonCommande.edit")}
                      </LoadingButton>
                    </Box>
                  )}
              </>
            )}
          </>
        )}
      </>
    );
  }
);

export default FDocenteteTransformComponent;
