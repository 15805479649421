import React, { useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { set } from "../../../app/globalSlice";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSelectionModel,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { getLocaleDataGrid } from "../../../helpers/GetLanguage";
import { objectToQuery, requestApi } from "../../../helpers/RequestApi";
import { GET, POST } from "../../../utils/MethodUtils";
import { FOURNISSEUR_FILE_URL } from "../../../utils/UrlsUtils";
import { toastr } from "react-redux-toastr";
import {
  Box,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Select,
} from "@mui/material";
import LoginComponent from "../../../components/common/user/LoginComponent";
import { GridSortModel } from "@mui/x-data-grid/models/gridSortModel";
import { searchParamToObject } from "../../../helpers/SearchParamHelper";
import { Link, useSearchParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { ADMIN_NOVE_FOURNISSEUR_PAGE } from "../../../utils/RouteUtils";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CopyClipboardComponent from "../CopyClipboardComponent";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { FournisseurFileInterface } from "../../../interfaces/NoveFournisseurInterface";
import FileInput from "../file/FileInputComponent";
import { LoadingButton } from "@mui/lab";
import { InputInterface } from "../../../interfaces/InputInterface";
import notEmptyValidator from "../../../helpers/validator/NotEmptyValidator";
import getErrorApi from "../../../helpers/GetErrorApi";
import MenuItem from "@mui/material/MenuItem";
import { SelectChangeEvent } from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";

interface FormState {
  ctNum: InputInterface;
  hasHeaders: InputInterface;
  separator: InputInterface;
}

const NoveFournisseurAddFileComponent: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const suppliers = useAppSelector(
    (state: RootState) => state.globalState.suppliers
  );
  const dispatch = useAppDispatch();
  const fournisseurs = useAppSelector(
    (state: RootState) => state.globalState.fournisseurs
  );
  const fileRef: any = useRef();
  const [hasFile, setHasFile] = React.useState<boolean>(false);
  const { t } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const [loadingAddFile, setLoadingAddFile] = React.useState<boolean>(false);
  const handleChangeSelect = React.useCallback(
    (prop: keyof FormState) => (event: SelectChangeEvent) => {
      setValues((v) => {
        return {
          ...v,
          [prop]: {
            ...v[prop],
            value: event.target.value as string,
            error: "",
          },
        };
      });
    },
    []
  );
  const getValues = React.useCallback((): FormState => {
    return {
      ctNum: {
        value: "",
        error: "",
      },
      hasHeaders: {
        value: true,
        error: "",
      },
      separator: {
        value: ";",
        error: "",
      },
    };
  }, []);
  const [values, setValues] = React.useState<FormState>(getValues());
  const saveFile = React.useCallback(async () => {
    setLoadingAddFile(true);
    const file = fileRef.current.getValue();
    if (file) {
      const ctNum = values.ctNum.value.trim();
      const ctNumError = notEmptyValidator(ctNum);
      if (ctNumError) {
        const newValue: FormState = { ...values };
        if (ctNumError) {
          values.ctNum.error = ctNumError;
        }
        setValues(newValue);
        setLoadingAddFile(false);
        return undefined;
      }
      const body = new FormData();
      body.append("file", file);
      body.append("ctNum", ctNum);
      body.append("hasHeaders", values.hasHeaders.value ? "1" : "0");
      body.append("separator", values.separator.value);
      const response = await requestApi({
        method: POST,
        path: FOURNISSEUR_FILE_URL,
        allowError: true,
        token: token,
        body: body,
        formData: true,
      });
      if (response.statusCode === 201) {
        fileRef.current.resetFile();
        setValues(getValues());
        dispatch(set({ refreshPage: true }));
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        for (let message of getErrorApi(response.content)) {
          toastr.error(t("word.error"), t(message));
        }
      }
    }
    setLoadingAddFile(false);
  }, [dispatch, getValues, t, token, values]);
  const handleChangeCheckbox = React.useCallback(
    (prop: keyof FormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues((v) => {
        return {
          ...v,
          [prop]: { ...v[prop], value: event.target.checked, error: "" },
        };
      });
    },
    []
  );

  return (
    <>
      {!refreshPage && (
        <FileInput
          ref={fileRef}
          accept=".csv"
          label={t("sentence.selectFile")}
          setHasFile={setHasFile}
        />
      )}
      {hasFile && (
        <>
          <Grid container spacing={1} sx={{ padding: 1 }}>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="userIdentifier">
                  {t("word.supplier")}
                </InputLabel>
                <Select
                  labelId="userIdentifier"
                  value={values.ctNum.value}
                  label={t("word.supplier")}
                  onChange={handleChangeSelect("ctNum")}
                >
                  {suppliers?.map((supplier, indexSupplier) => (
                      <MenuItem value={supplier} key={indexSupplier}>
                        {supplier +
                          " " +
                          fournisseurs?.find(
                            (f) => f.userIdentifier === supplier
                          )?.ctIntitule}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormGroup>
                <FormControlLabel
                  sx={{ justifyContent: "center" }}
                  control={
                    <Checkbox
                      checked={values.hasHeaders.value}
                      onChange={handleChangeCheckbox("hasHeaders")}
                    />
                  }
                  label={t("sentence.fileHasHeaders")}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel id="separator">{t("word.separator")}</InputLabel>
                <Select
                  labelId="separator"
                  value={values.separator.value}
                  label={t("word.separator")}
                  onChange={handleChangeSelect("separator")}
                >
                  {[";", ","].map((separator, indexSeparator) => (
                    <MenuItem value={separator} key={indexSeparator}>
                      {separator}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ marginY: 1, textAlign: "right" }}>
            <LoadingButton
              variant="contained"
              loading={loadingAddFile}
              onClick={saveFile}
            >
              {t("word.save")}
            </LoadingButton>
          </Box>
        </>
      )}
    </>
  );
});

const NoveFournisseurComponent: React.FC = React.memo(() => {
  const refreshPage = useAppSelector(
    (state: RootState) => state.globalState.refreshPage
  );
  const fournisseurs = useAppSelector(
    (state: RootState) => state.globalState.fournisseurs
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const componentRef = useRef();
  const dispatch = useAppDispatch();
  const [init, setInit] = React.useState(false);
  const [defaultItemsPerPage] = React.useState(50);
  const [fDocentetes, setFDocentetes] = React.useState<
    FournisseurFileInterface[] | undefined
  >(undefined);
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>([]);
  const [totalItems, setTotalItems] = React.useState(0);
  const { t, i18n } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);
  const user = useAppSelector((state: RootState) => state.globalState.user);

  const getColumns = React.useCallback((): GridColDef[] => {
    return [
      {
        field: "id",
        headerName: t("column.id"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
      },
      {
        field: "ctNum",
        headerName: t("column.userIdentifier"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <CopyClipboardComponent
              className="RobotoMono"
              component="span"
              text={params.row.userIdentifier ?? ""}
            />
            <Typography sx={{ marginLeft: 1 }}>
              {
                fournisseurs?.find(
                  (f) => f.userIdentifier === params.row.userIdentifier
                )?.ctIntitule
              }
            </Typography>
          </>
        ),
      },
      {
        field: "done",
        headerName: t("column.done"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            {params.row.done ? (
              <DoneIcon color="success" />
            ) : (
              <CloseIcon color="error" />
            )}
          </>
        ),
      },
      {
        field: "nbProducts",
        headerName: t("column.nbProducts"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
      },
      {
        field: "created",
        headerName: t("column.created"),
        flex: 1,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) =>
          new Date(params.row.created).toLocaleString(i18n.language, {
            dateStyle: "long",
            timeStyle: "medium",
            timeZone: "America/Cuiaba",
          }),
      },
      {
        field: "actions",
        headerName: t("column.actions"),
        flex: 0,
        headerClassName: "background-nove",
        filterable: false,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <>
            <Link
              to={ADMIN_NOVE_FOURNISSEUR_PAGE + "/" + params.row.id}
              state={params.row}
            >
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </Link>
          </>
        ),
      },
    ];
  }, [fournisseurs, i18n.language, t]);
  const [columns] = React.useState<GridColDef[]>(getColumns());

  const load = React.useCallback(
    async (force: boolean = false) => {
      setInit(true);
      if (!user || (fDocentetes !== undefined && !force)) {
        dispatch(set({ refreshPage: false }));
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      let hasChanged = false;
      if (!searchParamsObject.hasOwnProperty("page")) {
        searchParamsObject.page = 1;
        hasChanged = true;
      }
      if (!searchParamsObject.hasOwnProperty("itemsPerPage")) {
        searchParamsObject.itemsPerPage = defaultItemsPerPage;
        hasChanged = true;
      }
      if (!force) {
        let hasOrder = false;
        for (const [key] of Object.entries(searchParamsObject)) {
          if (key.startsWith("order")) {
            hasOrder = true;
            break;
          }
        }
        if (!hasOrder) {
          searchParamsObject["order[id]"] = "desc";
          hasChanged = true;
        }
      }
      if (hasChanged) {
        setSearchParams(searchParamsObject, {
          replace: true,
        });
        setTimeout(() => {
          dispatch(set({ refreshPage: true }));
        });
        return;
      }
      const response = await requestApi({
        method: GET,
        path: FOURNISSEUR_FILE_URL + objectToQuery(searchParamsObject),
        allowError: false,
        token: token,
        paginate: true,
      });
      if (response.statusCode === 200) {
        let maxId = Math.max(
          ...response.content["hydra:member"].map(
            (o: FournisseurFileInterface) => o.id
          )
        );
        const newArrivages = [
          ...Array.from(
            Array(
              Number(searchParamsObject.itemsPerPage) *
                (Number(searchParamsObject.page) - 1)
            )
          ).map(() => {
            maxId++;
            return {
              id: maxId,
            };
          }),
          ...response.content["hydra:member"],
        ];
        setFDocentetes(newArrivages);
        setTotalItems(response.content["hydra:totalItems"]);
      } else if (response.statusCode === 401) {
        toastr.info(t("word.info"), t("error.reconnect"));
      } else {
        toastr.error(t("word.error"), t("error.tryAgain"));
      }
      dispatch(set({ refreshPage: false }));
    },
    [
      defaultItemsPerPage,
      dispatch,
      fDocentetes,
      searchParams,
      setSearchParams,
      t,
      token,
      user,
    ]
  );

  const onPageSizeChange = React.useCallback(
    (pageSize: number) => {
      if (!init) {
        return;
      }
      setFDocentetes(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.itemsPerPage = pageSize;
      searchParamsObject.page = 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onPageChange = React.useCallback(
    (page: number) => {
      if (!init) {
        return;
      }
      setFDocentetes(undefined);
      const searchParamsObject = searchParamToObject(searchParams);
      searchParamsObject.page = page + 1;
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const onSortModelChange = React.useCallback(
    (model: GridSortModel) => {
      if (!init) {
        return;
      }
      const searchParamsObject = searchParamToObject(searchParams);
      for (const [key] of Object.entries(searchParamsObject)) {
        if (key.startsWith("order")) {
          delete searchParamsObject[key];
        }
      }
      for (const sort of model) {
        searchParamsObject["order[" + sort.field + "]"] = sort.sort;
      }
      setSearchParams(searchParamsObject, {
        replace: true,
      });
    },
    [init, searchParams, setSearchParams]
  );

  const getSortModel = React.useCallback(() => {
    const searchParamsObject = searchParamToObject(searchParams);
    const result: any[] = [];
    for (const [key, value] of Object.entries(searchParamsObject)) {
      if (key.startsWith("order")) {
        let field: string[] | string = key.split("[");
        field = field[1].replace("]", "");
        result.push({
          field: field,
          sort: value,
        });
      }
    }
    return result;
  }, [searchParams]);

  React.useEffect(() => {
    load();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (init && refreshPage) {
      load(true);
    }
  }, [init, refreshPage]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (!user) {
      setSearchParams(
        {},
        {
          replace: true,
        }
      );
    }
    if (init && !refreshPage) {
      dispatch(set({ refreshPage: true }));
    }
  }, [searchParams, user?.userIdentifier]); // eslint-disable-line react-hooks/exhaustive-deps

  const searchParamsObject = searchParamToObject(searchParams);
  return (
    <>
      <LoginComponent redirect={null} requireAdmin={true}>
        <Box ref={componentRef}>
          <Container sx={{ marginY: 2 }}>
            <NoveFournisseurAddFileComponent />
            {searchParamsObject.itemsPerPage && searchParamsObject.page && (
              // https://mui.com/x/react-data-grid/components/#pagination
              <DataGrid
                initialState={{
                  sorting: {
                    sortModel: getSortModel(),
                  },
                }}
                loading={refreshPage}
                onSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel);
                }}
                selectionModel={selectionModel}
                rows={refreshPage ? [] : fDocentetes ?? []}
                onSortModelChange={onSortModelChange}
                sortingMode="server"
                page={Number(searchParamsObject.page) - 1}
                rowsPerPageOptions={[10, 25, 50]}
                pageSize={Number(searchParamsObject.itemsPerPage)}
                onPageSizeChange={onPageSizeChange}
                onPageChange={onPageChange}
                rowCount={totalItems}
                columns={columns}
                autoHeight={true}
                disableExtendRowFullWidth={true}
                localeText={getLocaleDataGrid(i18n.language)}
              />
            )}
          </Container>
        </Box>
      </LoginComponent>
    </>
  );
});

export default NoveFournisseurComponent;
